import React, { useEffect, useState } from "react";
import { QuizFormData, QuizQuestionFormData, QUESTION_TYPES } from "types/quiz";
import QuizQuestionInput from "components/quiz/form/edit-create/QuizQuestionInput";
import QuizQuestionAnswerOptionInput from "components/quiz/form/edit-create/QuizQuestionAnswerOptionInput";
import { QuizQuestionAnswerOptionFormData } from "types/quiz";
import { useAppSelector } from "redux/hooks";
import { Button, Spinner } from "flowbite-react";
import QuizInput from "components/quiz/form/edit-create/QuizInput";
import { ErrorMessages, NestedErrorMessages } from "types/redux/slice";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";

interface Props {
  formData: QuizFormData;
  setFormData: (formData: QuizFormData) => void;
  generateBlankQuestionAnswerData: (
    question: QuizQuestionFormData | undefined,
  ) => QuizQuestionAnswerOptionFormData;
}

/**
 * Component for rendering quiz edit/create form inputs.
 */
export default function QuizEditCreateInputs({
  formData,
  setFormData,
  generateBlankQuestionAnswerData,
}: Props) {
  const [collapsedQuestions, setCollapsedQuestions] = useState<boolean[]>([]);
  const pendingRetrieveQuizzes = useAppSelector(
    (store) => store.quiz.pendingRetrieveQuizzes,
  );
  const pendingRetrieveQuizQuestions = useAppSelector(
    (store) => store.quiz.pendingRetrieveQuizQuestions,
  );
  const pendingGetNeuralNetworks = useAppSelector(
    (store) => store.neuralNetworks.pendingGetNeuralNetworks,
  );
  const quizEditCreateErrorMessages = useAppSelector(
    (store) => store.quiz.quizEditCreateErrorMessages,
  );

  /**
   * Updates a quiz question at the given index with provided data.
   */
  function onChangeQuestion(idx: number, data: QuizQuestionFormData) {
    const newFormData = { ...formData };

    // If the question is of a specific type - enforce answer option structure
    if (data.type === QUESTION_TYPES.FREEFORM) {
      data.answer_options = [...FREEFORM_ANSWER_OPTIONS];
    }
    if (data.type === QUESTION_TYPES.RATING) {
      data.answer_options = [...RATING_ANSWER_OPTIONS];
    }
    if (data.type === QUESTION_TYPES.AB) {
      data.answer_options = [...AB_ANSWER_OPTIONS];
    }

    newFormData.questions[idx] = { ...data };
    setFormData(newFormData);
  }

  /**
   * Updates a quiz question answer option at the given index with provided data.
   */
  function onChangeQuestionAnswerOption(
    questionIdx: number,
    answerIdx: number,
    data: QuizQuestionAnswerOptionFormData,
  ) {
    const newFormData = { ...formData };
    newFormData.questions[questionIdx].answer_options[answerIdx] = { ...data };
    setFormData(newFormData);
  }

  /**
   * Remove a quiz question at the given index.
   */
  function onRemoveQuestion(questionIdx: number) {
    const newFormData = { ...formData };
    newFormData.questions = [
      ...newFormData.questions.filter((question, idx) => idx !== questionIdx),
    ];
    setFormData(newFormData);
  }

  /**
   * Add a new answer option to a quiz question at the given index.
   */
  function onAddAnotherAnswer(questionIdx: number) {
    const newFormData = { ...formData };
    newFormData.questions[questionIdx].answer_options.push(
      generateBlankQuestionAnswerData(newFormData.questions[questionIdx]),
    );
    setFormData(newFormData);
  }

  /**
   * Remove an answer option at the given index.
   */
  function onRemoveAnswer(questionIdx: number, answerIdx: number) {
    const newFormData = { ...formData };
    newFormData.questions[questionIdx].answer_options = newFormData.questions[
      questionIdx
    ].answer_options.filter((answer, idx) => idx !== answerIdx);
    setFormData(newFormData);
  }

  /**
   * Generate title text for the remove answer button.
   */
  function generateRemoveAnswerTitleText(question: QuizQuestionFormData) {
    const type = question.type;
    if (type === QUESTION_TYPES.FREEFORM) {
      return "You cannot remove answers from freeform-type questions";
    }
    if (type === QUESTION_TYPES.RATING) {
      return "You cannot remove answers from rating-type questions";
    }
    if (type === QUESTION_TYPES.AB) {
      return "You cannot remove answers from A or B-type questions";
    }
    return undefined;
  }

  /**
   * Generate title text for the add answer button.
   */
  function generateAddAnswerTitleText(question: QuizQuestionFormData) {
    const type = question.type;
    if (type === QUESTION_TYPES.FREEFORM) {
      return "You cannot add answers to freeform-type questions";
    }
    if (type === QUESTION_TYPES.RATING) {
      return "You cannot add answers to rating-type questions";
    }
    if (type === QUESTION_TYPES.AB) {
      return "You cannot add answers to A or B-type questions";
    }
    return undefined;
  }

  /**
   * Scroll the question at the given index into view.
   */
  function scrollQuestionIntoView(questionIdx: number) {
    document.getElementById(questionIdx.toString())?.scrollIntoView();
  }

  /**
   * Handle collapsing/expanding accordions.
   */
  function handleClickAccordion(idx: number) {
    // Collapse all accordions and toggle the one selected
    const isSelectedAccordionCollapsed = !!collapsedQuestions[idx];
    const newCollapsedQuestions = Array(collapsedQuestions.length).fill(true);
    if (idx >= newCollapsedQuestions.length) {
      return;
    }
    newCollapsedQuestions[idx] = !isSelectedAccordionCollapsed;
    setCollapsedQuestions(newCollapsedQuestions);

    // Since there's an animation on accordion wrapping/unwrapping itself
    // scrollQuestionIntoView has to be delayed
    setTimeout(() => scrollQuestionIntoView(idx), 150);
  }

  /**
   * Check if the question at the given index is collapsed.
   */
  function isQuestionCollapsed(questionIdx: number) {
    const isCollapsed = collapsedQuestions[questionIdx];
    if (isCollapsed === undefined) {
      // This prevents the accordions from expanding when the component is initially rendered
      return true;
    }
    return isCollapsed;
  }

  /**
   * Set all questions to be collapsed except for the last one.
   */
  useEffect(() => {
    const currCollapsedQuestions = [...collapsedQuestions];
    const newCollapsedQuestions = Array(formData.questions.length).fill(true);
    currCollapsedQuestions.forEach((isCollapsed, idx) => {
      // All questions should be collapsed except for the last one
      if (idx >= newCollapsedQuestions.length) {
        return;
      }
      newCollapsedQuestions[idx] = isCollapsed;
    });
    setCollapsedQuestions(newCollapsedQuestions);
  }, [formData]);

  const pending =
    pendingRetrieveQuizQuestions ||
    pendingRetrieveQuizzes ||
    pendingGetNeuralNetworks;

  if (pending) {
    return (
      <div className="flex justify-center">
        <Spinner size="sm" aria-label="Loading spinner" />
      </div>
    );
  }

  return (
    <>
      <div className="px-6 space-y-12">
        <div className="border rounded p-4 space-y-2 shadow-lg">
          <QuizInput
            data={formData}
            onChange={(data) => setFormData({ ...data })}
            errors={quizEditCreateErrorMessages as ErrorMessages}
          />
        </div>
        {formData.questions.map((question, questionIdx) => {
          // Do not allow adding/removing answer options from FREEFORM/RATING/AB question types
          const disableAddQuestionButton =
            question.type === QUESTION_TYPES.FREEFORM ||
            question.type === QUESTION_TYPES.RATING ||
            question.type === QUESTION_TYPES.AB;
          const disableRemoveQuestionButton =
            question.type === QUESTION_TYPES.FREEFORM ||
            question.type === QUESTION_TYPES.RATING ||
            question.type === QUESTION_TYPES.AB;

          return (
            <div key={questionIdx} id={questionIdx.toString()}>
              <div className="border rounded p-4 space-y-2 shadow-lg">
                <Accordion
                  open={!isQuestionCollapsed(questionIdx)}
                  icon={
                    <ChevronUpIcon
                      className={`${
                        !isQuestionCollapsed(questionIdx) ? "rotate-180" : ""
                      } h-5 w-5 transition-transform`}
                      aria-label="Accordion toggle icon"
                    />
                  }
                  placeholder={""}
                >
                  <AccordionHeader
                    onClick={() => handleClickAccordion(questionIdx)}
                    className="flex py-3 px-4"
                    placeholder={""}
                    aria-label={`Accordion header for question ${questionIdx + 1}`}
                  >
                    <div className="flex flex-col flex-1 items-start">
                      <p className="text-base text-left font-semibold text-gray-700 overflow-hidden">
                        {question.text || `Question ${questionIdx + 1}`}
                      </p>
                      <p className="text-sm text-left text-gray-500">
                        {`${question.answer_options.length} answer options`}
                      </p>
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="space-y-2 px-4">
                    <QuizQuestionInput
                      data={question}
                      onChange={(data) => onChangeQuestion(questionIdx, data)}
                      errors={
                        quizEditCreateErrorMessages?.questions?.[
                          questionIdx
                        ] as ErrorMessages
                      }
                      idx={questionIdx}
                    />
                    {question.answer_options.map((answerOption, answerIdx) => (
                      <div
                        className="border rounded p-4 space-y-2 shadow-md"
                        key={answerIdx}
                      >
                        <QuizQuestionAnswerOptionInput
                          question={question}
                          key={answerIdx}
                          answerIdx={answerIdx}
                          data={answerOption}
                          onChange={(data) =>
                            onChangeQuestionAnswerOption(
                              questionIdx,
                              answerIdx,
                              data,
                            )
                          }
                          errors={
                            (
                              quizEditCreateErrorMessages?.questions?.[
                                questionIdx
                              ] as NestedErrorMessages
                            )?.answer_options?.[answerIdx] as ErrorMessages
                          }
                        />
                        <div className="flex flex-row gap-2 justify-center items-center">
                          <Button
                            onClick={() =>
                              onRemoveAnswer(questionIdx, answerIdx)
                            }
                            className="flex-1"
                            color="failure"
                            disabled={disableRemoveQuestionButton}
                            title={generateRemoveAnswerTitleText(question)}
                            aria-label={`Remove answer ${answerIdx + 1}`}
                          >
                            Remove answer
                          </Button>
                        </div>
                      </div>
                    ))}

                    <div className="flex flex-row gap-2 justify-center items-center">
                      <Button
                        onClick={() => onAddAnotherAnswer(questionIdx)}
                        className="flex-1"
                        disabled={disableAddQuestionButton}
                        title={generateAddAnswerTitleText(question)}
                        aria-label="Add another answer"
                      >
                        {question.answer_options.length > 0
                          ? "Add another answer"
                          : "Add answer"}
                      </Button>
                      <Button
                        onClick={() => onRemoveQuestion(questionIdx)}
                        className="flex-1"
                        color="failure"
                        aria-label={`Remove question ${questionIdx + 1}`}
                      >
                        Remove question
                      </Button>
                    </div>
                  </AccordionBody>
                </Accordion>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

const RATING_ANSWER_OPTIONS: QuizQuestionAnswerOptionFormData[] = [
  {
    text: "1 star",
    value: "1",
    position: 1,
    image: null,
    external_image: "",
  },
  {
    text: "2 stars",
    value: "2",
    position: 2,
    image: null,
    external_image: "",
  },
  {
    text: "3 stars",
    value: "3",
    position: 3,
    image: null,
    external_image: "",
  },
  {
    text: "4 stars",
    value: "4",
    position: 4,
    image: null,
    external_image: "",
  },
  {
    text: "5 stars",
    value: "5",
    position: 5,
    image: null,
    external_image: "",
  },
];

const FREEFORM_ANSWER_OPTIONS: QuizQuestionAnswerOptionFormData[] = [
  {
    text: "Answer:",
    value: "answer",
    position: 1,
    image: null,
    external_image: "",
  },
];

const AB_ANSWER_OPTIONS: QuizQuestionAnswerOptionFormData[] = [
  {
    text: "",
    value: "",
    position: 1,
    image: null,
    external_image: "",
  },
  {
    text: "",
    value: "",
    position: 2,
    image: null,
    external_image: "",
  },
];
