import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { history } from "./helpers/history";
import Navigation from "navigation";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  // Init custom history object to allow navigation from
  // anywhere in the React app (inside or outside components)
  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <div className="App">
      <ToastContainer />
      <Navigation />
    </div>
  );
}

export default App;
