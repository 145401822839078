import { post } from "./apiService";

/**
 * Send a POST request to the API to sign up for the newsletter
 */
export const submitEmailOrPhone = async (data: {
  email?: string;
  phone?: string;
}) => {
  return await post("/api/newsletter/register/", data, true);
};
