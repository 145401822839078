import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import Header from "components/common/header";
import { useParams } from "react-router-dom";
import FeedDataTrackDetailsComponent from "components/admin/feedData/details/FeedDataTrackDetails";

/**
 * Feed data track details page.
 */
export default function FeedDataTrackDetails() {
  const { trackId } = useParams();

  useEffect(() => {
    document.title = "Feed Data | BrainCargo";
  }, []);

  return (
    <PageWrapper>
      <div className="border shadow-md lg:rounded-lg bg-white pb-6 space-y-2">
        <Header title="Track Details" />
        <FeedDataTrackDetailsComponent trackId={trackId || ""} />
      </div>
    </PageWrapper>
  );
}
