import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {history} from "../../helpers/history";
import {useAppDispatch} from "../../redux/hooks";
import {verifyEmail} from "../../redux/auth/authSlice";
import {toast} from "react-toastify";

const EmailConfirmation: React.FC = () => {
  const {token} = useParams();  // Extract the token from the URL
  const dispatch = useAppDispatch();

  useEffect(() => {
    try {
      const key: string = token || "";
      if (key) {
        toast.promise(
          dispatch(verifyEmail({key})).unwrap(),
          {
            pending: "Verifying Email",
            success: {
              render({data}: any) {
                return data?.detail || "Verified Successfully";
              }
            },
            error: {
              render({data}: any) {
                return data?.detail || "Verification Failed";
              }
            }
          },
          {
            autoClose: 5000,
          }
        );
      }


    } catch (error) {
      console.error("Email confirmation failed", error);
    }

  }, [token, history]);

  return (
    <div>
      <h2>Confirming your email...</h2>
      <div id="status-message" aria-live="polite"></div>
    </div>
  );
};

export default EmailConfirmation;
