import React, { useEffect } from "react";
import { CircleStackIcon, ListBulletIcon } from "@heroicons/react/24/solid";
import {
  ClipboardDocumentListIcon,
  PlayIcon,
  UserIcon,
  MusicalNoteIcon,
  NewspaperIcon,
  CurrencyDollarIcon,
  FlagIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import NeuralNetworkIcon from "../../icons/NeuralNetworkIcon";
import { APP_URLS } from "navigation";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getUser } from "redux/user/userSlice";
import DesktopNavBarItem from "./DesktopNavBarItem";
import DesktopNavBarSeparator from "./DesktopNavBarSeparator";

const ICON_CLASSNAME =
  "flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900";

const DARK_GRAY_FILL = "rgb(107 114 128)";

/**
 * Component that renders the navigation bar for desktop.
 */
export default function DesktopNavBar() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);

  /**
   * Fetch the user if it is not set.
   */
  useEffect(() => {
    if (!user) {
      dispatch(getUser());
    }
  }, [user]);

  return (
    <>
      <aside
        id="default-sidebar"
        className={"fixed top-0 left-0 z-40 h-screen overflow-hidden w-64"}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800 flex flex-col gap-2">
          <DesktopNavBarItem
            href={APP_URLS.PROFILE}
            icon={<UserIcon className={ICON_CLASSNAME} />}
            label="Profile"
          />
          <DesktopNavBarItem
            href={APP_URLS.BRAIN_COIN}
            icon={<CurrencyDollarIcon className={ICON_CLASSNAME} />}
            label="Brain Coin"
          />
          <DesktopNavBarItem
            href={APP_URLS.VIDEOS}
            icon={<PlayIcon className={ICON_CLASSNAME} />}
            label="Videos"
          />
          <DesktopNavBarItem
            href={APP_URLS.MUSIC}
            icon={<MusicalNoteIcon className={ICON_CLASSNAME} />}
            label="Music"
          />
          <DesktopNavBarItem
            href={APP_URLS.NEWS}
            icon={<NewspaperIcon className={ICON_CLASSNAME} />}
            label="News"
          />
          <DesktopNavBarItem
            href={APP_URLS.PLAYLISTS_DESKTOP}
            icon={<ListBulletIcon className={ICON_CLASSNAME} />}
            label="Playlists"
          />
          <DesktopNavBarItem
            href={APP_URLS.NEURAL_NETWORKS}
            icon={
              <NeuralNetworkIcon
                className={ICON_CLASSNAME}
                fill={DARK_GRAY_FILL}
              />
            }
            label="Neural Networks"
          />
          <DesktopNavBarItem
            href={APP_URLS.DATA_SETS}
            icon={<CircleStackIcon className={ICON_CLASSNAME} />}
            label="Datasets"
          />
          <DesktopNavBarItem
            href={APP_URLS.QUIZZES}
            icon={<ClipboardDocumentListIcon className={ICON_CLASSNAME} />}
            label="Quizzes"
          />
          <DesktopNavBarItem
            href={APP_URLS.FEEDBACK}
            icon={<PencilSquareIcon className={ICON_CLASSNAME} />}
            label="Feedback"
          />
          {user?.is_superuser && (
            <>
              <DesktopNavBarSeparator />
              <DesktopNavBarItem
                href={APP_URLS.FEED_DATA}
                icon={<CircleStackIcon className={ICON_CLASSNAME} />}
                label="Feed Data"
              />
              <DesktopNavBarItem
                href={APP_URLS.REPORTS_LIST}
                icon={<FlagIcon className={ICON_CLASSNAME} />}
                label="Reports"
              />
              <DesktopNavBarItem
                href={APP_URLS.FEEDBACK_LIST}
                icon={<PencilSquareIcon className={ICON_CLASSNAME} />}
                label="Feedbacks"
              />
            </>
          )}
        </div>
      </aside>
    </>
  );
}
