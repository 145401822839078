import React, { MouseEvent } from "react";
import {
  Playlist,
  PlaylistItem,
  RemoveItemFromPlaylistPayload,
} from "types/playlist";
import { TrashIcon } from "@heroicons/react/24/outline";
import { getPlaylistItemImage } from "helpers/playlist";
import { useAppDispatch } from "redux/hooks";
import {
  getPlaylist,
  removeItemFromPlaylist,
} from "redux/playlist/playlistSlice";
import { APP_URLS } from "navigation";
import { FEED_ITEM_TYPES } from "types/feed";
import { replaceSpecialChars } from "helpers/text";

interface Props {
  item: PlaylistItem;
  playlist: Playlist;
}

/**
 * Component used for displaying a playlist item in PlaylistDetails.
 */
export default function PlaylistDetailsItem({ item, playlist }: Props) {
  const dispatch = useAppDispatch();

  /**
   * Get the title of the playlist item.
   */
  function getItemTitle(item: Props["item"]) {
    return replaceSpecialChars(item.feed_item?.title || "Unknown");
  }

  /**
   * Get the subtitle of the playlist item.
   */
  function getItemSubTitle(item: Props["item"]) {
    let subtitle = "Unknown";
    if (item.feed_item?.type === FEED_ITEM_TYPES.VIDEO) {
      subtitle = "Video";
    } else if (item.feed_item?.type === FEED_ITEM_TYPES.TRACK) {
      subtitle = "Song";
    } else if (item.feed_item?.type === FEED_ITEM_TYPES.NEWS) {
      subtitle = "News";
    }
    return subtitle;
  }

  /**
   * Remove the item from the playlist.
   */
  async function onClickDelete(e: MouseEvent<any>) {
    e.stopPropagation();
    const data: RemoveItemFromPlaylistPayload = {
      playlist_id: playlist.id,
      feed_item_id: item.feed_item?.id,
    };
    await dispatch(removeItemFromPlaylist(data));
    dispatch(getPlaylist({ id: playlist.id }));
  }

  /**
   * Navigate to the feed item details page.
   */
  async function onClickItem() {
    const url = APP_URLS.FEED_MOBILE_SPECIFIC.replace(
      ":feedItemId",
      String(item.feed_item?.id),
    );
    window.location.href = url;
  }

  return (
    <button
      className="grid grid-cols-6 justify-center items-center space-x-4 w-full text-left"
      onClick={onClickItem}
      aria-label={`View details for ${getItemTitle(item)}`}
    >
      <img
        src={getPlaylistItemImage(item)}
        className="col-span-1 rounded"
        alt={`${getItemTitle(item)} thumbnail`}
      />
      <div className="col-span-4 flex flex-col">
        <span className="text-lg text-neutral-900 font-medium truncate">
          {getItemTitle(item)}
        </span>
        <span className="text-sm text-neutral-400 font-medium">
          {getItemSubTitle(item)}
        </span>
      </div>
      <div className="col-span-1 flex justify-center items-center">
        <TrashIcon
          className="text-red-600 p-1"
          onClick={onClickDelete}
          aria-label={`Remove ${getItemTitle(item)} from playlist`}
        />
      </div>
    </button>
  );
}
