import React from "react";

interface Props {
  currentTab: string;
  setCurrentTab: (tab: string) => void;
}

export const TMDB_MOVIE_TABS = {
  OVERVIEW: "Overview",
  CAST: "Cast",
  WATCH: "Watch",
  TRAILERS_AND_CLIPS: "Trailers and clips",
};

/**
 * Clickable tabs for TMDBMovie component.
 */
export default function TMDBMovieTabs({ currentTab, setCurrentTab }: Props) {
  return (
    <div className="flex flex-row overflow-x-auto space-x-2 whitespace-nowrap scrollbar-hide justify-between" role="tablist" aria-label="TMDB Movie Tabs">
      {Object.entries(TMDB_MOVIE_TABS).map(([key, value]) => (
        <button
          key={key}
          onClick={() => setCurrentTab(value)}
          className={`px-2 py-1 rounded-lg ${
            currentTab === value ? "bg-neutral-300" : "bg-neutral-200"
          }`}
          aria-selected={currentTab === value}
          aria-label={`Tab for ${value}`}
          role="tab"
        >
          {value}
        </button>
      ))}
    </div>
  );
}
