import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import Modal from ".";

interface Props {
  show: boolean;
  onSubmit: () => void;
}

/**
 * Modal used for displaying a "To be implemented" message.
 */
export default function ToBeImplementedPopup({ show, onSubmit }: Props) {
  return (
    <React.Fragment>
      <Modal
        show={show}
        onSubmit={onSubmit}
        onClose={onSubmit}
        submitButtonText="Go back"
        body={
          <div className="text-center">
            <ExclamationCircleIcon
              className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200"
              aria-hidden="true"
            />
            <h3
              className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400"
              aria-label="To be implemented message"
            >
              To be implemented.
            </h3>
          </div>
        }
      />
    </React.Fragment>
  );
}
