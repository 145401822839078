import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ModalEditCreatePlaylist from "components/playlist/ModalEditCreatePlaylist";
import SelectPlaylistPopup from "components/playlist/SelectPlaylistPopup";
import PageWrapper from "components/common/pageWrapper";
import NewsItem from "components/common/news";
import { isDispatchResponseError } from "redux/utils";
import { PlusIcon } from "@heroicons/react/24/solid";
import {
  FlagIcon,
  ForwardIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import MyPlaylistList from "components/playlist/MyPlaylistList";
import SharedPlaylistList from "components/playlist/SharedPlaylistList";
import Header from "components/common/header";
import { retrieveConnectedAdvertisers } from "redux/brainCoin/brainCoinSlice";
import AdByRandomProvider from "components/ads/AdByRandomProvider";
import {
  dislikeFeedItem,
  getDislikedFeedItems,
  getLikedFeedItems,
  getRandomFeedItem,
  increaseWatchedFor,
  likeFeedItem,
  skipFeedItem,
  watchFeedItem,
} from "redux/feed/feedItemsSlice";
import { FEED_ITEM_TYPES } from "types/feed";
import { ReportFeedItemModal } from "components/report/ReportFeedItemModal";

/**
 * News page
 */
export default function News() {
  const dispatch = useAppDispatch();
  const feedItem = useAppSelector((state) => state.feedItems.feedItem);
  const likedFeedItems = useAppSelector(
    (state) => state.feedItems.likedFeedItems,
  );
  const dislikedFeedItems = useAppSelector(
    (state) => state.feedItems.dislikedFeedItems,
  );
  const [showPlaylistPopup, setShowPlaylistPopup] = useState<boolean>(false);
  const [showSelectPlaylistPopup, setShowSelectPlaylistPopup] =
    useState<boolean>(false);
  const [readFor, setReadFor] = useState(0);
  const [blurDate, setBlurDate] = useState<Date | null>(null);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);

  /**
   * Mark feedItem as liked in the database
   */
  const handleLikeFeedItem = async () => {
    const response = await dispatch(likeFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      // Update how long user has watched this media
      updateWatchedFor();

      // Retrieve a new random feedItem
      dispatch(
        getRandomFeedItem({ queryParams: { types: FEED_ITEM_TYPES.NEWS } }),
      );
    }
  };

  /**
   * Mark feedItem as disliked in the database
   */
  const handleDislikeFeedItem = async () => {
    const response = await dispatch(dislikeFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      // Update how long user has watched this media
      updateWatchedFor();

      // Retrieve a new random news item
      dispatch(
        getRandomFeedItem({ queryParams: { types: FEED_ITEM_TYPES.NEWS } }),
      );
    }
  };

  /**
   * Mark feedItem as skipped in the database
   */
  const handleSkipFeedItem = async () => {
    const response = await dispatch(skipFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      // Update how long user has watched this media
      updateWatchedFor();

      // Retrieve a new random news
      dispatch(
        getRandomFeedItem({ queryParams: { types: FEED_ITEM_TYPES.NEWS } }),
      );
    }
  };

  /**
   * Mark feedItem as seen in the database
   */
  const onClickReadMore = () => {
    dispatch(watchFeedItem(feedItem?.id || ""));
    setBlurDate(new Date());
  };

  /**
   * Render a list of liked news
   */
  const displayLikedFeedItems = () => {
    if (likedFeedItems.length > 0) {
      return likedFeedItems.map((likedFeedItem, idx) => (
        <li key={idx} className="pb-3 sm:py-4">
          <NewsItem news={likedFeedItem} />
        </li>
      ));
    } else {
      return (
        <p className="py-4 text-gray-500 text-sm font-semibold">
          No Liked News added yet!
        </p>
      );
    }
  };

  /**
   * Update how long user has watched this media
   */
  function updateWatchedFor() {
    if (feedItem && readFor > 0) {
      dispatch(
        increaseWatchedFor({
          feed_item: feedItem.id,
          watched_for: readFor,
          watched: true,
        }),
      );
      setReadFor(0);
    }
  }

  /**
   * Render a list of disliked news
   */
  const displayDislikedFeedItems = () => {
    if (dislikedFeedItems.length > 0) {
      return dislikedFeedItems.map((dislikedFeedItem, idx) => (
        <li key={idx} className="pb-3 sm:py-4">
          <NewsItem news={dislikedFeedItem} />
        </li>
      ));
    } else {
      return (
        <p className="py-4 text-gray-500 text-sm font-semibold">
          No Disliked News added yet!
        </p>
      );
    }
  };

  /**
   * Retrieve data
   */
  useEffect(() => {
    document.title = "News | BrainCargo";

    dispatch(
      getRandomFeedItem({ queryParams: { types: FEED_ITEM_TYPES.NEWS } }),
    );

    dispatch(
      getLikedFeedItems({ queryParams: { types: FEED_ITEM_TYPES.NEWS } }),
    );

    dispatch(
      getDislikedFeedItems({ queryParams: { types: FEED_ITEM_TYPES.NEWS } }),
    );
    dispatch(retrieveConnectedAdvertisers());
  }, []);

  /**
   * Update watchedFor when user leaves the page
   */
  useEffect(() => {
    const onUnload = function (e: BeforeUnloadEvent) {
      updateWatchedFor();
    };

    const onVisibilityChange = function (e: Event) {
      if (!document.hidden && blurDate) {
        const now = new Date();
        const seconds = (now.getTime() - blurDate.getTime()) / 1000;
        setReadFor(readFor + seconds);
        setBlurDate(null);
      }
    };

    window.addEventListener("beforeunload", onUnload);
    document.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      window.removeEventListener("beforeunload", onUnload);
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [readFor, blurDate]);

  return (
    <PageWrapper>
      <div className="border shadow-md sm:rounded-lg bg-white">
        <Header title="News" />

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 p-2 auto-rows-min">
          <div className="grid grid-cols-2 col-span-2 gap-2 auto-rows-min">
            <div className="col-span-2 justify-center items-center">
              {feedItem ? (
                <div className="flex flex-row p-2 border rounded gap-2">
                  <div className="flex flex-row justify-center items-center">
                    <img
                      src={feedItem.image || ""}
                      className="h-[150px] rounded"
                      alt={feedItem.title}
                    />
                  </div>
                  <div className="flex flex-1 flex-col justify-center divide-gray-200 gap-1">
                    <div className="text-lg font-bold border-b">
                      {feedItem.title}
                    </div>
                    <div className="text-base">{feedItem.description}</div>
                    <div>
                      <a
                        className="text-blue-600 font-medium cursor-pointer inline"
                        href={feedItem.bing_news_url}
                        rel="noreferrer"
                        target="_blank"
                        onClick={onClickReadMore}
                        aria-label={`Read more about ${feedItem.title}`}
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  <p className="bg-gray-200 font-semibold p-6">
                    News not available at this time
                  </p>
                </div>
              )}
            </div>

            <div className="col-span-2 flex flex-col md:flex-row gap-2 w-full rounded">
              <button
                onClick={handleLikeFeedItem}
                disabled={!feedItem}
                className="flex p-2 bg-[#EBEDF0] font-semibold border rounded-lg justify-center"
                aria-label="Like this news item"
              >
                <HandThumbUpIcon className="w-6 h-6" />
                &#160;Like
              </button>
              <button
                onClick={handleDislikeFeedItem}
                disabled={!feedItem}
                className="flex p-2 bg-[#EBEDF0] font-semibold border rounded-lg justify-center"
                aria-label="Dislike this news item"
              >
                <HandThumbDownIcon className="w-6 h-6" />
                &#160;Dislike
              </button>
              <button
                onClick={handleSkipFeedItem}
                disabled={!feedItem}
                className="flex p-2 md:ml-auto bg-[#EBEDF0] font-semibold border rounded-lg justify-center"
                aria-label="Skip this news item"
              >
                <ForwardIcon className="w-6 h-6" />
                &#160;Skip
              </button>
              <button
                onClick={() => setShowReportModal(true)}
                disabled={!feedItem}
                className="flex p-2 bg-[#EBEDF0] font-semibold border rounded-lg justify-center"
                aria-label="Report this news item"
              >
                <FlagIcon className="w-6 h-6" />
                &#160;Report
              </button>
              <button
                onClick={() => setShowSelectPlaylistPopup(true)}
                disabled={!feedItem}
                className="flex p-2 bg-[#EBEDF0] font-semibold border rounded-lg justify-center"
                aria-label="Add this news item to a playlist"
              >
                <PlusIcon className="w-6 h-6" />
                &#160;Add to Playlist
              </button>
            </div>

            <AdByRandomProvider className="col-span-2 justify-center items-center" />

            <div className="border rounded col-span-2 xl:col-span-1">
              <div className="bg-gray-100 p-4 border-b">
                <span className="font-semibold">Liked News</span>
              </div>
              <ul className="px-4 divide-y divide-gray-200 max-h-[40rem] overflow-auto" aria-label="List of liked news items">
                {displayLikedFeedItems()}
              </ul>
            </div>
            <div className="border rounded col-span-2 xl:col-span-1">
              <div className="bg-gray-100 p-4 border-b">
                <span className="font-semibold">Disliked News</span>
              </div>
              <ul className="px-4 divide-y divide-gray-200 max-h-[40rem] overflow-auto" aria-label="List of disliked news items">
                {displayDislikedFeedItems()}
              </ul>
            </div>
          </div>
          <div className="w-full">
            <div className="border rounded">
              <MyPlaylistList smallHeader />
              <SharedPlaylistList smallHeader />
            </div>
          </div>
        </div>

        <ModalEditCreatePlaylist
          show={showPlaylistPopup}
          onClose={() => setShowPlaylistPopup(false)}
        />
        {showSelectPlaylistPopup && feedItem && (
          <SelectPlaylistPopup
            feedItem={feedItem}
            show={showSelectPlaylistPopup}
            onClose={() => setShowSelectPlaylistPopup(false)}
            headerText="Add news to playlist"
          />
        )}

        {feedItem && (
          <ReportFeedItemModal
            show={showReportModal}
            setShow={setShowReportModal}
            feedItem={feedItem}
          />
        )}
      </div>
    </PageWrapper>
  );
}
