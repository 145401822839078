import React, {useState} from "react";
import {useAppDispatch} from "redux/hooks";
import {toast} from "react-toastify";
import TextInput from "../../components/common/form/TextInput";
import {forgotEmailPassword} from "../../redux/auth/authSlice";
import {APP_URLS} from "../../navigation";
import {history} from "helpers/history";

const PRIMARY_TEXT_COLOR_CLASS = "text-stone-200";

/**
 * Forgot Password page.
 */
export default function ForgotPassword() {
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");

  /**
   * Handle password reset request.
   */
  const handlePasswordResetRequest = async () => {
    try {
      await toast.promise(
        dispatch(forgotEmailPassword({email})).unwrap(),
        {
          pending: "Processing...",
          success: {
            render({data}: any) {
              history.navigate(APP_URLS.LOGIN);
              return data?.detail || "Password Reset Link Sent";
            },
          },
          error: {
            render({data}: any) {
              return data?.detail || "Failed to send reset link.";
            },
          },
        },
        {
          autoClose: 5000,
        }
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handlePasswordResetRequest();
  };

  return (
    <div className="relative flex flex-col justify-center p-4 md:p-0 min-h-screen overflow-hidden bg-[#02041a]">
      <div
        className="w-full p-4 sm:p-10 m-auto bg-[#02041a] drop-shadow-xl border border-[#fbfbff] rounded-xl lg:max-w-xl"
        aria-label="Forgot Password Form"
      >
        <div className="flex flex-col items-center">
          <h1
            className={`text-xl text-center sm:text-4xl ${PRIMARY_TEXT_COLOR_CLASS} font-extrabold sm:w-2/3 width: -webkit-fill-available;`}
          >
            Forgot Password
          </h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mt-6">
            <TextInput
              label="Email"
              id="email"
              type="email"
              required
              onChange={(event) => setEmail(event.target.value)}
              placeholder="example@example.com"
              value={email}
              aria-label="Email Input"
            />
            <div className="mt-6 flex justify-center">
              <button
                type="submit"
                className="w-full px-4 py-3 tracking-wide text-white transition-colors border border-transparent duration-200 transform bg-[linear-gradient(95.49deg,#5d3ef8_-12.23%,#ba4cd6_117.24%)] rounded-full hover:bg-transparent hover:border-[#cec0f3] focus:outline-none focus:bg-blue-600"
                aria-label="Send Reset Link"
              >
                Send Reset Link
              </button>
            </div>

          </div>
        </form>
        <div id="status-message" aria-live="polite"></div>
      </div>
    </div>
  );
}
