import React from "react";
import { TMDBCast } from "types/feed";

interface Props {
  cast: TMDBCast;
  fullWidth?: boolean;
}

/**
 * Single cast member for TMDBMovie component.
 */
export default function TMDBMovieCastItem({ cast, fullWidth }: Props) {
  /**
   * Generate link to the cast member's profile on TMDB.
   */
  function getProfileLink(cast: Props["cast"]) {
    return `https://www.themoviedb.org/person/${cast.id}`;
  }

  return (
    <a
      className={`rounded-lg p-2 bg-neutral-200 flex-shrink-0 space-y-2 text-left ${
        fullWidth ? "w-full" : "w-32 "
      }`}
      href={getProfileLink(cast)}
      target="_blank"
      rel="noreferrer"
      aria-label={`View profile of ${cast.name} on TMDB`}
    >
      <img
        className="rounded-lg m-auto w-full h-40 object-cover"
        src={`https://image.tmdb.org/t/p/w200${cast.profile_path}`}
        alt={cast.name}
      />
      <div>
        <div className="text-base font-bold">{cast.name}</div>
        <div className="text-sm font-bold text-neutral-500">
          {cast.character}
        </div>
      </div>
    </a>
  );
}
