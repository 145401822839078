import React, { useState } from "react";
import {
  Bars3Icon,
  CircleStackIcon,
  ListBulletIcon,
} from "@heroicons/react/24/solid";
import {
  ClipboardDocumentListIcon,
  PlayIcon,
  ChevronDoubleDownIcon,
  FlagIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import NeuralNetworkIcon from "../../../icons/NeuralNetworkIcon";
import { APP_URLS } from "navigation";
import { useAppSelector } from "redux/hooks";
import MobileNavBarExpandableMenu from "../common/MobileNavBarExpandableMenu";
import MobileBottomNavBarItem from "./MobileBottomNavBarItem";
import MobileBottomNavBarSeparator from "./MobileBottomNavBarSeparator";
import MobileBottomNavBarExpandableItem from "./MobileBottomNavBarExpandableItem";

const ICON_CLASSNAME = "w-8 h-8 text-gray-500 transition duration-75";
const DARK_GRAY_FILL = "rgb(107 114 128)";
export const MOBILE_BOTTOM_NAV_BAR_HEIGHT = "88px";

/**
 * The bottom navigation bar for mobile devices.
 */
export default function MobileBottomNavBar() {
  const [expandFeedMenu, setExpandFeedMenu] = useState(false);
  const user = useAppSelector((state) => state.user.user);

  return (
    <nav
      className={
        "fixed bottom-0 left-0 z-40 w-screen bg-gray-50 border-t-2 border-gray-200"
      }
    >
      <div className="grid grid-cols-5 py-2 gap-2">
        <MobileBottomNavBarItem
          href={APP_URLS.FEED_MOBILE}
          icon={<PlayIcon className={ICON_CLASSNAME} />}
          label="AI"
        />
        <MobileBottomNavBarItem
          href={APP_URLS.PLAYLISTS_MOBILE}
          icon={<ListBulletIcon className={ICON_CLASSNAME} />}
          label="Playlists"
        />
        <div></div>
        <div></div>
        <MobileBottomNavBarExpandableItem
          expanded={expandFeedMenu}
          onClick={() => setExpandFeedMenu(!expandFeedMenu)}
          collapsedIcon={<Bars3Icon className={ICON_CLASSNAME} />}
          expandedIcon={<ChevronDoubleDownIcon className={ICON_CLASSNAME} />}
          label="More"
        />
      </div>

      <MobileNavBarExpandableMenu expanded={expandFeedMenu} gridCols={5}>
        <MobileBottomNavBarItem
          href={APP_URLS.NEURAL_NETWORKS}
          icon={
            <NeuralNetworkIcon
              className={ICON_CLASSNAME}
              fill={DARK_GRAY_FILL}
            />
          }
          label="Neural Networks"
        />
        <MobileBottomNavBarItem
          href={APP_URLS.DATA_SETS}
          icon={<CircleStackIcon className={ICON_CLASSNAME} />}
          label="Datasets"
        />
        <MobileBottomNavBarItem
          href={APP_URLS.FEEDBACK}
          icon={<PencilSquareIcon className={ICON_CLASSNAME} />}
          label="Feedback"
        />
        <MobileBottomNavBarItem
          href={APP_URLS.QUIZZES}
          icon={<ClipboardDocumentListIcon className={ICON_CLASSNAME} />}
          label="Quizzes"
        />
        {user?.is_superuser && (
          <>
            <MobileBottomNavBarSeparator />
            <MobileBottomNavBarItem
              href={APP_URLS.DATA_SETS}
              icon={<CircleStackIcon className={ICON_CLASSNAME} />}
              label="Feed Data"
            />
            <MobileBottomNavBarItem
              href={APP_URLS.REPORTS_LIST}
              icon={<FlagIcon className={ICON_CLASSNAME} />}
              label="Reports"
            />
            <MobileBottomNavBarItem
              href={APP_URLS.FEEDBACK_LIST}
              icon={<PencilSquareIcon className={ICON_CLASSNAME} />}
              label="Feedbacks"
            />
          </>
        )}
      </MobileNavBarExpandableMenu>
    </nav>
  );
}
