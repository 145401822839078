import React, { MouseEvent, useRef } from "react";
import ReactPlayer from "react-player";
import { OnProgressProps } from "react-player/base";

interface Props {
  progress: OnProgressProps;
  duration: number;
  player: ReactPlayer | null;
}

/**
 * Seek bar for VideoPlayer.
 */
export default function SeekBar({ progress, duration, player }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  /**
   * Calculate the width of the played bar element in the seek bar.
   */
  function calculateActiveElementWidth(progress: OnProgressProps) {
    const played = progress.played;
    const maxWidth = ref.current?.offsetWidth;

    if (played && maxWidth) {
      return maxWidth * played;
    } else {
      return 0;
    }
  }

  /**
   * Seek to the clicked position in the seek bar.
   */
  function onClickSeekBar(e: MouseEvent<HTMLDivElement>) {
    const xCoordinate = e.pageX;
    if (duration && ref.current?.offsetWidth && player) {
      const seekToSecond = duration * (xCoordinate / ref.current?.offsetWidth);
      player?.seekTo(seekToSecond);
    }
  }

  return (
    <div
      className="w-full bg-neutral-800"
      style={{ height: "100%" }}
      ref={ref}
      onClick={onClickSeekBar}
    >
      <div
        className="bg-red-600 h-full transition-[width]"
        style={{ width: calculateActiveElementWidth(progress) }}
      ></div>
    </div>
  );
}
