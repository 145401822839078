import { Playlist, PlaylistItem } from "types/playlist";
import NoImage from "images/No-Image.png";

/**
 * Return the image of the latest playlist item if it exists, otherwise return the default image.
 */
export function selectLatestPlaylistItemImg(
  playlist: Playlist | undefined | null,
) {
  if (!playlist) {
    return NoImage;
  }

  const items = playlist.items;

  if (items.length === 0) {
    return NoImage;
  }
  let latestItem = items[0];
  items.forEach((item) => {
    const itemDate = new Date(item.created_at);
    const latestItemDate = new Date(latestItem.created_at);
    if (itemDate > latestItemDate) {
      latestItem = item;
    }
  });

  return latestItem.feed_item?.image || NoImage;
}

/**
 * Return the image of the given playlist item if it exists, otherwise return the default image.
 */
export function getPlaylistItemImage(item: PlaylistItem) {
  return item.feed_item?.image || NoImage;
}
