import { TrashIcon } from "@heroicons/react/24/outline";
import FileUpload from "components/common/file/FileUpload";
import QuestionImage from "components/quiz/QuestionImage";
import { Checkbox, Label, Select, TextInput } from "flowbite-react";
import { displayErrors } from "helpers/errors";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import {
  QUESTION_CATEGORIES,
  QuizQuestionFormData,
  QuizQuestionCategory,
  QUESTION_CATEGORIES_LABELS,
  QUESTION_TYPES,
  QUESTION_TYPES_LABELS,
  QuizQuestionType,
  QUESTION_TYPES_EXPLANATIONS,
} from "types/quiz";
import { ErrorMessages } from "types/redux/slice";

interface Props {
  data: QuizQuestionFormData;
  onChange: (data: QuizQuestionFormData) => void;
  errors?: ErrorMessages;
  idx: number;
}

/**
 * Quiz question input fields.
 */
export default function QuizQuestionInput({
  data,
  onChange,
  errors,
  idx,
}: Props) {
  const aggregatedUploadQuestionImageErrorMessages = useAppSelector(
    (state) => state.quiz.aggregatedUploadQuestionImageErrorMessages,
  );
  const [showImageUpload, setShowImageUpload] = useState(
    !!(data.external_image || data.image) || false,
  );
  const randomId = (Math.random() + 1).toString(36).substring(7);
  // Disable if the quiz has no id - meaning it has not been created yet
  const isImageCheckboxDisabled = !data?.id;

  /**
   * Handle input change.
   */
  function handleOnChange(
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ) {
    let value: boolean | string | number;
    if (e.target.type === "checkbox") {
      value = (e as React.ChangeEvent<HTMLInputElement>).target.checked;
    } else {
      value = e.target.value;
    }
    const key = e.target.name as keyof QuizQuestionFormData;
    const newData = { ...data, [key]: value };
    onChange(newData);
  }

  /**
   * Handle image change.
   */
  function handleImageChange(file: File | null) {
    const newData = { ...data, external_image: "", image: file };
    onChange(newData);
  }

  /**
   * Handle external image change.
   */
  function handleExternalImageChange(e: React.ChangeEvent<HTMLInputElement>) {
    const external_image = e.target.value;
    const newData = { ...data, external_image, image: null };
    onChange(newData);
  }

  /**
   * Clear image.
   */
  function clearImage() {
    onChange({ ...data, external_image: "", image: null });
  }

  /**
   * Generate disabled checkbox title.
   */
  function generateDisabledCheckboxTitle() {
    if (isImageCheckboxDisabled) {
      return "You must first submit the question before you can attach an image to it";
    }
    return undefined;
  }

  /**
   * Show image upload input field if the answer already has an image.
   */
  useEffect(() => {
    setShowImageUpload(!!(data.external_image || data.image) || false);
  }, [data]);

  return (
    <div className="flex flex-col gap-2">
      <Label value="Question" />
      {displayErrors(errors?.text as string[])}
      <TextInput
        type="text"
        value={data.text}
        name="text"
        onChange={handleOnChange}
        placeholder="What's your favorite movie?"
        aria-label="Question text"
      />

      <Label value="Identifier of the question" />
      {displayErrors(errors?.value as string[])}
      <TextInput
        type="text"
        value={data.value}
        name="value"
        onChange={handleOnChange}
        placeholder="favorite-movie"
        aria-label="Question identifier"
      />

      <Label value="Category of the question" />
      {displayErrors(errors?.category as string[])}
      <Select
        value={data.category}
        name="category"
        onChange={handleOnChange}
        aria-label="Question category"
      >
        {Object.keys(QUESTION_CATEGORIES).map((key) => (
          <option key={key} value={key}>
            {QUESTION_CATEGORIES_LABELS[key as QuizQuestionCategory]}
          </option>
        ))}
      </Select>

      <Label value="Type of the question" />
      {displayErrors(errors?.type as string[])}
      <Select
        value={data.type}
        name="type"
        onChange={handleOnChange}
        aria-label="Question type"
      >
        {Object.keys(QUESTION_TYPES).map((key) => (
          <option
            key={key}
            value={key}
            title={QUESTION_TYPES_EXPLANATIONS[key as QuizQuestionType]}
          >
            {QUESTION_TYPES_LABELS[key as QuizQuestionType]}
          </option>
        ))}
      </Select>

      <Label value="Position of the question on the questionnaire page" />
      {displayErrors(errors?.position as string[])}
      <TextInput
        type="number"
        min="1"
        value={data.position}
        name="position"
        onChange={handleOnChange}
        aria-label="Question position"
      />

      {displayErrors(errors?.required as string[])}
      <div className="flex items-center gap-2">
        <Checkbox
          checked={data.required}
          name="required"
          onChange={handleOnChange}
          aria-label="Is an answer to this question required?"
        />
        <Label value="Is an answer to this question required?" />
      </div>

      <div className="flex items-center gap-2">
        <Checkbox
          disabled={isImageCheckboxDisabled}
          title={generateDisabledCheckboxTitle()}
          className={isImageCheckboxDisabled ? "cursor-not-allowed" : ""}
          checked={showImageUpload}
          onChange={() => setShowImageUpload(!showImageUpload)}
          aria-label="Would you like to assign an image to this question?"
        />
        <Label value="Would you like to assign an image to this question?" />
      </div>

      {showImageUpload && (
        <div className="border rounded p-4 space-y-2">
          <Label value="Upload image" />
          {displayErrors(
            aggregatedUploadQuestionImageErrorMessages[idx]?.image,
          )}
          <FileUpload
            file={data.image instanceof File ? data.image : null}
            setFile={handleImageChange}
            inputId={randomId}
          />
          <div className="text-center">- or -</div>
          <Label value="Provide a link to the image" />
          {displayErrors(
            aggregatedUploadQuestionImageErrorMessages[idx]?.external_image,
          )}
          <TextInput
            type="text"
            value={data.external_image || ""}
            name="external_image"
            onChange={handleExternalImageChange}
            aria-label="Provide a link to the image"
          />
          {(data.image || data.external_image) && (
            <>
              <div>
                <Label value="Image preview" />
              </div>
              <div className="relative w-[128px] h-[128px]">
                <QuestionImage question={data} />
                <button
                  className="top-1 right-1 absolute"
                  onClick={clearImage}
                  aria-label="Clear image"
                >
                  <TrashIcon className="w-5 h-5 text-red-700" />
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
