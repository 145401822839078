import React from "react";
import { retrieveUsersDataset } from "redux/feed/feedDatasetSlice";
import { useAppSelector } from "redux/hooks";
import FeedDataTable from "../common/FeedDataTable";
import { APP_URLS } from "navigation";

/**
 * Component for displaying a list of user-related feed data.
 */
export default function FeedDataUsersList() {
  function onClickRow(itemId: string) {
    // Navigate to user details page
    window.location.href = APP_URLS.FEED_DATA_USER_DETAILS.replace(
      ":userId",
      itemId,
    );
  }

  return (
    <FeedDataTable
      headerText="Users"
      defaultOrdering="email"
      paginatedList={useAppSelector((state) => state.feedDataset.users)}
      fetchThunkFunction={retrieveUsersDataset}
      pending={useAppSelector(
        (state) => state.feedDataset.retrieveUsersDatasetPending,
      )}
      labels={{
        id: "ID",
        email: "Email",
        like_count: "Likes",
        dislike_count: "Dislikes",
        watch_count: "Watches",
        skip_count: "Skips",
      }}
      onClickRow={onClickRow}
    />
  );
}
