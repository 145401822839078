import { get, post } from "./apiService";

/**
 * Send a GET request to the API to retrieve the Youtube login URL
 */
export const retrieveYoutubeLoginUrl = async () => {
  return await get("/api/youtube/auth/login");
};

/**
 * Send a GET request to the API to upload a Google Takeout file
 */
export const uploadGoogleTakeout = async (data: FormData) => {
  return await post(
    "/api/youtube/takeout/",
    data,
    false,
    "multipart/form-data",
  );
};
