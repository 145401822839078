import { Spinner } from "flowbite-react";
import React from "react";

interface Props {
  title: string | JSX.Element;
  loading?: boolean;
  isSubHeader?: boolean;
}

/**
 * A header component to be used in conjunction with PageWrapper.
 */
export default function Header({ title, loading, isSubHeader }: Props) {
  return (
    <div
      className={`flex items-center px-6 py-4 border-b ${
        isSubHeader && "border-t"
      }`}
      role="banner"
      aria-live="polite"
    >
      {loading ? (
        <div className="flex justify-center w-full">
          <Spinner size="sm" aria-label="Loading" />
        </div>
      ) : (
        <h3 className="text-lg font-semibold text-gray-800 capitalize">
          {title}
        </h3>
      )}
    </div>
  );
}
