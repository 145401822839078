import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import Header from "components/common/header";
import FeedDataVideosList from "components/admin/feedData/lists/FeedDataVideosList";
import FeedDataNewsList from "components/admin/feedData/lists/FeedDataNewsList";
import FeedDataTracksList from "components/admin/feedData/lists/FeedDataTracksList";
import FeedDataUsersList from "components/admin/feedData/lists/FeedDataUsersList";

/**
 * Page displaying feed data.
 */
export default function FeedData() {
  useEffect(() => {
    document.title = "Feed Data | BrainCargo";
  }, []);

  return (
    <PageWrapper>
      <div className="border shadow-md lg:rounded-lg bg-white pb-6 space-y-2">
        <Header title="Feed Data" />
        <div className="grid grid-cols-1 gap-4 py-2 px-4">
          <div className="col-span-1 border rounded p-2 bg-gray-100" aria-label="Users List">
            <FeedDataUsersList />
          </div>
          <div className="col-span-1 border rounded p-2 bg-gray-100" aria-label="Videos List">
            <FeedDataVideosList />
          </div>
          <div className="col-span-1 border rounded p-2 bg-gray-100" aria-label="Tracks List">
            <FeedDataTracksList />
          </div>
          <div className="col-span-1 border rounded p-2 bg-gray-100" aria-label="News List">
            <FeedDataNewsList />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
