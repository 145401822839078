import Modal from "components/common/modal";
import React from "react";
import { useAppDispatch } from "redux/hooks";
import {
  getMyPlaylists,
  removeItemFromPlaylist,
} from "redux/playlist/playlistSlice";
import { isDispatchResponseError } from "redux/utils";
import { Playlist, RemoveItemFromPlaylistPayload } from "types/playlist";

interface Props {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  itemId: string;
  playlist: Playlist;
}

/**
 * Modal for removing a feed item from the default "Saved" playlist.
 */
export default function ConfirmRemoveFromSavedModal({
  showModal,
  setShowModal,
  itemId,
  playlist,
}: Props) {
  const dispatch = useAppDispatch();

  /**
   * Removes the feed item from the playlist.
   */
  async function handleSubmit() {
    const data: RemoveItemFromPlaylistPayload = {
      playlist_id: playlist.id,
      feed_item_id: itemId,
    };
    const response = await dispatch(removeItemFromPlaylist(data));
    dispatch(getMyPlaylists({}));

    if (!isDispatchResponseError(response)) {
      setShowModal(false);
    }
  }

  return (
    <Modal
      show={showModal}
      onClose={() => setShowModal(false)}
      onSubmit={handleSubmit}
      headerText={`Remove item from ${playlist.name}?`}
      submitButtonText="Remove"
      submitButtonColor="failure"
      body={
        <div className="space-y-2">
          <div className="font-medium">
            The selected item will be removed from all of your playlists.
          </div>
        </div>
      }
    />
  );
}
