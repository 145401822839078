import React from "react";

/**
 * Component that renders a separator in MobileBottomNavBar.
 */
export default function MobileBottomNavBarSeparator() {
  return (
    <div className="col-span-5 flex items-center justify-center p-2 text-base font-normal text-gray-400 gap-3 select-none">
      <div className="block h-[1px] w-full bg-gray-300 rounded-full"></div>
      <span>Admin</span>
      <div className="block h-[1px] w-full bg-gray-300 rounded-full"></div>
    </div>
  );
}
