import React, { useEffect, useState } from "react";
import PageWrapper from "components/common/pageWrapper";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useParams } from "react-router-dom";
import { displayErrors } from "helpers/errors";
import QuizResultsComponent from "components/quiz/QuizResults";
import { retrieveQuizzes } from "redux/quiz/quizSlice";
import { Quiz } from "types/quiz";
import Header from "components/common/header";

/**
 * Quiz Results page
 */
export default function QuizResults() {
  const { id } = useParams();
  const quizzes = useAppSelector((store) => store.quiz.quizzes);
  const pendingRetrieveQuizzes = useAppSelector(
    (store) => store.quiz.pendingRetrieveQuizzes,
  );
  const quizzesErrorMessages = useAppSelector(
    (state) => state.quiz.quizzesErrorMessages,
  );
  const [quiz, setQuiz] = useState<Quiz | null>(null);
  const dispatch = useAppDispatch();

  function renderErrors() {
    if (quizzesErrorMessages) {
      // Render a different message for this specific case
      let errors = quizzesErrorMessages?.id;
      if (
        quizzesErrorMessages?.id &&
        quizzesErrorMessages.id.includes("Enter a valid UUID.")
      ) {
        errors = ["Couldn't find a quiz with provided id."];
      }
      return displayErrors(errors);
    }
  }

  useEffect(() => {
    document.title = "Quiz Results | BrainCargo";
  }, []);

  /**
   * Extract quiz data
   */
  useEffect(() => {
    if (quizzes.results.length > 0) {
      setQuiz({ ...quizzes.results[0] });
    } else {
      setQuiz(null);
    }
  }, [quizzes]);

  /**
   * Fetch quiz data
   */
  useEffect(() => {
    if (id) {
      dispatch(retrieveQuizzes({ queryParams: { id } }));
    }
  }, [id]);

  return (
    <PageWrapper>
      <div className="border shadow-md lg:rounded-lg bg-white pb-6 space-y-2">
        <Header
          loading={pendingRetrieveQuizzes}
          title={`${quiz?.name} results` || "UNKNOWN QUIZ NAME"}
        />

        <div className="px-6 space-y-2">
          {Object.keys(quizzesErrorMessages).length === 0 ? (
            <QuizResultsComponent quizId={id || null} />
          ) : (
            renderErrors()
          )}
        </div>
      </div>
    </PageWrapper>
  );
}
