import React, { useState } from "react";
import { Button, Label, Spinner, TextInput } from "flowbite-react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { displayErrors } from "helpers/errors";
import { addItemToPlaylists, getPlaylist } from "redux/playlist/playlistSlice";
import { isDispatchResponseError } from "redux/utils";
import Modal from "components/common/modal";
import { FEED_ITEM_TYPE_LABELS, FeedItem, FeedItemType } from "types/feed";
import { Playlist } from "types/playlist";
import {
  addFeedItem,
  clearErrorMessages,
  clearSearchedFeedItems,
  searchForFeedItems,
} from "redux/feed/feedItemsSlice";
import { getFeedItemImage } from "helpers/feedItem";
import { replaceSpecialChars } from "helpers/text";

interface Props {
  show: boolean;
  onClose: () => void;
  playlist: Playlist | null;
}

/**
 * Component used for adding a feed item to a playlist with a direct link.
 */
export default function ModalAddToPlaylistDirectLink({
  show,
  onClose,
  playlist,
}: Props) {
  const dispatch = useAppDispatch();
  const [url, setUrl] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const searchedFeedItems = useAppSelector(
    (state) => state.feedItems.searchedFeedItems,
  );
  const pendingSearchForFeedItems = useAppSelector(
    (state) => state.feedItems.pendingSearchForFeedItems,
  );
  const pendingAddFeedItem = useAppSelector(
    (state) => state.feedItems.pendingAddFeedItem,
  );
  const addItemToPlaylistsPending = useAppSelector(
    (state) => state.playlist.addItemToPlaylistsPending,
  );
  const errorMessages = useAppSelector(
    (state) => state.feedItems.errorMessages,
  );
  const user = useAppSelector((state) => state.user.user);

  /**
   * Search for feed items with the provided url and title.
   */
  function handleClickSearch() {
    const queryParams = { url, title };
    dispatch(searchForFeedItems({ queryParams }));
  }

  /**
   * Add the feed item from the search results to the playlist.
   */
  async function handleClickAdd() {
    if (searchedFeedItems.length > 0 && playlist) {
      const responseAddFeedItem = await dispatch(
        addFeedItem(searchedFeedItems[0]),
      );
      if (!isDispatchResponseError(responseAddFeedItem)) {
        const payload = responseAddFeedItem.payload as FeedItem;
        const responseAddToPlaylist = await dispatch(
          addItemToPlaylists({
            feed_item_id: payload.id,
            playlist_ids: [playlist.id],
          }),
        );
        if (!isDispatchResponseError(responseAddToPlaylist)) {
          await dispatch(getPlaylist({ id: playlist.id }));
          handleClose();
        }
      }
    }
  }

  /**
   * Close the modal and reset the state.
   */
  function handleClose() {
    setUrl("");
    setTitle("");
    dispatch(clearSearchedFeedItems());
    dispatch(clearErrorMessages());
    onClose();
  }

  if (!playlist) {
    return null;
  }

  return (
    <React.Fragment>
      <Modal
        show={show}
        onClose={handleClose}
        headerText={"Add an item to playlist with a direct link"}
        body={
          <form onSubmit={handleClickSearch} className="space-y-4">
            <div>
              <Label value="Paste your link below" />
              <TextInput
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="e.g. https://www.youtube.com/watch?v=1234567890"
                aria-label="URL input"
              />
              {displayErrors(errorMessages.url)}
              {!user?.is_spotify_connected && displayErrors("You must connect your Spotify account to view tracks - you can do that on your profile page.")}
            </div>
            <div>
              <Label value="If your link leads to a news article, please provide its title as well" />
              <TextInput
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="e.g. Dream debut for rising golf star Lee"
                aria-label="Title input"
              />
              {displayErrors(errorMessages.title)}
            </div>
            <div>
              {pendingSearchForFeedItems ? (
                <div className="flex justify-center items-center">
                  <Spinner size="lg" aria-label="Loading spinner" />
                </div>
              ) : (
                <>
                  {searchedFeedItems.length > 0 && (
                    <>
                      <div>
                        <Label value="This is what we found:" />
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="flex flex-col justify-center items-center gap-1 w-20">
                          <img
                            src={getFeedItemImage(searchedFeedItems[0])}
                            className="h-16 w-16 rounded-full"
                            alt="Feed item"
                          />
                          <span className="text-sm font-medium">
                            {FEED_ITEM_TYPE_LABELS[searchedFeedItems[0].type]}
                          </span>
                        </div>
                        <div className="flex flex-col justify-center items-start w-full gap-1">
                          <span className="text-base font-semibold">
                            {replaceSpecialChars(searchedFeedItems[0].title)}
                          </span>
                          {searchedFeedItems[0].description && (
                            <span className="text-sm">
                              {replaceSpecialChars(
                                searchedFeedItems[0].description,
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </form>
        }
        customFooter={
          <>
            <Button
              className="px-10 m-auto"
              onClick={handleClickSearch}
              disabled={!url}
              color={"info"}
              aria-label="Search button"
            >
              {pendingSearchForFeedItems ? <Spinner size="sm" aria-label="Loading spinner" /> : "Search"}
            </Button>
            {searchedFeedItems.length > 0 && !pendingSearchForFeedItems && (
              <Button
                className="px-10 m-auto"
                onClick={handleClickAdd}
                color={"info"}
                aria-label="Add button"
              >
                {pendingAddFeedItem || addItemToPlaylistsPending ? (
                  <Spinner size="sm" aria-label="Loading spinner" />
                ) : (
                  "Add"
                )}
              </Button>
            )}
          </>
        }
      ></Modal>
    </React.Fragment>
  );
}
