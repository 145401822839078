import React from "react";
import { Playlist } from "types/playlist";
import PlaylistActions from "./PlaylistActions";
import PlaylistDetailsItem from "./PlaylistDetailsItem";
import { selectLatestPlaylistItemImg } from "helpers/playlist";

interface Props {
  playlist: Playlist | null;
}

/**
 * Component used for displaying details of a playlist.
 */
export default function PlaylistDetails({ playlist }: Props) {
  const lastUpdateDate = getLatestItemUpdateDate();

  /**
   * Get the date of the latest item in the playlist.
   */
  function getLatestItemUpdateDate() {
    if (playlist) {
      const items = playlist.items;
      if (items.length === 0) {
        return null;
      }

      let latestItem = items[0];
      items.forEach((item) => {
        const itemDate = new Date(item.created_at);
        const latestItemDate = new Date(latestItem.created_at);
        if (itemDate > latestItemDate) {
          latestItem = item;
        }
      });

      const date = new Date(latestItem.created_at);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return date.toLocaleDateString(undefined, options);
    }
    return null;
  }

  /**
   * Render a separator between playlist items.
   */
  function renderPlaylistItemsSeparator() {
    return <div className="h-[1px] w-full bg-neutral-300"></div>;
  }

  return (
    <div className="flex flex-col justify-center items-center space-y-6">
      {playlist ? (
        <>
          <div className="flex flex-col justify-center items-center space-y-2 w-full">
            <img
              src={selectLatestPlaylistItemImg(playlist)}
              className="h-[200px] rounded-xl"
              alt={`Thumbnail for playlist ${playlist.name || "Unknown"}`}
            />
            <span className="font-bold text-2xl text-neutral-900">
              {playlist.name || "Unknown"}
            </span>
            {lastUpdateDate && (
              <span className="font-semibold text-base text-neutral-500">
                Updated {lastUpdateDate}
              </span>
            )}
            <div className="w-full">
              <PlaylistActions playlist={playlist} />
            </div>
          </div>

          {playlist.items.length === 0 && (
            <div className="flex flex-col border-t-2 w-full justify-center items-center py-4 text-center text-xl text-neutral-600 font-medium">
              <div>This playlist is empty</div>
              <div>You can add items to playlists on the feed page</div>
            </div>
          )}

          {playlist.items.length !== 0 && (
            <div className="flex flex-col w-full justify-center items-center space-y-2">
              {playlist.items.map((item, idx) => (
                <div key={item.id} className="flex flex-col space-y-2">
                  {idx === 0 && renderPlaylistItemsSeparator()}
                  <PlaylistDetailsItem item={item} playlist={playlist} />
                  {renderPlaylistItemsSeparator()}
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        <div className="font-bold text-2xl text-neutral-900 text-center">
          Playlist could not be found
        </div>
      )}
    </div>
  );
}
