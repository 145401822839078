import {
  QuizFormData,
  QuizQuestionAnswer,
  QuizQuestionAnswerOptionImageFormData,
  QuizQuestionImageFormData,
  QuizResults,
} from "types/quiz";
import { API_BASE_URL, deleteRequest, get, patch, post } from "./apiService";
import { QueryParams } from "types/api";

/**
 * Send a GET request to the API to retrieve quizzes
 */
export const retrieveQuizzes = async (
  pageUrl?: string | null,
  queryParams?: QueryParams,
) => {
  // Determine Quiz list endpoint URL
  const url = pageUrl ? pageUrl.replace(API_BASE_URL, "") : "/api/quiz/";

  return get(url, undefined, undefined, queryParams);
};

/**
 * Send a GET request to the API to retrieve quiz questions
 */
export const retrieveQuizQuestions = async (quizId: string) => {
  // Retrieve a list of quiz questions from the backend.
  return get(`/api/quiz/${quizId}/questions/`);
};

/**
 * Send a POST request to the API to submit quiz answers
 */
export const submitQuizAnswers = async (
  data: QuizQuestionAnswer[],
): Promise<QuizQuestionAnswer[]> => {
  return post("/api/quiz/answers/", data);
};

/**
 * Send a GET request to the API to retrieve quiz answers
 */
export const retrieveQuizAnswers = async (
  quizId: string,
): Promise<QuizQuestionAnswer[]> => {
  return get(`/api/quiz/${quizId}/answers/`);
};

/**
 * Send a PATCH request to the API to update a quiz
 */
export const updateQuiz = async (
  quizId: string,
  data: QuizFormData,
): Promise<QuizQuestionAnswer[]> => {
  return patch(`/api/quiz/${quizId}/`, data);
};

/**
 * Send a POST request to the API to create a new quiz
 */
export const createQuiz = async (
  data: QuizFormData,
): Promise<QuizQuestionAnswer[]> => {
  return post("/api/quiz/", data);
};

/**
 * Send a DELETE request to the API to delete a quiz
 */
export const removeQuiz = async (
  quizId: string,
): Promise<QuizQuestionAnswer[]> => {
  return deleteRequest(`/api/quiz/${quizId}/`);
};

/**
 * Send a GET request to the API to retrieve quiz results
 */
export const retrieveQuizResults = async (
  quizId: string,
): Promise<QuizResults> => {
  return get(`/api/quiz/${quizId}/results/`);
};

/**
 * Send a PATCH request to the API upload an image for a quiz question answer option
 */
export const uploadAnswerOptionImage = async (
  data: QuizQuestionAnswerOptionImageFormData,
): Promise<QuizQuestionAnswerOptionImageFormData> => {
  return patch(
    `/api/quiz/answer_options/${data.answer_option}/upload_image/`,
    data,
    false,
    "multipart/form-data",
  );
};

/**
 * Send a PATCH request to the API upload an image for a quiz question
 */
export const uploadQuestionImage = async (
  data: QuizQuestionImageFormData,
): Promise<QuizQuestionImageFormData> => {
  return patch(
    `/api/quiz/questions/${data.question}/upload_image/`,
    data,
    false,
    "multipart/form-data",
  );
};
