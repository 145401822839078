import React, {useEffect} from "react";
import PageWrapper from "components/common/pageWrapper";
import Header from "components/common/header";
import ReportListTable from "components/report/ReportListTable";

/**
 * Reports list page.
 */
export default function Reports() {
  useEffect(() => {
    document.title = "Reports | BrainCargo";
  }, []);

  return (
    <PageWrapper>
      <div className="border shadow-md lg:rounded-lg bg-white pb-6 space-y-2">
        <Header title="Reports" />
        <div className="py-2 px-4" aria-label="Reports List">
          <ReportListTable />
        </div>
      </div>
    </PageWrapper>
  );
}
