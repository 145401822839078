import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  retrieveQuizQuestions as retrieveQuizQuestionsService,
  submitQuizAnswers as submitQuizAnswersService,
  retrieveQuizAnswers as retrieveQuizAnswersService,
  retrieveQuizzes as retrieveQuizzesService,
  updateQuiz as updateQuizService,
  createQuiz as createQuizService,
  removeQuiz as removeQuizService,
  retrieveQuizResults as retrieveQuizResultsService,
  uploadAnswerOptionImage as uploadAnswerOptionImageService,
  uploadQuestionImage as uploadQuestionImageService,
} from "services/quizService";
import { PaginatedList, QueryParams } from "types/api";
import {
  Quiz,
  QuizFormData,
  QuizQuestion,
  QuizQuestionAnswer,
  QuizQuestionAnswerOptionImageFormData,
  QuizQuestionImageFormData,
  QuizResults,
} from "types/quiz";
import { ErrorMessages, NestedErrorMessages } from "types/redux/slice";

interface QuizState {
  quizzes: PaginatedList<Quiz>;
  quizResults: QuizResults | null;
  questions: QuizQuestion[];
  answers: QuizQuestionAnswer[];
  pendingRetrieveQuizzes: boolean;
  pendingRetrieveQuizQuestions: boolean;
  pendingQuizEditCreate: boolean;
  pendingSubmitQuizAnswers: boolean;
  pendingRetrieveQuizAnswers: boolean;
  pendingRemoveQuiz: boolean;
  pendingRetrieveQuizResults: boolean;
  pendingUploadAnswerOptionImage: boolean;
  pendingUploadQuestionImage: boolean;
  questionErrorMessages: ErrorMessages;
  quizzesErrorMessages: ErrorMessages;
  answerErrorMessages: ErrorMessages[];
  quizEditCreateErrorMessages: NestedErrorMessages;
  retrieveQuizResultsErrorMessages: ErrorMessages;
  uploadAnswerOptionImageErrorMessages: ErrorMessages;
  uploadQuestionImageErrorMessages: ErrorMessages;
  aggregatedUploadAnswerOptionImageErrorMessages: {
    [answerOptionId: number]: ErrorMessages;
  };
  aggregatedUploadQuestionImageErrorMessages: ErrorMessages[];
}

const initialState: QuizState = {
  quizzes: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  quizResults: null,
  questions: [],
  answers: [],
  pendingRetrieveQuizzes: false,
  pendingRetrieveQuizQuestions: false,
  pendingQuizEditCreate: false,
  pendingSubmitQuizAnswers: false,
  pendingRetrieveQuizAnswers: false,
  pendingRemoveQuiz: false,
  pendingRetrieveQuizResults: false,
  pendingUploadAnswerOptionImage: false,
  pendingUploadQuestionImage: false,
  questionErrorMessages: {},
  quizzesErrorMessages: {},
  answerErrorMessages: [],
  quizEditCreateErrorMessages: {},
  retrieveQuizResultsErrorMessages: {},
  uploadAnswerOptionImageErrorMessages: {},
  uploadQuestionImageErrorMessages: {},
  aggregatedUploadAnswerOptionImageErrorMessages: [],
  aggregatedUploadQuestionImageErrorMessages: [],
};

export const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    resetQuizzes(state, action: PayloadAction<undefined>) {
      state.quizzes = initialState.quizzes;
    },
    setAggregatedUploadQuestionImageErrorMessages(
      state,
      action: PayloadAction<ErrorMessages[]>,
    ) {
      state.aggregatedUploadQuestionImageErrorMessages = action.payload;
    },
    setAggregatedUploadAnswerOptionImageErrorMessages(
      state,
      action: PayloadAction<{ [answerOptionId: number]: ErrorMessages }>,
    ) {
      state.aggregatedUploadAnswerOptionImageErrorMessages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveQuizzes.fulfilled, (state, action) => {
      state.pendingRetrieveQuizzes = false;
      state.quizzesErrorMessages = {};
      state.quizzes = action.payload;
    });
    builder.addCase(retrieveQuizzes.pending, (state, action) => {
      state.pendingRetrieveQuizzes = true;
      state.quizzesErrorMessages = {};
      state.quizzes = { ...initialState.quizzes };
    });
    builder.addCase(retrieveQuizzes.rejected, (state, action) => {
      state.pendingRetrieveQuizzes = false;
      state.quizzesErrorMessages = action.payload || {};
      state.quizzes = { ...initialState.quizzes };
    });

    builder.addCase(retrieveQuizQuestions.fulfilled, (state, action) => {
      state.pendingRetrieveQuizQuestions = false;
      state.questionErrorMessages = {};
      state.questions = action.payload;
    });
    builder.addCase(retrieveQuizQuestions.pending, (state, action) => {
      state.pendingRetrieveQuizQuestions = true;
      state.questionErrorMessages = {};
      state.questions = [];
    });
    builder.addCase(retrieveQuizQuestions.rejected, (state, action) => {
      state.pendingRetrieveQuizQuestions = false;
      state.questionErrorMessages = action.payload || {};
      state.questions = [];
    });

    builder.addCase(submitQuizAnswers.fulfilled, (state, action) => {
      state.pendingSubmitQuizAnswers = false;
      state.answerErrorMessages = [];
    });
    builder.addCase(submitQuizAnswers.pending, (state, action) => {
      state.pendingSubmitQuizAnswers = true;
      state.answerErrorMessages = [];
    });
    builder.addCase(submitQuizAnswers.rejected, (state, action) => {
      state.pendingSubmitQuizAnswers = false;
      state.answerErrorMessages = action.payload || [];
    });

    builder.addCase(retrieveQuizAnswers.fulfilled, (state, action) => {
      state.pendingRetrieveQuizAnswers = false;
      state.answerErrorMessages = [];
      state.answers = action.payload;
    });
    builder.addCase(retrieveQuizAnswers.pending, (state, action) => {
      state.pendingRetrieveQuizAnswers = true;
      state.answerErrorMessages = [];
      state.answers = [];
    });
    builder.addCase(retrieveQuizAnswers.rejected, (state, action) => {
      state.pendingRetrieveQuizAnswers = false;
      state.answerErrorMessages = action.payload || [];
      state.answers = [];
    });

    builder.addCase(updateQuiz.fulfilled, (state, action) => {
      state.pendingQuizEditCreate = false;
      state.quizEditCreateErrorMessages = {};
    });
    builder.addCase(updateQuiz.pending, (state, action) => {
      state.pendingQuizEditCreate = true;
      state.quizEditCreateErrorMessages = {};
    });
    builder.addCase(updateQuiz.rejected, (state, action) => {
      state.pendingQuizEditCreate = false;
      state.quizEditCreateErrorMessages = action.payload || {};
    });

    builder.addCase(createQuiz.fulfilled, (state, action) => {
      state.pendingQuizEditCreate = false;
      state.quizEditCreateErrorMessages = {};
    });
    builder.addCase(createQuiz.pending, (state, action) => {
      state.pendingQuizEditCreate = true;
      state.quizEditCreateErrorMessages = {};
    });
    builder.addCase(createQuiz.rejected, (state, action) => {
      state.pendingQuizEditCreate = false;
      state.quizEditCreateErrorMessages = action.payload || {};
    });

    builder.addCase(removeQuiz.fulfilled, (state, action) => {
      state.pendingRemoveQuiz = false;
    });
    builder.addCase(removeQuiz.pending, (state, action) => {
      state.pendingRemoveQuiz = true;
    });
    builder.addCase(removeQuiz.rejected, (state, action) => {
      state.pendingRemoveQuiz = false;
    });

    builder.addCase(retrieveQuizResults.fulfilled, (state, action) => {
      state.pendingRetrieveQuizResults = false;
      state.quizResults = action.payload;
      state.retrieveQuizResultsErrorMessages = {};
    });
    builder.addCase(retrieveQuizResults.pending, (state, action) => {
      state.pendingRetrieveQuizResults = true;
      state.quizResults = null;
      state.retrieveQuizResultsErrorMessages = {};
    });
    builder.addCase(retrieveQuizResults.rejected, (state, action) => {
      state.pendingRetrieveQuizResults = false;
      state.quizResults = null;
      state.retrieveQuizResultsErrorMessages = action.payload || {};
    });

    builder.addCase(uploadAnswerOptionImage.fulfilled, (state, action) => {
      state.pendingUploadAnswerOptionImage = false;
      state.uploadAnswerOptionImageErrorMessages = {};
    });
    builder.addCase(uploadAnswerOptionImage.pending, (state, action) => {
      state.pendingUploadAnswerOptionImage = true;
      state.uploadAnswerOptionImageErrorMessages = {};
    });
    builder.addCase(uploadAnswerOptionImage.rejected, (state, action) => {
      state.pendingUploadAnswerOptionImage = false;
      state.uploadAnswerOptionImageErrorMessages = action.payload || {};
    });

    builder.addCase(uploadQuestionImage.fulfilled, (state, action) => {
      state.pendingUploadQuestionImage = false;
      state.uploadQuestionImageErrorMessages = {};
    });
    builder.addCase(uploadQuestionImage.pending, (state, action) => {
      state.pendingUploadQuestionImage = true;
      state.uploadQuestionImageErrorMessages = {};
    });
    builder.addCase(uploadQuestionImage.rejected, (state, action) => {
      state.pendingUploadQuestionImage = false;
      state.uploadQuestionImageErrorMessages = action.payload || {};
    });
  },
});

export const retrieveQuizzes = createAsyncThunk<
  PaginatedList<Quiz>,
  { url?: string; queryParams?: QueryParams },
  { rejectValue: ErrorMessages }
>("quiz/retrieveQuizzes", async ({ url, queryParams }, thunkApi) => {
  try {
    const response = await retrieveQuizzesService(url, queryParams);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const retrieveQuizQuestions = createAsyncThunk<
  QuizQuestion[],
  { quizId: string },
  { rejectValue: ErrorMessages }
>("quiz/retrieveQuizQuestions", async ({ quizId }, thunkApi) => {
  try {
    const response = await retrieveQuizQuestionsService(quizId);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const submitQuizAnswers = createAsyncThunk<
  QuizQuestionAnswer[],
  { data: QuizQuestionAnswer[] },
  { rejectValue: ErrorMessages[] }
>("quiz/submitQuizAnswers", async ({ data }, thunkApi) => {
  try {
    const response = await submitQuizAnswersService(data);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages[]);
  }
});

export const retrieveQuizAnswers = createAsyncThunk<
  QuizQuestionAnswer[],
  { quizId: string },
  { rejectValue: ErrorMessages[] }
>("quiz/retrieveQuizAnswers", async ({ quizId }, thunkApi) => {
  try {
    const response = await retrieveQuizAnswersService(quizId);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages[]);
  }
});

export const updateQuiz = createAsyncThunk<
  boolean,
  { quizId: string; data: QuizFormData },
  { rejectValue: NestedErrorMessages }
>("quiz/updateQuiz", async ({ quizId, data }, thunkApi) => {
  try {
    await updateQuizService(quizId, data);
    return true;
  } catch (err) {
    return thunkApi.rejectWithValue(err as NestedErrorMessages);
  }
});

export const createQuiz = createAsyncThunk<
  boolean,
  QuizFormData,
  { rejectValue: NestedErrorMessages }
>("quiz/createQuiz", async (data, thunkApi) => {
  try {
    await createQuizService(data);
    return true;
  } catch (err) {
    return thunkApi.rejectWithValue(err as NestedErrorMessages);
  }
});

export const removeQuiz = createAsyncThunk<
  boolean,
  string,
  { rejectValue: ErrorMessages[] }
>("quiz/removeQuiz", async (quizId, thunkApi) => {
  try {
    await removeQuizService(quizId);
    return true;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages[]);
  }
});

export const retrieveQuizResults = createAsyncThunk<
  QuizResults,
  { quizId: string },
  { rejectValue: ErrorMessages }
>("quiz/retrieveQuizResults", async ({ quizId }, thunkApi) => {
  try {
    const response = await retrieveQuizResultsService(quizId);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const uploadAnswerOptionImage = createAsyncThunk<
  boolean,
  QuizQuestionAnswerOptionImageFormData,
  { rejectValue: ErrorMessages }
>("quiz/uploadAnswerOptionImage", async (data, thunkApi) => {
  try {
    await uploadAnswerOptionImageService(data);
    return true;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const uploadQuestionImage = createAsyncThunk<
  boolean,
  QuizQuestionImageFormData,
  { rejectValue: ErrorMessages }
>("quiz/uploadQuestionImage", async (data, thunkApi) => {
  try {
    await uploadQuestionImageService(data);
    return true;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const {
  resetQuizzes,
  setAggregatedUploadAnswerOptionImageErrorMessages,
  setAggregatedUploadQuestionImageErrorMessages,
} = quizSlice.actions;
