import { Spinner } from "flowbite-react";
import { getFeedItemImage } from "helpers/feedItem";
import { replaceSpecialChars } from "helpers/text";
import { APP_URLS } from "navigation";
import React from "react";
import { addFeedItem } from "redux/feed/feedItemsSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { isDispatchResponseError } from "redux/utils";
import { FEED_ITEM_TYPE_LABELS, FeedItem } from "types/feed";
import MobileTopNavBarSearchResult from "./MobileTopNavBarSearchResult";

interface Props {
  navRef: React.MutableRefObject<HTMLDivElement | null>;
  searchInteractedWith: boolean;
  pendingClickSearchItem: boolean;
  setPendingClickSearchItem: (val: boolean) => void;
  setShowSearchResults: (val: boolean) => void;
}

/**
 * Search results for MobileTopNavBar.
 */
export default function MobileTopNavBarSearchResults({
  navRef,
  searchInteractedWith,
  pendingClickSearchItem,
  setPendingClickSearchItem,
  setShowSearchResults,
}: Props) {
  const searchedFeedItems = useAppSelector(
    (state) => state.feedItems.searchedFeedItems,
  );
  const pendingSearchFeedItems = useAppSelector(
    (state) => state.feedItems.pendingSearchForFeedItems,
  );
  const pendingAddFeedItem = useAppSelector(
    (state) => state.feedItems.pendingAddFeedItem,
  );

  let top = "0px";
  const topNavBarHeight = navRef.current?.offsetHeight || 0;
  const bottomNavBarHeight =
    document.getElementById("mobile-bottom-nav-bar-video-feed")?.offsetHeight ||
    0;
  const height = window.outerHeight - topNavBarHeight - bottomNavBarHeight;

  if (navRef.current) {
    top = navRef.current.offsetHeight + "px";
  }

  return (
    <div
      style={{ position: "absolute", top, left: 0, right: 0, height }}
      className="z-50"
      aria-label="Search Results"
    >
      <div className="flex flex-col items-center w-full bg-gray-100 h-full">
        <div className="flex flex-col items-center w-full overflow-auto h-[90%]">
          {pendingSearchFeedItems ||
          pendingAddFeedItem ||
          pendingClickSearchItem ? (
              <div className="flex justify-center items-center h-full">
                <Spinner size="xl" aria-label="Loading spinner" />
              </div>
            ) : (
              <>
                {searchInteractedWith && searchedFeedItems.length === 0 && (
                  <div className="flex justify-center items-center h-full" aria-live="polite">
                    No results
                  </div>
                )}
                {searchedFeedItems.length !== 0 &&
                  searchedFeedItems.map((feedItem) => {
                    return (
                      <MobileTopNavBarSearchResult
                        key={feedItem.id}
                        feedItem={feedItem}
                        setPendingClickSearchItem={setPendingClickSearchItem}
                      />
                    );
                  })}
              </>
            )}
        </div>
        <div className="flex justify-center items-center w-full mt-auto bg-gray-100 border-t-2 h-[10%]">
          <button
            className="text-xl p-2"
            onClick={() => setShowSearchResults(false)}
            aria-label="Hide search results"
          >
            - Hide search results -
          </button>
        </div>
      </div>
    </div>
  );
}
