import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as feedbackService from "services/feedbackService";
import { PaginatedList, QueryParams } from "types/api";
import { Feedback } from "types/feedback";
import { ErrorMessages } from "types/redux/slice";

interface FeedbackState {
  feedbacks: PaginatedList<Feedback>;
  feedback: Feedback | null;
  pendingCreateFeedback: boolean;
  errorMessagesCreateFeedback: ErrorMessages;
  pendingGetFeedbacks: boolean;
  errorMessagesGetFeedbacks: ErrorMessages;
  pendingGetFeedback: boolean;
  errorMessagesGetFeedback: ErrorMessages;
}

const initialState: FeedbackState = {
  feedbacks: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  feedback: null,
  pendingCreateFeedback: false,
  errorMessagesCreateFeedback: {},
  pendingGetFeedbacks: false,
  errorMessagesGetFeedbacks: {},
  pendingGetFeedback: false,
  errorMessagesGetFeedback: {},
};

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createFeedback.pending, (state, action) => {
      state.pendingCreateFeedback = true;
      state.errorMessagesCreateFeedback = {};
    });
    builder.addCase(createFeedback.fulfilled, (state, action) => {
      state.pendingCreateFeedback = false;
      state.errorMessagesCreateFeedback = {};
    });
    builder.addCase(createFeedback.rejected, (state, action) => {
      state.pendingCreateFeedback = false;
      state.errorMessagesCreateFeedback = action.payload as ErrorMessages;
    });

    builder.addCase(retrieveFeedbacks.pending, (state, action) => {
      state.pendingGetFeedbacks = true;
      state.errorMessagesGetFeedbacks = {};
    });
    builder.addCase(retrieveFeedbacks.fulfilled, (state, action) => {
      state.feedbacks = action.payload as PaginatedList<Feedback>;
      state.pendingGetFeedbacks = false;
      state.errorMessagesGetFeedbacks = {};
    });
    builder.addCase(retrieveFeedbacks.rejected, (state, action) => {
      state.pendingGetFeedbacks = false;
      state.errorMessagesGetFeedbacks = action.payload as ErrorMessages;
    });

    builder.addCase(retrieveFeedback.pending, (state, action) => {
      state.pendingGetFeedback = true;
      state.errorMessagesGetFeedback = {};
    });
    builder.addCase(retrieveFeedback.fulfilled, (state, action) => {
      state.feedback = action.payload;
      state.pendingGetFeedback = false;
      state.errorMessagesGetFeedback = {};
    });
    builder.addCase(retrieveFeedback.rejected, (state, action) => {
      state.pendingGetFeedback = false;
      state.errorMessagesGetFeedback = action.payload as ErrorMessages;
    });
  },
});

export const createFeedback = createAsyncThunk<
  Feedback,
  FormData,
  { rejectValue: ErrorMessages }
>("feedback/createFeedback", async (data, thunkApi) => {
  try {
    const response: Feedback = await feedbackService.createFeedback(data);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const retrieveFeedbacks = createAsyncThunk<
  PaginatedList<Feedback>,
  { queryParams?: QueryParams },
  { rejectValue: ErrorMessages }
>("feedback/retrieveFeedbacks", async ({ queryParams }, thunkApi) => {
  try {
    const response = await feedbackService.retrieveFeedbacks({
      queryParams,
    });
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const retrieveFeedback = createAsyncThunk<
  Feedback,
  { id: string },
  { rejectValue: ErrorMessages }
>("feedback/retrieveFeedback", async ({ id }, thunkApi) => {
  try {
    const response = await feedbackService.retrieveFeedback(id);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});
