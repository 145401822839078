import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/24/solid";
import React from "react";
import { PAGE_SIZE } from "services/apiService";

interface Props {
  count: number;
  page: number;
  previous: string | null;
  next: string | null;
  pageSize?: number;
  onClickPrev: () => void;
  onClickNext: () => void;
}

/**
 * Pagination component.
 */
export default function PaginationV2({
  count,
  page,
  previous,
  next,
  pageSize = PAGE_SIZE,
  onClickPrev,
  onClickNext,
}: Props) {
  /**
   * Get the number of the first item on the page.
   */
  const getStart = () => {
    if (count === 0) return count;
    return 1 + (page - 1) * pageSize;
  };

  /**
   * Get the number of the last item on the page.
   */
  const getEnd = () => {
    const maxPageVal = page * pageSize;
    const maxTotalVal = count;
    return maxPageVal < maxTotalVal ? maxPageVal : maxTotalVal;
  };

  return (
    <div className="flex flex-col py-4 items-center" role="navigation" aria-label="Pagination">
      <span className="text-sm text-gray-700 dark:text-gray-400" aria-live="polite">
        Showing{" "}
        <span className="font-semibold text-gray-900">{getStart()}</span> to{" "}
        <span className="font-semibold text-gray-900">{getEnd()}</span> of{" "}
        <span className="font-semibold text-gray-900">{count}</span> Entries
      </span>
      <div className="inline-flex mt-2 xs:mt-0">
        <button
          onClick={onClickPrev}
          disabled={!previous}
          className={`inline-flex items-center px-4 py-2 text-sm font-medium text-white rounded-l ${
            previous ? "bg-gray-800 hover:bg-gray-900" : "bg-gray-500"
          }`}
          aria-label="Previous page"
        >
          <ArrowLongLeftIcon className="w-5 h-5 mr-2" aria-hidden="true" />
          Prev
        </button>
        <button
          onClick={onClickNext}
          disabled={!next}
          className={`inline-flex items-center px-4 py-2 text-sm font-medium text-white rounded-r ${
            next ? "bg-gray-800 hover:bg-gray-900" : "bg-gray-500"
          }`}
          aria-label="Next page"
        >
          Next
          <ArrowLongRightIcon className="w-5 h-5 ml-2" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}
