import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  clearFeedItems,
  dislikeFeedItem,
  getFeedItem,
  getRandomFeedItems,
  likeFeedItem,
  skipFeedItem,
} from "redux/feed/feedItemsSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import PlaylistsDrawer from "./playlists/PlaylistsDrawer";
import ScrollableMobileFeedElement from "./ScrollableMobileFeedElement";
import { FeedItem } from "types/feed";
import { isDispatchResponseError } from "redux/utils";
import { initiateSpotifyMobileFeed } from "helpers/spotifyIFrameAPI";
import { handleTouchEnd, handleTouchMove, handleTouchStart } from "./handlers";
import { AddItemToPlaylistPayload, PlaylistItem } from "types/playlist";
import { addItemToPlaylists, getPlaylist } from "redux/playlist/playlistSlice";
import LikePopup from "./popups/LikePopup";
import DislikePopup from "./popups/DislikePopup";
import {
  generateFeedItemHtmlId,
  updateFeedItemZIndexes,
  SEEK_BAR_HEIGHT,
  TRANSITION_STYLE_PROPERTY,
  getComponentHeight,
} from "./utils";
import { Spinner } from "flowbite-react";
import { setCurrFeedItem } from "redux/feed/feedItemReportsSlice";
import { QueryParams } from "types/api";

interface Props {
  // Id of a feed item to be displayed first
  feedItemId?: string;
  // Id of a playlist to play feed items from
  playlistId?: string;
}

/**
 * Scrollable feed for mobile devices that supports gestures.
 */
export default function ScrollableMobileFeed({
  feedItemId,
  playlistId,
}: Props) {
  const dispatch = useAppDispatch();

  const [playing, setPlaying] = useState(false);
  const [currFeedItemIdx, setCurrFeedItemIdx] = useState(0);
  const [showPlaylistsDrawer, setShowPlaylistsDrawer] = useState(false);
  const [spotifyIFrameAPI, setSpotifyIFrameAPI] = useState<any>(null);
  const [showLikePopup, setShowLikePopup] = useState(false);
  const [showDislikePopup, setShowDislikePopup] = useState(false);
  const [feedItems, setFeedItems] = useState<FeedItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [firstFetchComplete, setFirstFetchComplete] = useState(false);

  const feedFilterTypes = useAppSelector(
    (state) => state.feedFilterTypes.feedFilterTypes,
  );
  const selectedFeedDataSet = useAppSelector(
    (state) => state.dataSets.selectedFeedDataSet,
  );
  const pendingGetRandomFeedItems = useAppSelector(
    (state) => state.feedItems.pendingGetRandomFeedItems,
  );
  const pendingGetFeedItem = useAppSelector(
    (state) => state.feedItems.pendingGetFeedItem,
  );
  const defaultPlaylist = useAppSelector((state) =>
    state.playlist.myPlaylists.find((playlist) => playlist.is_default),
  );

  const overlayRef = useRef<HTMLDivElement | null>(null);

  let currX = 0;
  let currY = 0;
  let startX = 0;
  let startY = 0;
  let prevTouchDate: Date | null = null;
  let holdMs = 0;
  let holdInterval: NodeJS.Timeout | null = null;
  let tapTimeout: NodeJS.Timeout | null = null;

  const renderedFeedItems = useMemo(
    function renderFeedItems() {
      return feedItems.map((feedItem, idx) => {
        const renderedItemHeight = getComponentHeight();
        const id = generateFeedItemHtmlId(feedItem, idx);
        return (
          <ScrollableMobileFeedElement
            key={id}
            id={id}
            className="absolute w-full aspect-video rounded bg-gray-100"
            style={{
              ...TRANSITION_STYLE_PROPERTY,
              height: renderedItemHeight + "px",
              top: renderedItemHeight * idx,
            }}
            data={{
              playing: idx === currFeedItemIdx ? playing : false,
              feedItem,
            }}
            spotifyIFrameAPI={spotifyIFrameAPI}
          />
        );
      });
    },
    [feedItems, currFeedItemIdx, playing],
  );

  /**
   * Retrieve feed items from the backend.
   */
  async function retrieveFeedItems() {
    if (feedItemId) {
      return dispatch(getFeedItem({ id: feedItemId }));
    } else {
      const queryParams: QueryParams = {};
      if (feedFilterTypes) {
        queryParams["types"] = feedFilterTypes.join(",");
      }
      if (selectedFeedDataSet) {
        queryParams["dataset"] = selectedFeedDataSet.id;
      }
      return dispatch(
        getRandomFeedItems({
          queryParams,
        }),
      );
    }
  }

  /**
   * Fetch feed item or items from the backend.
   */
  async function fetchFeedItems() {
    const response = await retrieveFeedItems();
    if (!isDispatchResponseError(response)) {
      const payload = response.payload;
      if (Array.isArray(payload)) {
        setFeedItems(payload as FeedItem[]);
      } else {
        setFeedItems([payload as FeedItem]);
      }
    }
    setFirstFetchComplete(true);
  }

  /**
   * Fetch next set of feed items from the backend.
   */
  async function fetchAdditionalFeedItems() {
    const response = await retrieveFeedItems();
    if (!isDispatchResponseError(response)) {
      setFeedItems([...feedItems, ...(response.payload as FeedItem[])]);
    }
  }

  /**
   * Fetch feed items from a playlist.
   */
  async function fetchPlaylistFeedItems() {
    if (playlistId) {
      const response = await dispatch(getPlaylist({ id: playlistId }));
      if (!isDispatchResponseError(response) && response.payload) {
        const newFeedItems: Array<FeedItem> = [];

        (response.payload.items as PlaylistItem[]).forEach((item) => {
          // Extract feed items
          newFeedItems.push(item.feed_item);
        });

        setFeedItems(newFeedItems);
      }
    }
    setFirstFetchComplete(true);
  }

  /**
   * Mark feed item as liked in the database.
   */
  const handleLikeFeedItem = async () => {
    // Mark item as liked in database
    if (feedItems) {
      const response = await dispatch(
        likeFeedItem(feedItems[currFeedItemIdx].id || ""),
      );
      if (!isDispatchResponseError(response)) {
        return true;
      }
    }
    return false;
  };

  /**
   * Mark feed item as disliked in the database.
   */
  const handleDislikeFeedItem = async () => {
    // Mark item as disliked in database
    if (feedItems) {
      const response = await dispatch(
        dislikeFeedItem(feedItems[currFeedItemIdx].id || ""),
      );
      if (!isDispatchResponseError(response)) {
        return true;
      }
    }
    return false;
  };

  /**
   * Add feed item to default playlist.
   */
  async function handleAddToDefaultPlaylist() {
    // Add item to default playlist
    if (defaultPlaylist && feedItems) {
      // Prepare payload
      const data: AddItemToPlaylistPayload = {
        playlist_ids: [defaultPlaylist.id],
        feed_item_id: feedItems[currFeedItemIdx].id,
      };
      const response = await dispatch(addItemToPlaylists(data));
      if (!isDispatchResponseError(response)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Mark feed item as skipped in the database.
   */
  async function handleSkipFeedItem() {
    // Mark item as skipped in the database
    if (feedItems) {
      const response = await dispatch(
        skipFeedItem(feedItems[currFeedItemIdx].id),
      );
      if (!isDispatchResponseError(response)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Fetch feed items whenever the component mounts and append Spotify setup script.
   */
  useEffect(() => {
    // Append Spotify script used by SpotifyTrack component
    initiateSpotifyMobileFeed(setSpotifyIFrameAPI);
    const script = document.createElement("script");
    script.src = "https://open.spotify.com/embed/iframe-api/v1";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  /**
   * Fetch feed items whenever feedFilterTypes and selectedFeedDataSet change.
   */
  useEffect(() => {
    // Reload feed items if feedFilterTypes changes
    setCurrFeedItemIdx(0);
    setPlaying(false);
    dispatch(clearFeedItems());

    // Fetch feed items
    if (playlistId) {
      fetchPlaylistFeedItems();
    } else {
      fetchFeedItems();
    }
  }, [feedFilterTypes, selectedFeedDataSet]);

  /**
   * Stop feed items from playing whenever currFeedItemIdx changes.
   */
  useEffect(() => {
    setPlaying(false);
  }, [currFeedItemIdx]);

  /**
   * Set up gesture handlers whenever feed items change.
   */
  useEffect(() => {
    // Avoid infinite fetching of feed items if there are no items available
    if (feedItems.length === 0) return;

    // Fetch another set of feed items if user gets close to the end of the array
    if (currFeedItemIdx >= feedItems.length - 1 && !playlistId && !feedItemId) {
      fetchAdditionalFeedItems();
    }

    // Update every feed item element's z-index
    updateFeedItemZIndexes({ feedItemIdx: currFeedItemIdx, feedItems });

    // Set up gesture handlers
    function onTouchStart(e: TouchEvent) {
      const result = handleTouchStart({
        e,
        holdMs,
        startY,
        startX,
        currY,
        currX,
        playing,
        setShowPlaylistsDrawer,
        setPlaying,
        feedItems,
      });
      holdMs = result.holdMs;
      holdInterval = result.holdInterval;
      startX = result.startX;
      startY = result.startY;
      currX = result.currX;
      currY = result.currY;
      tapTimeout = result.tapTimeout;
    }

    function onTouchMove(e: TouchEvent) {
      const result = handleTouchMove({
        e,
        currX,
        currY,
        startX,
        startY,
        feedItems,
        currFeedItemIdx,
        tapTimeout,
      });
      currX = result.currX;
      currY = result.currY;
    }

    function onTouchEnd(e: TouchEvent) {
      const result = handleTouchEnd({
        startY,
        startX,
        currX,
        currY,
        holdInterval,
        tapTimeout,
        prevTouchDate,
        currFeedItemIdx,
        feedItems,
        setCurrFeedItemIdx,
        setShowLikePopup,
        setShowDislikePopup,
        handleLikeFeedItem,
        handleDislikeFeedItem,
        handleAddToDefaultPlaylist,
        handleSkipFeedItem,
        setPlaying,
      });
      prevTouchDate = result.prevTouchDate;
    }

    if (overlayRef.current) {
      overlayRef.current.addEventListener("touchmove", onTouchMove);
      overlayRef.current.addEventListener("touchstart", onTouchStart);
      overlayRef.current.addEventListener("touchend", onTouchEnd);
    }

    return () => {
      if (overlayRef.current) {
        overlayRef.current.removeEventListener("touchmove", onTouchMove);
        overlayRef.current.removeEventListener("touchstart", onTouchStart);
        overlayRef.current.removeEventListener("touchend", onTouchEnd);
      }
    };
  }, [feedItems, currFeedItemIdx, playing]);

  /**
   * Set loading state whenever feed items are fetched.
   */
  useEffect(() => {
    const isEmpty = firstFetchComplete && feedItems.length === 0;
    const newLoading =
    (pendingGetRandomFeedItems || pendingGetFeedItem) && !isEmpty;
    if (newLoading) {
      // Immediately set loading to true in order to display loading indicator
      setLoading(newLoading);
    } else {
      // Add a delay to setting loading to false.
      // It's done in order to hide loading states of embeds.
      setTimeout(() => {
        setLoading(newLoading);
      }, 2500);
    }
  }, [pendingGetRandomFeedItems, pendingGetFeedItem, feedItems, firstFetchComplete]);

  /**
   * Update feedItemReportsSlice -> currFeedItem whenever currFeedItemIdx changes
   * so that ReportFeedItemModal can display the correct feed item.
   */
  useEffect(() => {
    if (currFeedItemIdx < feedItems.length) {
      dispatch(setCurrFeedItem(feedItems[currFeedItemIdx]));
    } else {
      dispatch(setCurrFeedItem(null));
    }
  }, [feedItems, currFeedItemIdx]);

  return (
    <div className="relative h-full overflow-hidden">
      {feedItems instanceof Array && feedItems.length === 0 ? (
        <div
          className={
            "flex justify-center items-center text-lg bg-neutral-800 text-slate-100 h-full text-center"
          }
        >
          {loading ? (
            <div className="bg-gray-100 justify-center items-center flex w-full h-full">
              <Spinner size="xl" />
            </div>
          ) : (
            "There isn't anything available for you to watch"
          )}
        </div>
      ) : (
        <>
          {/* Overlay
            Iframes themselves cannot be draggable since we cannot capture touch/click events on them, hence the overlay
          */}
          <div
            className={"absolute left-0 top-0 right-0 bottom-0 z-40 flex"}
            ref={overlayRef}
            draggable
            style={{
              ...TRANSITION_STYLE_PROPERTY,
              height: loading
                ? getComponentHeight()
                : getComponentHeight() - SEEK_BAR_HEIGHT,
            }}
          >
            {loading && (
              <div className="bg-gray-100 justify-center items-center flex w-full h-full">
                <Spinner size="xl" />
              </div>
            )}
          </div>

          <LikePopup showLikePopup={showLikePopup} />
          <DislikePopup showDislikePopup={showDislikePopup} />

          {renderedFeedItems}

          {feedItems && showPlaylistsDrawer && (
            <PlaylistsDrawer
              currItemId={feedItems[currFeedItemIdx].id}
              show={showPlaylistsDrawer}
              incrementCurrFeedItemIdx={() =>
                setCurrFeedItemIdx(currFeedItemIdx + 1)
              }
              setShow={setShowPlaylistsDrawer}
            />
          )}
        </>
      )}
    </div>
  );
}
