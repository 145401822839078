import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import { useAppSelector } from "redux/hooks";
import SelectDataSetsTable from "components/dataSets/SelectDataSetsTable";
import Header from "components/common/header";
import { APP_URLS } from "navigation";

/**
 * Dataset list page.
 */
export default function DataSets() {
  const user = useAppSelector((state) => state.user.user);

  useEffect(() => {
    document.title = "Datasets | BrainCargo";
  }, []);

  return (
    <PageWrapper>
      <div className="border shadow-md lg:rounded-lg bg-white pb-6 space-y-2" aria-label="Datasets Page">
        <Header title="Datasets" />
        <div className="px-6 space-y-2">
          <p>
            Below you will find a list of all datasets uploaded to the
            BrainCargo database. You can upload new datasets and select existing
            ones in order to use them in other parts of the application.
          </p>
          <SelectDataSetsTable headerText="" />
        </div>

        {user && (
          <>
            <Header title="Feed Data" />
            <div className="px-6">
              <p>
                Click the following link to see all data we have collected
                related to your watch history:{" "}
                <a
                  href={APP_URLS.FEED_DATA_USER_DETAILS.replace(
                    ":userId",
                    user.id,
                  )}
                  className="text-blue-600 font-medium underline cursor-pointer"
                  aria-label="Feed Data Link"
                >
                  Feed Data
                </a>
              </p>
            </div>
          </>
        )}
      </div>
    </PageWrapper>
  );
}
