import Modal from "components/common/modal";
import SelectFeedDataSetTable from "components/dataSets/SelectFeedDataSetTable";
import { Button } from "flowbite-react";
import React, { useState } from "react";
import { useAppSelector } from "redux/hooks";

/**
 * Component that renders feed dataset selection menu in MobileBottomNavBarScrollableFeed.
 */
export default function MobileNavBarDataSetSelection() {
  const selectedDataSet = useAppSelector(
    (state) => state.dataSets.selectedFeedDataSet,
  );
  const [showSelectDataSetModal, setShowSelectDataSetModal] = useState(false);

  return (
    <div className="flex flex-col justify-center col-span-5 py-2 gap-1">
      <div className="text-base text-center">
        Select which dataset should be used <br />
        to provide content:
      </div>
      <div className="flex flex-row justify-center items-center flex-wrap gap-4 px-4">
        <button
          onClick={() => setShowSelectDataSetModal(true)}
          className={
            "whitespace-nowrap capitalize px-4 py-2 text-center rounded-lg  text-white bg-blue-600 overflow-hidden text-ellipsis"
          }
          aria-label="Select dataset"
        >
          {selectedDataSet?.name || "None"}
        </button>
      </div>
      <Modal
        show={showSelectDataSetModal}
        onClose={() => setShowSelectDataSetModal(false)}
        customFooter={
          <Button
            className="px-10 m-auto"
            onClick={() => setShowSelectDataSetModal(false)}
            color="gray"
            aria-label="Cancel"
          >
            Cancel
          </Button>
        }
        body={
          <SelectFeedDataSetTable
            afterDataSetSelect={() => setShowSelectDataSetModal(false)}
          />
        }
      />
    </div>
  );
}
