import { FeedItem, StreamingProvider } from "types/feed";
import React from "react";
import {
  getLogoForStreamingProvider,
  transformStreamingServiceName,
} from "helpers/feedItem";
import { PlayCircleIcon } from "@heroicons/react/24/outline";

interface Props {
  feedItem: FeedItem | null;
}

/**
 * Watch section for TMDBMovie component.
 */
export default function TMDBMovieWatch({ feedItem }: Props) {
  if (!feedItem?.tmdb_data?.videos || feedItem?.tmdb_data?.videos?.length === 0)
    return null;

  const streamingProviders = feedItem.streaming_providers;

  function onClickWatch(e: React.MouseEvent, provider: StreamingProvider) {
    e.stopPropagation();
    window.open(provider.link);
  }

  return (
    <div className="flex flex-col space-y-2 text-left">
      {streamingProviders.map((provider) => {
        return (
          <div
            key={provider.id}
            className="flex flex-row justify-center items-center text-sm gap-4 bg-neutral-200 p-2 rounded-lg"
            role="button"
            tabIndex={0}
            aria-label={`Watch on ${transformStreamingServiceName(provider.service)}`}
          >
            <div className="bg-neutral-100 rounded-full p-2">
              <img
                src={getLogoForStreamingProvider(provider)}
                className="h-6 w-6"
                alt={`${transformStreamingServiceName(provider.service)} logo`}
              />
            </div>
            <div className="capitalize">
              <div className="text-base">
                {transformStreamingServiceName(provider.service)}
              </div>
              <div className="text-xs text-neutral-400">
                {provider.streaming_type}
              </div>
            </div>
            <div className="ml-auto">
              <button
                className="border-[1px] border-yellow-500 py-1 px-2 rounded-2xl bg-neutral-100"
                onClick={(e) => onClickWatch(e, provider)}
                aria-label={`Watch on ${transformStreamingServiceName(provider.service)}`}
              >
                <div className="flex justify-center items-center gap-1">
                  <PlayCircleIcon className="h-6 w-6 text-yellow-500" />
                  <div>Watch</div>
                </div>
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}
