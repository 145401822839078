import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import Header from "components/common/header";
import { CreateFeedbackForm } from "components/feedback/CreateFeedbackForm";

/**
 * Create feedback page.
 */
export default function CreateFeedback() {
  useEffect(() => {
    document.title = "Feedback | BrainCargo";
  }, []);

  return (
    <PageWrapper>
      <div className="border shadow-md lg:rounded-lg bg-white pb-6 space-y-2" aria-label="Create Feedback Page">
        <Header title="Feedback" />
        <div className="py-2 px-4">
          <CreateFeedbackForm />
        </div>
      </div>
    </PageWrapper>
  );
}
