import { Checkbox, Label, Select, TextInput } from "flowbite-react";
import { displayErrors } from "helpers/errors";
import React from "react";
import { useAppSelector } from "redux/hooks";
import { QuizFormData } from "types/quiz";
import { ErrorMessages } from "types/redux/slice";

interface Props {
  data: QuizFormData;
  onChange: (data: QuizFormData) => void;
  errors?: ErrorMessages;
}

/**
 * Quiz input fields.
 */
export default function QuizInput({ data, onChange, errors }: Props) {
  const neuralNetworks = useAppSelector(
    (store) => store.neuralNetworks.neuralNetworks.results,
  );

  /**
   * Handle input change.
   */
  function handleOnChange(
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ) {
    let value: boolean | string | null;
    if (e.target.type === "checkbox") {
      value = (e as React.ChangeEvent<HTMLInputElement>).target.checked;
    } else {
      value = e.target.value === "-" ? null : e.target.value;
    }
    const key = e.target.name as keyof QuizFormData;
    const newData = { ...data, [key]: value };
    onChange(newData);
  }

  return (
    <div className="flex flex-col gap-2">
      <Label value="Name of the quiz" />
      {displayErrors(errors?.name)}
      <TextInput
        type="text"
        value={data.name}
        name="name"
        onChange={handleOnChange}
        placeholder="John's Quiz"
        aria-label="Name of the quiz"
      />

      <Label value="Neural network" />
      {displayErrors(errors?.type as string[])}
      <Select
        value={data.neural_network || undefined}
        name="neural_network"
        onChange={handleOnChange}
        aria-label="Neural network"
      >
        <option>-</option>
        {neuralNetworks.map((neuralNetwork) => (
          <option key={neuralNetwork.id} value={neuralNetwork.id}>
            {neuralNetwork.name}
          </option>
        ))}
      </Select>

      <div className="flex items-center gap-2">
        <Checkbox
          checked={data.is_hidden}
          onChange={handleOnChange}
          name="is_hidden"
          aria-label="Hide this quiz"
        />
        <Label value="Would you like to hide this quiz?" />
      </div>
    </div>
  );
}
