import { Button, Spinner } from "flowbite-react";
import React, { ReactElement } from "react";
import NoImage from "images/No-Image.png";
import { FEED_ITEM_TYPES, FeedItemDataset } from "types/feed";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useAppSelector } from "redux/hooks";
import { generateYoutubeUrl } from "helpers/youtube";
import { generateSpotifyUrl } from "helpers/spotify";

interface Props {
  feedItemDataset: FeedItemDataset | null;
  pending: boolean;
  onHideFeedItem: () => void;
}

/**
 * Displays information about a feed item.
 */
export default function FeedItemDatasetInfo({
  feedItemDataset,
  pending,
  onHideFeedItem,
}: Props) {
  const pendingUpdateFeedItem = useAppSelector(
    (state) => state.feedItems.pendingUpdateFeedItem,
  );
  const user = useAppSelector((state) => state.user.user);

  function renderRow(label: string, value?: string | number | boolean) {
    let renderedValue: undefined | string | number | boolean | ReactElement =
      value;
    if (typeof value === "boolean") {
      renderedValue = value ? (
        <CheckCircleIcon className="w-8 text-green-500" aria-label="Yes" />
      ) : (
        <XCircleIcon className="w-8 text-red-500" aria-label="No" />
      );
    } else if (!value && value !== 0) {
      renderedValue = "-";
    } else if (
      typeof value === "string" &&
      (value.includes("http://") || value.includes("https://"))
    ) {
      renderedValue = (
        <a
          href={value}
          className="text-blue-600 font-medium underline cursor-pointer"
          target="_blank"
          rel="noreferrer"
          aria-label={`Link to ${value}`}
        >
          {value}
        </a>
      );
    }
    return (
      <div className="grid grid-cols-2 border-b-2 items-center">
        <div className="text-gray-500 text-base py-2 font-semibold mr-8">
          {label}
        </div>
        <div className="text-gray-500 text-base py-2 break-all">
          {renderedValue}
        </div>
      </div>
    );
  }

  function getItemUrl(feedItemDataset: FeedItemDataset) {
    if (feedItemDataset.type === FEED_ITEM_TYPES.VIDEO) {
      return feedItemDataset.youtube_video_id
        ? generateYoutubeUrl(feedItemDataset.youtube_video_id)
        : "-";
    } else if (feedItemDataset.type === FEED_ITEM_TYPES.NEWS) {
      return feedItemDataset.bing_news_url
        ? feedItemDataset.bing_news_url
        : "-";
    } else if (feedItemDataset.type === FEED_ITEM_TYPES.TRACK) {
      return feedItemDataset.spotify_track_id
        ? generateSpotifyUrl(feedItemDataset.spotify_track_id)
        : "-";
    }
    return "-";
  }

  if (pending) {
    return (
      <div className="flex justify-center" role="status" aria-live="polite">
        <Spinner aria-label="Loading" />
      </div>
    );
  }

  if (!feedItemDataset) {
    return (
      <div className="flex justify-center" role="alert" aria-live="assertive">
        Failed to retrieve information about this feed item
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      {pending ? (
        <Spinner aria-label="Loading" />
      ) : (
        <div className="w-full lg:w-1/2" role="region" aria-live="polite">
          <div className="flex flex-col mb-2 justify-center items-center">
            <img
              className="h-32 box-content text-gray-600 bg-gray-200 rounded"
              src={feedItemDataset.image ? feedItemDataset.image : NoImage}
              alt="Feed item"
            />
          </div>
          {renderRow("ID:", feedItemDataset.id)}
          {renderRow("Title:", feedItemDataset.title)}
          {renderRow("Link:", getItemUrl(feedItemDataset))}
          {renderRow("Likes:", feedItemDataset.liked_by_count)}
          {renderRow("Dislikes:", feedItemDataset.disliked_by_count)}
          {renderRow("Watches:", feedItemDataset.watched_by_count)}
          {renderRow("Skips:", feedItemDataset.skipped_by_count)}
          {user?.is_superuser && (
            <>
              {renderRow("Is Hidden:", feedItemDataset.hidden)}
              <Button
                className="w-full mt-2"
                color="info"
                onClick={onHideFeedItem}
                aria-label={feedItemDataset.hidden ? "Show Feed Item" : "Hide Feed Item"}
              >
                {pendingUpdateFeedItem && <Spinner aria-label="Loading" />}
                {!pendingUpdateFeedItem && feedItemDataset.hidden
                  ? "Show Feed Item"
                  : "Hide Feed Item"}
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
}
