import { FeedItem } from "types/feed";
import TMDBMovieCastItem from "../common/TMDBMovieCastItem";
import React from "react";

interface Props {
  feedItem: FeedItem | null;
}

/**
 * Cast section for TMDBMovie component.
 */
export default function TMDBMovieCast({ feedItem }: Props) {
  if (!feedItem?.tmdb_data?.cast || feedItem?.tmdb_data?.cast?.length === 0)
    return null;

  return (
    <div className="grid grid-cols-2 gap-2">
      {feedItem.tmdb_data.cast.map((cast) => (
        <TMDBMovieCastItem key={cast.id} cast={cast} fullWidth />
      ))}
    </div>
  );
}
