import React, {useState} from "react";
import {useAppDispatch} from "redux/hooks";
// import { resetPassword } from "redux/auth/authSlice";
import {toast} from "react-toastify";
import TextInput from "../../components/common/form/TextInput";
import CoinImage from "images/Landing/Landing-Coin.png";
import {useParams} from "react-router-dom";
import {APP_URLS} from "../../navigation";
import {history} from "helpers/history";
import {resetEmailPassword} from "../../redux/auth/authSlice";

const PRIMARY_TEXT_COLOR_CLASS = "text-stone-200";

/**
 * Reset Password page.
 */

export default function ResetPassword() {
  const dispatch = useAppDispatch();

  const {uid , token} = useParams();



  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  /**
   * Handle password reset submission.
   */
  const handlePasswordReset = async () => {
    if (password1 !== password2) {
      toast.error("Passwords do not match.");
      return;
    }

    try {
      if(uid && token){
        const payload = {uid, token, new_password1: password1, new_password2: password2};
        toast.promise(
          dispatch(resetEmailPassword(payload)).unwrap(),
          {
            pending: "Resetting your password...",
            success: {
              render() {
                history.navigate("/login");
                return "Password has been successfully reset!";
              },
            },
            error: "An error occurred while resetting your password. Please try again.",
          }
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handlePasswordReset();
  };

  return (
    <div className="relative flex flex-col justify-center p-4 md:p-0 min-h-screen overflow-hidden bg-[#02041a]">
      <div
        className="w-full p-4 sm:p-10 m-auto bg-[#02041a] drop-shadow-xl border border-[#fbfbff] rounded-xl lg:max-w-xl"
        aria-label="Reset Password Form"
      >
        <div className="flex flex-col items-center">
          <img
            src={CoinImage} // Use the imported logo image
            alt="Site Logo"
            className="w-24 h-auto mb-4" // Adjust width and margin as needed
          />
          <h1
            className={`text-xl text-center sm:text-4xl ${PRIMARY_TEXT_COLOR_CLASS} font-extrabold sm:w-2/3`}
          >
            Reset Password
          </h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mt-6">
            <TextInput
              label="New Password"
              id="password1"
              type="password"
              required
              onChange={(event) => setPassword1(event.target.value)}
              placeholder="***********"
              value={password1}
              aria-label="New Password"
            />
            <TextInput
              label="Confirm Password"
              id="password2"
              type="password"
              required
              onChange={(event) => setPassword2(event.target.value)}
              placeholder="***********"
              value={password2}
              aria-label="Confirm Password"
            />
            <div className="mt-6 flex justify-center">
              <button
                type="submit"
                className="w-full px-4 py-3 tracking-wide text-white transition-colors border border-transparent duration-200 transform bg-[linear-gradient(95.49deg,#5d3ef8_-12.23%,#ba4cd6_117.24%)] rounded-full hover:bg-transparent hover:border-[#cec0f3] focus:outline-none focus:bg-blue-600"
                aria-label="Reset Password"
              >
                Reset Password
              </button>
            </div>
          </div>
        </form>
        <div id="status-message" aria-live="polite"></div>
      </div>
    </div>
  );
}
