import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Label,
  RangeSlider,
  Spinner,
  TextInput,
} from "flowbite-react";
import Accordion from "components/common/accordion";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  retrieveAdvertisers,
  retrieveConnectedAdvertisers,
  setConnectedAdvertisers,
} from "redux/brainCoin/brainCoinSlice";
import { Advertiser, ConnectedAdvertiser } from "types/brainCoin";
import { displayErrors } from "helpers/errors";

/**
 * Component for displaying a collapsible connected advertisers accordion.
 */
export default function ConnectedAdvertisersAccordion() {
  const dispatch = useAppDispatch();
  const [accordionItems, setAccordionItems] = useState<
    Array<{ header: JSX.Element; body: JSX.Element }>
  >([]);
  const [accordionOpenArray, setAccordionOpenArray] = useState<boolean[]>([]);
  const [editedConnectedAdvertisers, setEditedConnectedAdvertisers] = useState<
    ConnectedAdvertiser[]
  >([]);
  const advertisers = useAppSelector((state) => state.brainCoin.advertisers);
  const connectedAdvertisers = useAppSelector(
    (state) => state.brainCoin.connectedAdvertisers,
  );
  const pendingRetrieveAdvertisers = useAppSelector(
    (state) => state.brainCoin.pendingRetrieveAdvertisers,
  );
  const pendingRetrieveConnectedAdvertisers = useAppSelector(
    (state) => state.brainCoin.pendingRetrieveConnectedAdvertisers,
  );
  const pendingSetConnectedAdvertisers = useAppSelector(
    (state) => state.brainCoin.pendingSetConnectedAdvertisers,
  );
  const retrieveAdvertiserErrorMessages = useAppSelector(
    (state) => state.brainCoin.retrieveAdvertiserErrorMessages,
  );
  const retrieveConnectedAdvertiserErrorMessages = useAppSelector(
    (state) => state.brainCoin.retrieveConnectedAdvertiserErrorMessages,
  );
  const setConnectedAdvertiserErrorMessages = useAppSelector(
    (state) => state.brainCoin.setConnectedAdvertiserErrorMessages,
  );

  /**
   * Generate accordion items based on advertisers and editedConnectedAdvertisers.
   */
  function generateAccordionItems() {
    const items = advertisers.map((advertiser, idx) => {
      const editedConnectedAdvertiser = editedConnectedAdvertisers.find(
        (editedConnectedAdvertiser) =>
          editedConnectedAdvertiser.advertiser === advertiser.id,
      );
      const adPercentage =
        editedConnectedAdvertiser?.ad_percentage === undefined
          ? "0%"
          : editedConnectedAdvertiser?.ad_percentage.toString();

      function setAdPercentage(value: string) {
        const newEditedConnectedAdvertisers = [...editedConnectedAdvertisers];
        const editedConnectedAdvertiserIndex =
          newEditedConnectedAdvertisers.findIndex(
            (item) => item.advertiser === editedConnectedAdvertiser?.advertiser,
          );

        newEditedConnectedAdvertisers[
          editedConnectedAdvertiserIndex
        ].ad_percentage = value;
        setEditedConnectedAdvertisers([...newEditedConnectedAdvertisers]);
      }

      function handleAdPercentageChange(
        e: React.ChangeEvent<HTMLInputElement>,
        addPercentSymbol = false,
      ) {
        let value: string = e.target.value;

        // Make sure the value is in 0-100
        let valueNumber = parseFloat(value);
        if (valueNumber < 0) valueNumber = 0;
        if (valueNumber > 100) valueNumber = 100;

        // Convert valueNumber to string in order to filter out undesired symbols
        if (isNaN(valueNumber)) {
          value = "0";
        } else if (addPercentSymbol) {
          value = valueNumber.toString() + "%";
        } else {
          value = valueNumber.toString();
        }
        setAdPercentage(value);
      }

      function addPercentSymbolToAdPercentage() {
        setAdPercentage(adPercentage.replaceAll("%", "") + "%");
      }

      function removePercentSymbolFromAdPercentage() {
        setAdPercentage(adPercentage.replaceAll("%", ""));
      }

      return {
        header: (
          <div>
            <Checkbox
              checked={accordionOpenArray[idx]}
              onChange={() => handleAccordionClick(idx)}
              className="mr-4 hover:cursor-pointer focus:ring-opacity-0"
              aria-label={`Toggle ${advertiser.name}`}
            />
            {advertiser.name} - {advertiser.brain_coin_amount} coins
          </div>
        ),
        body: (
          <div className="space-y-2">
            <div>
              <Label value="Percentage of advertisements allowed on the feed" />{" "}
            </div>
            <TextInput
              placeholder="100%"
              value={adPercentage}
              onChange={handleAdPercentageChange}
              onFocus={removePercentSymbolFromAdPercentage}
              onBlur={addPercentSymbolToAdPercentage}
              aria-label={`Ad percentage for ${advertiser.name}`}
            />
            <div>
              <RangeSlider
                min={0}
                max={100}
                step={1}
                value={parseFloat(adPercentage)}
                onChange={(e) => {
                  handleAdPercentageChange(e, true);
                }}
                aria-label={`Ad percentage slider for ${advertiser.name}`}
              />
              <div className="flex flex-row justify-between">
                <span>0%</span>
                <span>100%</span>
              </div>
            </div>
          </div>
        ),
      };
    });
    setAccordionItems(items);
    return items;
  }

  /**
   * Generate accordionOpenArray based on advertisers and connectedAdvertisers.
   */
  function generateAccordionOpenArray(
    advertisers: Advertiser[],
    connectedAdvertisers: ConnectedAdvertiser[],
  ) {
    const connectedAdvertiserIds = connectedAdvertisers.map(
      (item) => item.advertiser,
    );
    setAccordionOpenArray(
      advertisers.map((item) => connectedAdvertiserIds.includes(item.id)),
    );
  }

  /**
   * Open/close accordion item.
   */
  function handleAccordionClick(idx: number) {
    const newAccordionOpenArray = [...accordionOpenArray];
    newAccordionOpenArray[idx] = !newAccordionOpenArray[idx];
    setAccordionOpenArray(newAccordionOpenArray);
  }

  /**
   * Translate editedConnectedAdvertisers to payload and make a request to set connected advertisers.
   */
  function handleSubmit() {
    const data: ConnectedAdvertiser[] = [];

    // Prepare payload based on checked accordion items
    accordionOpenArray.forEach((item, idx) => {
      const adPercentage =
        parseFloat(String(editedConnectedAdvertisers[idx].ad_percentage)) *
        0.01;
      if (item === true && adPercentage !== 0) {
        data.push({
          ...editedConnectedAdvertisers[idx],
          // ad_percentage needs to be set to percentage representation(ex. 50% -> 0.5)
          ad_percentage: adPercentage,
        });
      }
    });
    dispatch(setConnectedAdvertisers(data));
  }

  /**
   * Render errors from redux state.
   */
  function renderErrors() {
    if (
      retrieveAdvertiserErrorMessages ||
      setConnectedAdvertiserErrorMessages ||
      retrieveConnectedAdvertiserErrorMessages
    ) {
      const errors: Array<string[]> = [];
      Object.keys(retrieveAdvertiserErrorMessages).forEach((key) => {
        errors.push(retrieveAdvertiserErrorMessages[key]);
      });
      Object.keys(setConnectedAdvertiserErrorMessages).forEach((key) => {
        errors.push(setConnectedAdvertiserErrorMessages[key]);
      });
      Object.keys(retrieveConnectedAdvertiserErrorMessages).forEach((key) => {
        errors.push(retrieveConnectedAdvertiserErrorMessages[key]);
      });
      return errors.map((error) => displayErrors(error));
    }
  }

  useEffect(() => {
    // Generate accordion items on mount and whenever accordionOpenArray or editedConnectedAdvertisers change
    generateAccordionItems();
  }, [accordionOpenArray, editedConnectedAdvertisers]);

  useEffect(() => {
    // Generate editedConnectedAdvertisers based on advertisers and connectedAdvertisers
    const newEditedConnectedAdvertisers = advertisers.map((advertiser) => {
      let connectedAdvertiser = connectedAdvertisers.find(
        (item) => item.advertiser === advertiser.id,
      );
      let adPercentage = connectedAdvertiser?.ad_percentage;

      // Value returned from backend is represented as float(50% -> 0.5) - it needs to be adjusted
      if (adPercentage === undefined) {
        adPercentage = "0%";
      } else {
        adPercentage = String(Math.round((adPercentage as number) * 100)) + "%";
      }

      connectedAdvertiser = {
        advertiser: advertiser.id,
        brain_coin_amount: advertiser.brain_coin_amount,
        ad_percentage: adPercentage,
        ...(!!connectedAdvertiser && { id: connectedAdvertiser.id }),
      };
      return connectedAdvertiser;
    });

    setEditedConnectedAdvertisers([...newEditedConnectedAdvertisers]);
    generateAccordionOpenArray(advertisers, connectedAdvertisers);
  }, [advertisers, connectedAdvertisers]);

  useEffect(() => {
    // Retrieve advertisers and connected advertisers on mount
    dispatch(retrieveAdvertisers());
    dispatch(retrieveConnectedAdvertisers());
  }, []);

  return pendingRetrieveAdvertisers || pendingRetrieveConnectedAdvertisers ? (
    <div className="flex justify-center items-center">
      <Spinner />
    </div>
  ) : (
    <div className="space-y-1">
      <Accordion
        items={accordionItems}
        openArray={accordionOpenArray}
        handleClick={handleAccordionClick}
      />

      {renderErrors()}

      <Button className="w-full" onClick={handleSubmit} aria-label="Submit">
        {pendingSetConnectedAdvertisers ? <Spinner size="sm" /> : "Submit"}
      </Button>
    </div>
  );
}
