import React from "react";
import { TrashIcon } from "@heroicons/react/24/solid";

interface Props {
  onDelete: () => void;
}

/**
 * A trash icon button.
 */
export default function DeleteButton({ onDelete }: Props) {
  return (
    <button
      className="ml-auto"
      aria-label="Delete"
      onClick={onDelete}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onDelete();
        }
      }}
    >
      <TrashIcon className="w-5 h-5 text-red-700" />
    </button>
  );
}
