import React, { SyntheticEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { displayErrors } from "helpers/errors";
import {
  addItemToPlaylists,
  getMyPlaylists,
} from "redux/playlist/playlistSlice";
import { isDispatchResponseError } from "redux/utils";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import Modal from "../common/modal";
import { AddItemToPlaylistPayload, Playlist } from "types/playlist";
import { FeedItem } from "types/feed";
import { getRandomFeedItem } from "redux/feed/feedItemsSlice";

interface Props {
  feedItem: FeedItem;
  show: boolean;
  onClose: () => void;
  headerText?: string;
}

/**
 * A popup to select playlists to add a feed item to.
 */
export default function SelectPlaylistPopup({
  feedItem,
  show,
  onClose,
  headerText = "Add video to playlist",
}: Props) {
  const dispatch = useAppDispatch();
  const playlists = useAppSelector((state) => state.playlist.myPlaylists);
  const getMyPlaylistsErrors = useAppSelector(
    (state) => state.playlist.getMyPlaylistsErrors,
  );
  const [selectedPlaylists, setSelectedPlaylists] = useState<Array<string>>([]);

  /**
   * Add or remove the given playlist from the selected playlists list.
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedPlaylistId = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked && !selectedPlaylists.includes(selectedPlaylistId)) {
      // If playlist item is checked and doesn't exist in selectedPlaylists list
      setSelectedPlaylists([...selectedPlaylists, selectedPlaylistId]);
    } else if (!isChecked && selectedPlaylists.includes(selectedPlaylistId)) {
      // If playlist item is unchecked and exists in selectedPlaylists list
      const newSelectedPlaylists = selectedPlaylists.filter(
        (selectedPlaylist) => selectedPlaylist !== selectedPlaylistId,
      );
      setSelectedPlaylists(newSelectedPlaylists);
    }
  };

  /**
   * Add the given feed item to playlists.
   */
  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    const data: AddItemToPlaylistPayload = {
      playlist_ids: selectedPlaylists,
      feed_item_id: feedItem.id,
    };
    const response = await dispatch(addItemToPlaylists(data));

    if (!isDispatchResponseError(response)) {
      // Close the popup and proceed to next video
      onClose();
      // Retrieve a random video / track from Django API
      dispatch(getRandomFeedItem({ queryParams: { types: feedItem.type } }));
    }
  };

  /**
   * Get the number of items in a playlist.
   */
  function getPlaylistLength(playlist: Playlist) {
    const length = playlist.items.length;
    return length;
  }

  /**
   * Display playlists in a table.
   */
  const displayPlaylists = () => {
    return playlists.map((playlist, idx) => (
      <tr key={idx} className="border-b border-gray-200">
        <td className="flex items-center px-6 py-4 bg-gray-50">
          <input
            id={playlist.id}
            name="video_playlists"
            type="checkbox"
            value={playlist.id}
            defaultChecked={feedItem?.playlists?.includes(playlist.id || "")}
            onChange={(e) => handleChange(e)}
            className="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-0"
            aria-label={`Select playlist ${playlist.name}`}
          />
          <label htmlFor={playlist.id}>{playlist.name}</label>
        </td>
        <td className="px-6 py-4">{getPlaylistLength(playlist)} items</td>
      </tr>
    ));
  };

  /**
   * Retrieve playlists and preselect playlists associated with the given feed item.
   */
  useEffect(() => {
    // Run the code only when popup is open
    if (show) {
      dispatch(getMyPlaylists({}));
      setSelectedPlaylists(feedItem.playlists || []);
    }
  }, [show, feedItem]);

  return (
    <Modal
      show={show}
      onClose={onClose}
      onSubmit={handleSubmit}
      hideFooter={playlists.length <= 0}
      body={
        <div className="px-4">
          {playlists.length > 0 ? (
            <>
              <h3 className="text-xl font-medium text-gray-900">
                {headerText}
              </h3>

              <p className="my-4 text-gray-800 font-semibold">Your playlists</p>

              <form onSubmit={handleSubmit} className="space-y-2">
                <div className="border rounded">
                  <table className="w-full text-sm text-left">
                    <thead className="text-xs text-gray-700 uppercase border-b">
                      <tr>
                        <th scope="col" className="px-6 py-3 bg-gray-50">
                          Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Items
                        </th>
                      </tr>
                    </thead>
                    <tbody>{displayPlaylists()}</tbody>
                  </table>
                </div>

                {displayErrors(getMyPlaylistsErrors.name)}
              </form>
            </>
          ) : (
            <>
              <ExclamationCircleIcon className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" aria-hidden="true" />
              <p className="my-4 text-gray-800 font-semibold text-center">
                You must create a playlist first!
              </p>
            </>
          )}
        </div>
      }
    />
  );
}
