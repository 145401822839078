import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import React from "react";

interface Props {
  text: string;
  size?: number;
}

/**
 * A question mark icon that displays a tooltip on hover.
 */
export default function MoreInfo({ text, size = 20 }: Props) {
  return (
    <QuestionMarkCircleIcon
      width={size}
      title={text}
      className="inline text-gray-500"
    />
  );
}
