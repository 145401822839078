/**
 * Replaces special characters in a string with their original values.
 */
export function replaceSpecialChars(val: string) {
  val = val.replaceAll("&amp;", "&");
  val = val.replaceAll("&#39;", "'");
  return val;
}

/**
 * Shorten text to a certain length and add an ellipsis at the end
 */
export function shortenText(
  val: string,
  length: number = 256,
  addEllipsis: boolean = true,
) {
  if (val.length > length) {
    val = val.substring(0, length - 3);
    if (addEllipsis) {
      val = val + "...";
    }
  }
  return val;
}
