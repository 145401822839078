import { ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";

interface Props {
  icon: React.ReactNode;
  label: string;
  url: string;
}

/**
 * Component that renders a menu item in the library menu.
 */
export default function LibraryMobileMenuItem({ icon, label, url }: Props) {
  /**
   * Redirects to the url.
   */
  function onClick() {
    window.location.href = url;
  }

  return (
    <button
      className="grid grid-cols-6 text-left space-x-4 w-full py-4 border-b-2"
      onClick={onClick}
      aria-label={`Navigate to ${label}`}
    >
      <div className="col-span-1 h-full flex text-gray-500 justify-center items-center">
        <div className="w-[30px]" aria-hidden="true">{icon}</div>
      </div>
      <div className="col-span-4 h-full text-xl text-gray-600 font-medium tracking-wider">
        {label}
      </div>
      <div className="col-span-1 h-full flex justify-center items-center">
        <ChevronRightIcon className="col-span-1 w-[20px] text-gray-500" aria-hidden="true" />
      </div>
    </button>
  );
}
