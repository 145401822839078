import React from "react";
import DeleteButton from "../button/DeleteButton";

interface Props {
  file: File;
  setFile: (arg: any) => void;
}

/**
 * A file attachment.
 */
export default function FileAttachment({ file, setFile }: Props) {
  const renderSize = (size: number) => {
    return size > 1024
      ? size > 1048576
        ? Math.round(size / 1048576) + "mb"
        : Math.round(size / 1024) + "kb"
      : size + "b";
  };

  return (
    <div
      className="flex items-center gap-2 mt-2 p-4 bg-gray-100 rounded border-2"
      role="group"
      aria-label={`File attachment: ${file.name}`}
    >
      <span className="font-medium text-sm">{file.name}</span>
      <span className="text-gray-600 text-sm">{renderSize(file.size)}</span>
      <DeleteButton onDelete={() => setFile(null)} />
    </div>
  );
}
