/** Value of transition-duration css property in miliseconds set for draggable items */
export const PLAYLISTS_DRAWER_TRANSITION_MS = 150;

/** Percentage of the top-most area of the screen. Pulling the playlist handlebar to this area will make the playlist drawer be fullscreen.
 * e.g.
 * PLAYLISTS_DRAWER_FULL_SCREEN_THRESHOLD = 0.2 -> Upper 20% of the screen
 * PLAYLISTS_DRAWER_FULL_SCREEN_THRESHOLD = 0.1 -> Upper 10% of the screen
 */
export const PLAYLISTS_DRAWER_FULL_SCREEN_THRESHOLD = 0.3;

/** Percentage of the bottom-most area of the screen. Pulling the playlist handlebar to this area will hide it.
 * e.g.
 * PLAYLISTS_DRAWER_HIDE_THRESHOLD = 0.2 -> Lower 20% of the screen
 * PLAYLISTS_DRAWER_HIDE_THRESHOLD = 0.1 -> Lower 10% of the screen
 */
export const PLAYLISTS_DRAWER_HIDE_THRESHOLD = 0.2;

/**
 * Enables css transition for the playlists drawer.
 */
export function enableTransition({
  ref,
}: {
  ref: React.MutableRefObject<HTMLDivElement | null>;
}) {
  if (ref?.current) {
    ref.current.style.transitionDuration = `${PLAYLISTS_DRAWER_TRANSITION_MS}ms`;
  }
}

/**
 * Disables css transition for the playlists drawer.
 */
export function disableTransition({
  ref,
}: {
  ref: React.MutableRefObject<HTMLDivElement | null>;
}) {
  if (ref?.current) {
    if (ref?.current) {
      ref.current.style.transitionDuration = "0ms";
    }
  }
}

/**
 * Returns the height of the window.
 */
export function getComponentHeight() {
  return window.outerHeight;
}

/**
 * Sets the playlist drawer to fullscreen.
 */
export function setPlaylistFullScreen(
  ref: React.MutableRefObject<HTMLDivElement | null>,
) {
  if (ref?.current) {
    ref.current.style.top = "0px";
    ref.current.style.height = `${getComponentHeight()}px`;
  }
}

/**
 * Hides the playlist drawer.
 */
export function setPlaylistHidden(
  ref: React.MutableRefObject<HTMLDivElement | null>,
) {
  if (ref?.current) {
    ref.current.style.top = `${getComponentHeight()}px`;
    ref.current.style.height = "0px";
  }
}

/**
 * Returns the top position of the playlist drawer when it is first rendered.
 */
export function getInitialPlaylistTop() {
  const top = getComponentHeight() / 2;
  return top;
}
