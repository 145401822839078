import React, { useState } from "react";
import { Bars3Icon, ListBulletIcon } from "@heroicons/react/24/solid";
import {
  ChevronDoubleDownIcon,
  Cog6ToothIcon,
  FlagIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import { APP_URLS } from "navigation";
import { useAppSelector } from "redux/hooks";
import { ReportFeedItemModal } from "components/report/ReportFeedItemModal";
import MobileNavBarExpandableMenu from "../common/MobileNavBarExpandableMenu";
import MobileBottomNavBarItem from "./MobileBottomNavBarItem";
import MobileBottomNavBarExpandableItem from "./MobileBottomNavBarExpandableItem";
import MobileNavBarFeedTypeSelection from "../common/MobileNavBarFeedTypeSelection";

const ICON_CLASSNAME = "w-8 h-8 text-gray-500 transition duration-75";
export const MOBILE_FEED_BOTTOM_NAV_BAR_HEIGHT = "88px";

/**
 * The bottom navigation bar for ScrollableMobileFeed.
 */
export default function MobileBottomNavBarScrollableFeed() {
  const [expandFeedMenu, setExpandFeedMenu] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const feedFilterTypes = useAppSelector(
    (state) => state.feedFilterTypes.feedFilterTypes,
  );
  const currFeedItem = useAppSelector(
    (state) => state.feedItemReports.currFeedItem,
  );

  return (
    <nav
      id="mobile-bottom-nav-bar-video-feed"
      className={
        "fixed bottom-0 left-0 z-50 w-screen bg-gray-50 border-t-2 border-gray-200"
      }
      aria-label="Mobile bottom navigation bar"
    >
      <div className="grid grid-cols-3 py-2 gap-2">
        <MobileBottomNavBarItem
          href={APP_URLS.INDEX}
          icon={<HomeIcon className={ICON_CLASSNAME} />}
          label="Home"
        />
        <MobileBottomNavBarItem
          href={APP_URLS.PLAYLISTS_MOBILE}
          icon={<ListBulletIcon className={ICON_CLASSNAME} />}
          label="Library"
        />
        <MobileBottomNavBarExpandableItem
          expanded={expandFeedMenu}
          onClick={() => setExpandFeedMenu(!expandFeedMenu)}
          collapsedIcon={<Bars3Icon className={ICON_CLASSNAME} />}
          expandedIcon={<ChevronDoubleDownIcon className={ICON_CLASSNAME} />}
          label="More"
        />
      </div>

      <MobileNavBarExpandableMenu expanded={expandFeedMenu} gridCols={3}>
        <MobileNavBarFeedTypeSelection
          feedFilterTypes={feedFilterTypes}
        />
        <MobileBottomNavBarItem
          href={APP_URLS.INDEX}
          icon={<Cog6ToothIcon className={ICON_CLASSNAME} />}
          label="Settings"
        />
        <MobileBottomNavBarItem
          onClick={() => setShowReportModal(true)}
          icon={<FlagIcon className={ICON_CLASSNAME} />}
          label="Report"
        />
      </MobileNavBarExpandableMenu>

      {currFeedItem && (
        <ReportFeedItemModal
          feedItem={currFeedItem}
          show={showReportModal}
          setShow={setShowReportModal}
        />
      )}
    </nav>
  );
}
