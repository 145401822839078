import { UserIcon } from "@heroicons/react/24/solid";
import { Spinner } from "flowbite-react";
import React from "react";
import { User } from "types/user";

interface Props {
  user: User | null;
  pending: boolean;
}

/**
 * Displays information about a user.
 */
export default function UserData({ user, pending }: Props) {
  function renderRow(label: string, value?: string) {
    return (
      <div className="grid grid-cols-2 border-b-2">
        <div className="text-gray-500 text-base py-2 font-semibold mr-8">
          {label}
        </div>
        <div className="text-gray-500 text-base py-2">{value || "-"}</div>
      </div>
    );
  }

  if (pending) {
    return (
      <div className="flex justify-center">
        <Spinner />
      </div>
    );
  }

  if (!user) {
    return (
      <div className="flex justify-center">
        Failed to retrieve user information
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      {pending ? (
        <Spinner />
      ) : (
        <div className="w-full lg:w-1/2">
          <div className="flex flex-col mb-2 justify-center items-center">
            <UserIcon className="w-20 h-20 p-4 box-content text-gray-600 bg-gray-200 rounded-full" />
          </div>
          {renderRow("Username:", user.username)}
          {renderRow("Email:", user.email)}
          {renderRow("First Name:", user.first_name)}
          {renderRow("Last Name:", user.last_name)}
        </div>
      )}
    </div>
  );
}
