import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadShazamLibrary as uploadShazamLibraryService } from "services/shazamService";
import { ErrorMessages } from "types/redux/slice";

interface ShazamState {
  pending: boolean;
  errorMessages: ErrorMessages;
}

const initialState: ShazamState = {
  pending: false,
  errorMessages: {},
};

export const shazamSlice = createSlice({
  name: "shazam",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadShazamLibrary.fulfilled, (state, action) => {
      state.pending = false;
      state.errorMessages = {};
    });
    builder.addCase(uploadShazamLibrary.pending, (state, action) => {
      state.pending = true;
      state.errorMessages = {};
    });
    builder.addCase(uploadShazamLibrary.rejected, (state, action) => {
      state.pending = false;
      state.errorMessages = action.payload || {};
    });
  },
});

export const uploadShazamLibrary = createAsyncThunk<
  boolean,
  FormData,
  { rejectValue: ErrorMessages }
>("shazam/uploadShazamTakeout", async (data, thunkApi) => {
  try {
    // Request the currently logged-in user with Django API
    await uploadShazamLibraryService(data);
    return true;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});
