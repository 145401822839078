import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import PaginationV2 from "components/common/pagination/PaginationV2";
import SearchInput from "components/common/searchInput";
import { Spinner } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "redux/hooks";
import { PaginatedList, QueryParams } from "types/api";
import { useDebounce } from "use-debounce";

interface Props {
  headerText: string;
  defaultOrdering: string;
  paginatedList: PaginatedList<any>;
  fetchThunkFunction: any;
  pending: boolean;
  labels: { [key: string]: string };
  additionalQueryParams?: QueryParams;
  onClickRow: (itemId: string) => void;
  valueOverrides?: { [key: string]: (val: any) => any };
}

/**
 * A table that displays a paginated list of items.
 */
export default function FeedDataTable({
  headerText,
  defaultOrdering,
  paginatedList,
  fetchThunkFunction,
  pending,
  labels,
  additionalQueryParams = {},
  valueOverrides = {},
  onClickRow,
}: Props) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [ordering, setOrdering] = useState(defaultOrdering);
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 500);
  const dispatch = useAppDispatch();

  function renderTableHeader(labels: { [key: string]: string }) {
    return Object.entries(labels).map(([key, label]) => {
      let icon = null;
      if (ordering === key) {
        icon = <ChevronDownIcon className="w-4" aria-hidden="true" aria-label="Sorted ascending" />;
      }
      if (ordering === "-" + key) {
        icon = <ChevronUpIcon className="w-4" aria-hidden="true" aria-label="Sorted descending" />;
      }

      function onClick() {
        if (ordering === key) {
          // Toggle ordering if already ordered by this key
          setOrdering("-" + ordering);
        } else if (ordering === "-" + key) {
          // Toggle ordering if already ordered by this key
          setOrdering(ordering.replaceAll("-", ""));
        } else {
          // Set ordering to this key
          setOrdering(key);
        }
      }

      return (
        <th className="p-2" key={key}>
          <button onClick={onClick} className="flex flex-row gap-1" aria-label={`Sort by ${label}`}>
            {icon}
            {label}
          </button>
        </th>
      );
    });
  }

  function renderTableRow(elem: any) {
    return (
      <tr
        key={elem.id}
        className="border-b border-gray-200 hover:cursor-pointer hover:bg-gray-50"
        onClick={() => onClickRow(elem.id)}
        role="button"
        tabIndex={0}
        aria-label={`Row for item ${elem.id}`}
      >
        {Object.entries(labels).map(([key, label]) => {
          // Handle value overrides
          const value =
            key in valueOverrides ? valueOverrides[key](elem[key]) : elem[key];

          // Check if value is a URL
          const valueIsUrl =
            typeof value === "string" &&
            (value.includes("http://") || value.includes("https://"));

          // Check if value is a boolean
          const valueIsBoolean = typeof value === "boolean";

          let renderedItem;

          // Render a link if value is a URL
          if (valueIsUrl) {
            renderedItem = (
              <a
                href={value}
                className="text-blue-600 font-medium underline cursor-pointer"
                target="_blank"
                rel="noreferrer"
                aria-label={`Link to ${value}`}
              >
                {value}
              </a>
            );
          }

          // Render a checkmark if value is a boolean
          if (valueIsBoolean) {
            renderedItem = value ? (
              <CheckCircleIcon className="w-8 text-green-500" aria-label="Yes" />
            ) : (
              <XCircleIcon className="w-8 text-red-500" aria-label="No" />
            );
          }

          // Use renderedItem if it was set, otherwise use value
          renderedItem = renderedItem ?? value;
          return (
            <td className="p-2" key={key}>
              {renderedItem}
            </td>
          );
        })}
      </tr>
    );
  }

  function fetchData(page: number, query: string) {
    const queryParams: QueryParams = {
      page: page.toString(),
      page_size: pageSize.toString(),
      query,
      ordering,
      ...additionalQueryParams,
    };
    dispatch(fetchThunkFunction({ queryParams }));
  }

  useEffect(() => {
    fetchData(page, debouncedQuery);
  }, [page, debouncedQuery, ordering]);

  return (
    <div>
      <h4 className="text-center font-medium text-lg py-2" role="heading" aria-level={1}>
        {headerText}
      </h4>
      <div className="pb-2">
        <SearchInput value={query} onChange={setQuery} />
      </div>

      {pending ? (
        <div className="flex justify-center items-center" role="status" aria-live="polite">
          <Spinner aria-label="Loading" />
        </div>
      ) : (
        <div className="border rounded overflow-y-auto">
          <table className="w-full text-sm text-left bg-white">
            <thead className="text-xs text-gray-700 uppercase border-b sticky top-0">
              <tr className="bg-gray-50">{renderTableHeader(labels)}</tr>
            </thead>
            <tbody>
              {paginatedList.results.map((elem) => renderTableRow(elem))}
            </tbody>
          </table>
        </div>
      )}

      <PaginationV2
        count={paginatedList.count}
        page={page}
        previous={paginatedList.previous}
        next={paginatedList.next}
        onClickPrev={() => setPage(page - 1)}
        onClickNext={() => setPage(page + 1)}
        pageSize={pageSize}
      />
    </div>
  );
}
