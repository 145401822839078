import React, { useEffect, useState } from "react";
import PageWrapper from "components/common/pageWrapper";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { history } from "helpers/history";
import { APP_URLS } from "navigation";
import { useParams } from "react-router-dom";
import { displayErrors } from "helpers/errors";
import QuizForm from "components/quiz/form/take-quiz/QuizForm";
import { retrieveQuizzes } from "redux/quiz/quizSlice";
import { Quiz } from "types/quiz";
import Header from "components/common/header";

interface Props {
  successRedirectUrl?: string;
  successMessage?: string | JSX.Element;
  successRedirectDelayMs?: number;
}

const DEFAULT_SUCCESS_MESSAGE = (
  <>
    Thank you for taking part in the quiz!
    <br />
    You will be redirected to home screen in 5 seconds.
  </>
);

/**
 * Quiz Details page
 */
export default function QuizDetails({
  successRedirectUrl = APP_URLS.VIDEOS,
  successMessage = DEFAULT_SUCCESS_MESSAGE,
  successRedirectDelayMs = 5000,
}: Props) {
  const quizzes = useAppSelector((store) => store.quiz.quizzes);
  const pendingRetrieveQuizzes = useAppSelector(
    (store) => store.quiz.pendingRetrieveQuizzes,
  );
  const quizzesErrorMessages = useAppSelector(
    (state) => state.quiz.quizzesErrorMessages,
  );
  const [quiz, setQuiz] = useState<Quiz | null>(null);
  const dispatch = useAppDispatch();
  const { id } = useParams();

  /**
   * Navigate to successRedirectUrl after a delay
   */
  function onSuccess() {
    setTimeout(
      () => history.navigate(successRedirectUrl),
      successRedirectDelayMs,
    );
  }

  /**
   * Render API errors
   */
  function renderErrors() {
    if (quizzesErrorMessages) {
      // Render a different message for this specific case
      let errors = quizzesErrorMessages?.id;
      if (
        quizzesErrorMessages?.id &&
        quizzesErrorMessages.id.includes("Enter a valid UUID.")
      ) {
        errors = ["Couldn't find a quiz with provided id."];
      }
      return displayErrors(errors);
    }
  }

  useEffect(() => {
    document.title = "Quizzes | BrainCargo";
  }, []);

  /**
   * Extract quiz data
   */
  useEffect(() => {
    if (quizzes.results.length > 0) {
      setQuiz({ ...quizzes.results[0] });
    } else {
      setQuiz(null);
    }
  }, [quizzes]);

  /**
   * Fetch quiz data
   */
  useEffect(() => {
    if (id) {
      dispatch(retrieveQuizzes({ queryParams: { id } }));
    }
  }, [id]);

  return (
    <PageWrapper>
      <div className="border shadow-md lg:rounded-lg bg-white pb-6 space-y-2">
        <Header
          title={quiz?.name || "UNKNOWN QUIZ NAME"}
          loading={pendingRetrieveQuizzes}
        />

        <div className="px-6 space-y-2">
          {Object.keys(quizzesErrorMessages).length === 0 ? (
            <QuizForm
              onSuccess={onSuccess}
              successText={successMessage}
              quiz={quiz}
            />
          ) : (
            renderErrors()
          )}
        </div>
      </div>
    </PageWrapper>
  );
}
