import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createPlaylist,
  retrieveMyPlaylists,
  retrieveSharedPlaylists,
  addItemToPlaylists as addItemToPlaylistsService,
  sharePlaylistsWithUsers as sharePlaylistsWithUsersService,
  removeItemFromPlaylist as removeItemFromPlaylistService,
  retrievePlaylist,
  patchPlaylist,
  deletePlaylist as deletePlaylistService,
} from "services/playlistService";
import { QueryParams } from "types/api";
import {
  AddItemToPlaylistPayload,
  Playlist,
  PlaylistPayload,
  RemoveItemFromPlaylistPayload,
  sharePlaylistsWithUsersPayload,
} from "types/playlist";
import { ErrorMessages } from "types/redux/slice";

interface PlaylistState {
  myPlaylists: Array<Playlist>;
  sharedPlaylists: Array<Playlist>;
  playlist: Playlist | null;

  getPlaylistPending: boolean;
  getMyPlaylistsPending: boolean;
  getSharedPlaylistsPending: boolean;
  addPlaylistPending: boolean;
  updatePlaylistPending: boolean;
  deletePlaylistPending: boolean;
  addItemToPlaylistsPending: boolean;
  removeItemFromPlaylistPending: boolean;
  sharePlaylistsWithUsersPending: boolean;

  getPlaylistErrors: ErrorMessages;
  getMyPlaylistsErrors: ErrorMessages;
  getSharedPlaylistsErrors: ErrorMessages;
  addPlaylistErrors: ErrorMessages;
  deletePlaylistErrors: ErrorMessages;
  updatePlaylistErrors: ErrorMessages;
  addItemToPlaylistsErrors: ErrorMessages;
  removeItemFromPlaylistErrors: ErrorMessages;
  sharePlaylistsWithUsersErrors: ErrorMessages;
}

const initialState: PlaylistState = {
  myPlaylists: [],
  sharedPlaylists: [],
  playlist: null,

  getPlaylistPending: false,
  getMyPlaylistsPending: false,
  getSharedPlaylistsPending: false,
  addPlaylistPending: false,
  deletePlaylistPending: false,
  updatePlaylistPending: false,
  addItemToPlaylistsPending: false,
  removeItemFromPlaylistPending: false,
  sharePlaylistsWithUsersPending: false,

  getPlaylistErrors: {},
  getMyPlaylistsErrors: {},
  getSharedPlaylistsErrors: {},
  addPlaylistErrors: {},
  deletePlaylistErrors: {},
  updatePlaylistErrors: {},
  addItemToPlaylistsErrors: {},
  removeItemFromPlaylistErrors: {},
  sharePlaylistsWithUsersErrors: {},
};

export const playlistSlice = createSlice({
  name: "playlist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPlaylist.fulfilled, (state, action) => {
      state.playlist = action.payload;
      state.getPlaylistPending = false;
      state.getPlaylistErrors = {};
    });
    builder.addCase(getPlaylist.pending, (state, action) => {
      state.getPlaylistPending = true;
    });
    builder.addCase(getPlaylist.rejected, (state, action) => {
      state.getPlaylistPending = false;
      state.getPlaylistErrors = action.payload || {};
    });

    builder.addCase(getMyPlaylists.fulfilled, (state, action) => {
      state.myPlaylists = action.payload;
      state.getMyPlaylistsPending = false;
      state.getMyPlaylistsErrors = {};
    });
    builder.addCase(getMyPlaylists.pending, (state, action) => {
      state.getMyPlaylistsPending = true;
    });
    builder.addCase(getMyPlaylists.rejected, (state, action) => {
      state.getMyPlaylistsPending = false;
      state.getMyPlaylistsErrors = action.payload || {};
    });

    builder.addCase(getSharedPlaylists.fulfilled, (state, action) => {
      state.sharedPlaylists = action.payload;
      state.getSharedPlaylistsPending = false;
      state.getSharedPlaylistsErrors = {};
    });
    builder.addCase(getSharedPlaylists.pending, (state, action) => {
      state.getSharedPlaylistsPending = true;
    });
    builder.addCase(getSharedPlaylists.rejected, (state, action) => {
      state.getSharedPlaylistsPending = false;
      state.getSharedPlaylistsErrors = action.payload || {};
    });

    builder.addCase(addPlaylist.fulfilled, (state, action) => {
      state.addPlaylistPending = false;
      state.addPlaylistErrors = {};
    });
    builder.addCase(addPlaylist.pending, (state, action) => {
      state.addPlaylistPending = true;
    });
    builder.addCase(addPlaylist.rejected, (state, action) => {
      state.addPlaylistPending = false;
      state.addPlaylistErrors = action.payload || {};
    });

    builder.addCase(updatePlaylist.fulfilled, (state, action) => {
      state.updatePlaylistPending = false;
      state.updatePlaylistErrors = {};
    });
    builder.addCase(updatePlaylist.pending, (state, action) => {
      state.updatePlaylistPending = true;
    });
    builder.addCase(updatePlaylist.rejected, (state, action) => {
      state.updatePlaylistPending = false;
      state.updatePlaylistErrors = action.payload || {};
    });

    builder.addCase(addItemToPlaylists.fulfilled, (state, action) => {
      state.addItemToPlaylistsPending = false;
      state.addItemToPlaylistsErrors = {};
    });
    builder.addCase(addItemToPlaylists.pending, (state, action) => {
      state.addItemToPlaylistsPending = true;
    });
    builder.addCase(addItemToPlaylists.rejected, (state, action) => {
      state.addItemToPlaylistsPending = false;
      state.addItemToPlaylistsErrors = action.payload || {};
    });

    builder.addCase(removeItemFromPlaylist.fulfilled, (state, action) => {
      state.removeItemFromPlaylistPending = false;
      state.removeItemFromPlaylistErrors = {};
    });
    builder.addCase(removeItemFromPlaylist.pending, (state, action) => {
      state.removeItemFromPlaylistPending = true;
    });
    builder.addCase(removeItemFromPlaylist.rejected, (state, action) => {
      state.removeItemFromPlaylistPending = false;
      state.removeItemFromPlaylistErrors = action.payload || {};
    });
  },
});

export const getPlaylist = createAsyncThunk<
  Playlist,
  { id: string },
  { rejectValue: ErrorMessages }
>("videos/getPlaylist", async ({ id }, thunkApi) => {
  try {
    const response = await retrievePlaylist({ id });
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const getMyPlaylists = createAsyncThunk<
  Playlist[],
  { queryParams?: QueryParams },
  { rejectValue: ErrorMessages }
>("videos/getMyPlaylists", async (data, thunkApi) => {
  try {
    const response = await retrieveMyPlaylists(data);
    return response.results;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const getSharedPlaylists = createAsyncThunk<
  Playlist[],
  { queryParams?: QueryParams },
  { rejectValue: ErrorMessages }
>("videos/getSharedPlaylists", async (data, thunkApi) => {
  try {
    const response = await retrieveSharedPlaylists(data);
    return response.results;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const addPlaylist = createAsyncThunk<
  Playlist,
  PlaylistPayload,
  { rejectValue: ErrorMessages }
>("videos/addPlaylist", async (data, thunkApi) => {
  try {
    const response = await createPlaylist(data);
    thunkApi.dispatch(getMyPlaylists({}));
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const updatePlaylist = createAsyncThunk<
  Playlist,
  { data: PlaylistPayload; playlistId: string },
  { rejectValue: ErrorMessages }
>("videos/updatePlaylist", async ({ data, playlistId }, thunkApi) => {
  try {
    const response = await patchPlaylist(playlistId, data);
    thunkApi.dispatch(getMyPlaylists({}));
    thunkApi.dispatch(getPlaylist({ id: playlistId }));
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const deletePlaylist = createAsyncThunk<
  boolean,
  string,
  { rejectValue: ErrorMessages }
>("videos/deletePlaylist", async (playlistId, thunkApi) => {
  try {
    const response = await deletePlaylistService(playlistId);
    thunkApi.dispatch(getMyPlaylists({}));
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const addItemToPlaylists = createAsyncThunk<
  Playlist,
  AddItemToPlaylistPayload,
  { rejectValue: ErrorMessages }
>("videos/addItemToPlaylists", async (data, thunkApi) => {
  try {
    const response = await addItemToPlaylistsService(data);
    thunkApi.dispatch(getMyPlaylists({}));
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const removeItemFromPlaylist = createAsyncThunk<
  Playlist,
  RemoveItemFromPlaylistPayload,
  { rejectValue: ErrorMessages }
>("videos/removeItemFromPlaylists", async (data, thunkApi) => {
  try {
    const response = await removeItemFromPlaylistService(data);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const sharePlaylistsWithUsers = createAsyncThunk<
  Playlist,
  sharePlaylistsWithUsersPayload,
  { rejectValue: ErrorMessages }
>("videos/sharePlaylistsWithUsers", async (data, thunkApi) => {
  try {
    const response = await sharePlaylistsWithUsersService(data);
    thunkApi.dispatch(getMyPlaylists({}));
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});
