import axios from "axios";
import { getAccessToken, removeTokens } from "./localStorage";
import { history } from "helpers/history";
import { QueryParams } from "types/api";
import { APP_URLS } from "navigation";

/**
 * Default page size for paginating API requests
 */
export const PAGE_SIZE = 8;

/**
 * Base URL for API requests
 */
export const API_BASE_URL =
  // process.env.REACT_APP_API_BASE_URL || "http://none:8000";
  process.env.REACT_APP_API_BASE_URL || "https://staging-api.braincargo.com";
console.log("API_BASE_URL", API_BASE_URL);
/**
 * Generate headers for API requests
 */
const generateHeaders = function (contentType = "application/json") {
  const accessToken = getAccessToken();

  return {
    headers: {
      "Content-Type": contentType,
      "Authorization": `Bearer ${accessToken}`,
    },
  };
};

/**
 * Remove tokens and redirect to login page if response status is 401, otherwise return response data
 */
const handleResponse = (response: any) => {
  if (response.status === 401) {
    removeTokens();
    history.navigate(APP_URLS.LOGIN);
  }
  return response.data;
};

/**
 * Make a GET request to the API
 */
export const get = async function (
  url: string,
  noHeaders = false,
  contentType?: string,
  queryParams?: QueryParams,
) {
  const headers = noHeaders ? {} : generateHeaders(contentType);
  const urlObj = new URL(API_BASE_URL + url);

  // Set default page size and provided query params
  urlObj.searchParams.set("page_size", PAGE_SIZE.toString());
  if (queryParams) {
    Object.keys(queryParams).forEach((key) => {
      const val = queryParams[key];
      if (val) {
        urlObj.searchParams.set(key, val);
      }
    });
  }

  try {
    const response = await axios.get(urlObj.toString(), headers);
    return handleResponse(response);
  } catch (err: any) {
    throw handleResponse(err.response);
  }
};

/**
 * Make a POST request to the API
 */
export const post = async function (
  url: string,
  body: any,
  noHeaders = false,
  contentType?: string,
) {
  const headers = noHeaders ? {} : generateHeaders(contentType);

  try {
    const response = await axios.post(API_BASE_URL + url, body, headers);
    return handleResponse(response);
  } catch (err: any) {
    throw handleResponse(err.response);
  }
};

/**
 * Make a PUT request to the API
 */
export const put = async function (
  url: string,
  body: any,
  noHeaders = false,
  contentType?: string,
) {
  const headers = noHeaders ? {} : generateHeaders(contentType);

  try {
    const response = await axios.put(API_BASE_URL + url, body, headers);
    return handleResponse(response);
  } catch (err: any) {
    throw handleResponse(err.response);
  }
};

/**
 * Make a PATCH request to the API
 */
export const patch = async function (
  url: string,
  body: any,
  noHeaders = false,
  contentType?: string,
) {
  const headers = noHeaders ? {} : generateHeaders(contentType);

  try {
    const response = await axios.patch(API_BASE_URL + url, body, headers);
    return handleResponse(response);
  } catch (err: any) {
    throw handleResponse(err.response);
  }
};

/**
 * Make a DELETE request to the API
 */
export const deleteRequest = async function (
  url: string,
  noHeaders = false,
  contentType?: string,
) {
  const headers = noHeaders ? {} : generateHeaders(contentType);

  try {
    const response = await axios.delete(API_BASE_URL + url, headers);
    return handleResponse(response);
  } catch (err: any) {
    throw handleResponse(err.response);
  }
};
