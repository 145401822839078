import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  retrieveYoutubeLoginUrl,
  uploadGoogleTakeout as uploadGoogleTakeoutService,
} from "services/youtubeService";
import { ErrorMessages } from "types/redux/slice";

interface YoutubeState {
  pending: boolean;
  errorMessages: ErrorMessages;
  connectionUrl: string | null;
}

const initialState: YoutubeState = {
  pending: false,
  errorMessages: {},
  connectionUrl: null,
};

export const youtubeSlice = createSlice({
  name: "youtube",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getYoutubeConnectionUrl.fulfilled, (state, action) => {
      state.pending = false;
      state.errorMessages = {};
      state.connectionUrl = action.payload;
    });
    builder.addCase(getYoutubeConnectionUrl.pending, (state, action) => {
      state.pending = true;
      state.errorMessages = {};
      state.connectionUrl = null;
    });
    builder.addCase(getYoutubeConnectionUrl.rejected, (state, action) => {
      state.pending = false;
      state.errorMessages = action.payload || {};
      state.connectionUrl = null;
    });

    builder.addCase(uploadGoogleTakeout.fulfilled, (state, action) => {
      state.pending = false;
      state.errorMessages = {};
    });
    builder.addCase(uploadGoogleTakeout.pending, (state, action) => {
      state.pending = true;
      state.errorMessages = {};
    });
    builder.addCase(uploadGoogleTakeout.rejected, (state, action) => {
      state.pending = false;
      state.errorMessages = action.payload || {};
    });
  },
});

export const getYoutubeConnectionUrl = createAsyncThunk<
  string,
  void,
  { rejectValue: ErrorMessages }
>("youtube/getConnectionUrl", async (data, thunkApi) => {
  try {
    // Request the currently logged-in user with Django API
    const response = await retrieveYoutubeLoginUrl();
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const uploadGoogleTakeout = createAsyncThunk<
  boolean,
  FormData,
  { rejectValue: ErrorMessages }
>("youtube/uploadGoogleTakeout", async (data, thunkApi) => {
  try {
    // Request the currently logged-in user with Django API
    await uploadGoogleTakeoutService(data);
    return true;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});
