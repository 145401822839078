import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as feedService from "services/feedService";
import { PaginatedList, QueryParams } from "types/api";
import {
  FeedItem,
  FeedItemReport,
  SubmitFeedItemReportPayload,
  ToggleFeedItemReportStatusPayload,
} from "types/feed";
import { ErrorMessages } from "types/redux/slice";

const INITIAL_PAGINATED_LIST = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};

interface FeedItemReportsState {
  feedItemReports: PaginatedList<FeedItemReport>;
  /** Used to pass current feed item from ScrollableMobileFeed to MobileBottomNavBarScrollableFeed */
  currFeedItem: FeedItem | null;

  pendingGetFeedItemReports: boolean;
  errorsGetFeedItemReports: ErrorMessages;

  pendingSubmitFeedItemReport: boolean;
  errorsSubmitFeedItemReport: ErrorMessages;

  pendingToggleFeedItemReportReviewStatus: boolean;
  errorsToggleFeedItemReportReviewStatus: ErrorMessages;
}

const initialState: FeedItemReportsState = {
  feedItemReports: INITIAL_PAGINATED_LIST,
  currFeedItem: null,
  pendingGetFeedItemReports: false,
  errorsGetFeedItemReports: {},
  pendingSubmitFeedItemReport: false,
  errorsSubmitFeedItemReport: {},
  pendingToggleFeedItemReportReviewStatus: false,
  errorsToggleFeedItemReportReviewStatus: {},
};

export const feedItemReportsSlice = createSlice({
  name: "feedItemReports",
  initialState,
  reducers: {
    setCurrFeedItem: (state, action) => {
      state.currFeedItem = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFeedItemReports.fulfilled, (state, action) => {
      state.feedItemReports = action.payload;
      state.pendingGetFeedItemReports = false;
    });
    builder.addCase(getFeedItemReports.pending, (state, action) => {
      state.errorsGetFeedItemReports = {};
      state.pendingGetFeedItemReports = true;
    });
    builder.addCase(getFeedItemReports.rejected, (state, action) => {
      state.errorsGetFeedItemReports = action.payload || {};
      state.pendingGetFeedItemReports = false;
    });

    builder.addCase(submitFeedItemReport.fulfilled, (state, action) => {
      state.pendingSubmitFeedItemReport = false;
    });
    builder.addCase(submitFeedItemReport.pending, (state, action) => {
      state.errorsSubmitFeedItemReport = {};
      state.pendingSubmitFeedItemReport = true;
    });
    builder.addCase(submitFeedItemReport.rejected, (state, action) => {
      state.errorsSubmitFeedItemReport = action.payload || {};
      state.pendingSubmitFeedItemReport = false;
    });

    builder.addCase(
      toggleFeedItemReportReviewStatus.fulfilled,
      (state, action) => {
        state.pendingToggleFeedItemReportReviewStatus = false;
      },
    );
    builder.addCase(
      toggleFeedItemReportReviewStatus.pending,
      (state, action) => {
        state.errorsToggleFeedItemReportReviewStatus = {};
        state.pendingToggleFeedItemReportReviewStatus = true;
      },
    );
    builder.addCase(
      toggleFeedItemReportReviewStatus.rejected,
      (state, action) => {
        state.errorsToggleFeedItemReportReviewStatus = action.payload || {};
        state.pendingToggleFeedItemReportReviewStatus = false;
      },
    );
  },
});

export const getFeedItemReports = createAsyncThunk<
  PaginatedList<FeedItemReport>,
  { queryParams?: QueryParams },
  { rejectValue: ErrorMessages }
>("feedItemReports/getFeedItemReports", async (data, thunkApi) => {
  try {
    const response = await feedService.retrieveFeedItemReports(data);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const submitFeedItemReport = createAsyncThunk<
  boolean,
  SubmitFeedItemReportPayload,
  { rejectValue: ErrorMessages }
>("feedItemReports/submitFeedItemReport", async (data, thunkApi) => {
  try {
    const response = await feedService.createFeedItemReport(data);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const toggleFeedItemReportReviewStatus = createAsyncThunk<
  boolean,
  ToggleFeedItemReportStatusPayload,
  { rejectValue: ErrorMessages }
>(
  "feedItemReports/toggleFeedItemReportReviewStatus",
  async (data, thunkApi) => {
    try {
      const response = await feedService.toggleFeedItemReportReviewStatus(data);
      return response;
    } catch (err) {
      return thunkApi.rejectWithValue(err as ErrorMessages);
    }
  },
);

export const { setCurrFeedItem } = feedItemReportsSlice.actions;
