import React, { useEffect, useState } from "react";
import PageWrapper from "components/common/pageWrapper";
import { UserIcon } from "@heroicons/react/24/solid";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import SpotifyConnection from "components/connections/SpotifyConnection";
import YoutubeConnection from "components/connections/YoutubeConnection";
import ShazamConnection from "components/connections/ShazamConnection";
import { retrieveQuizAnswers, retrieveQuizzes } from "redux/quiz/quizSlice";
import { Button, Spinner, ToggleSwitch, TextInput } from "flowbite-react";
import { history } from "helpers/history";
import { APP_URLS } from "navigation";
import { Quiz } from "types/quiz";
import Header from "components/common/header";
import { removeTokens } from "services/localStorage";
import { formatDate } from "helpers/date";
import { updateUser } from "redux/user/userSlice";
import { toast } from "react-toastify";

/**
 * Profile page
 */
export default function Profile() {
  const user = useAppSelector((state) => state.user.user);
  const quizzes = useAppSelector((state) => state.quiz.quizzes);
  const quizAnswers = useAppSelector((state) => state.quiz.answers);
  const pendingRetrieveQuizzes = useAppSelector(
    (state) => state.quiz.pendingRetrieveQuizzes,
  );
  const pendingRetrieveQuizAnswers = useAppSelector(
    (state) => state.quiz.pendingRetrieveQuizAnswers,
  );
  const [mainQuiz, setMainQuiz] = useState<Quiz | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editableUser, setEditableUser] = useState({
    username: user?.username || "",
    email: user?.email || "",
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    is_touch_interface_enabled: user?.is_touch_interface_enabled || false,
  });
  const dispatch = useAppDispatch();

  /**
   * Navigate to quiz page
   */
  function onClickTakeQuiz() {
    if (mainQuiz) {
      const url = APP_URLS.PROFILE_QUIZ.replace(":id", mainQuiz.id);
      history.navigate(url);
    }
  }

  /**
   * Render take/retake quiz button
   */
  function renderQuizButton() {
    let buttonContent;
    const pending = pendingRetrieveQuizAnswers || pendingRetrieveQuizzes;
    if (pending) {
      buttonContent = <Spinner size="sm" aria-label="Loading spinner" />;
    } else if (quizAnswers.length === 0) {
      buttonContent = "Take Quiz";
    } else {
      buttonContent = "Retake Quiz";
    }

    return (
      <div>
        <Button onClick={onClickTakeQuiz} size="md" disabled={pending} aria-label="Take or Retake Quiz">
          {buttonContent}
        </Button>
        {quizAnswers.length !== 0 && (
          <span>Last taken: {formatDate(quizAnswers[0].updated_at || "")}</span>
        )}
      </div>
    );
  }

  /**
   * Log the user out
   */
  const onLogoutClick = () => {
    // Remove authentication tokens from local storage
    removeTokens();
    // Navigate user to Login page
    history.navigate(APP_URLS.LOGIN);
  };

  /**
   * Retrieve main quiz
   */
  useEffect(() => {
    document.title = "Profile | BrainCargo";
    dispatch(retrieveQuizzes({ queryParams: { main_quiz: "true" } }));
  }, []);

  /**
   * Retrieve main quiz answers and extract main quiz data
   */
  useEffect(() => {
    if (quizzes.results.length > 0) {
      const newMainQuiz = quizzes.results[0];
      // Retrieve answers to the quiz
      dispatch(retrieveQuizAnswers({ quizId: newMainQuiz.id }));
      setMainQuiz(newMainQuiz);
    }
  }, [quizzes]);

  useEffect(() => {
    if (user) {
      setEditableUser({
        username: user.username || "",
        email: user.email || "",
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        is_touch_interface_enabled: user.is_touch_interface_enabled || false,
      });
    }
  }, [user]);

  /**
   * Handle touch interface toggle change
   */
  const handleTouchInterfaceChange = (isEnabled: boolean) => {
    setEditableUser((prev) => ({
      ...prev,
      is_touch_interface_enabled: isEnabled,
    }));
  };

  /**
   * Handle input changes
   */
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditableUser((prev) => ({ ...prev, [name]: value }));
  };

  /**
   * Save changes
   */
  const handleSaveChanges = () => {
    toast.promise(dispatch(updateUser(editableUser)), {
      pending: "Saving changes...",
      success: "Changes saved successfully!",
      error: "Failed to save changes.",
    });
    setIsEditMode(false);
  };

  return (
    <PageWrapper>
      <div className="border shadow-md lg:rounded-lg bg-white pb-6">
        <Header title="Profile" />

        <div className="p-4">
          <div className="flex flex-col gap-2 justify-center items-center">
            <UserIcon className="w-20 h-20 p-4 box-content text-gray-600 bg-gray-200 rounded-full" aria-label="User Icon" />
            <Button onClick={onLogoutClick} size="md" color="failure" aria-label="Logout">
              Logout
            </Button>
            <table className="mt-4 w-full lg:w-1/2">
              <tbody>
                <tr className="border-gray-200 border-b-2">
                  <td className="py-2 w-1/2">
                    <span className="text-gray-500 text-base font-semibold mr-8">
                      Username:
                    </span>
                  </td>
                  <td className="py-2 w-1/2">
                    <TextInput
                      name="username"
                      value={editableUser.username}
                      onChange={handleInputChange}
                      disabled={!isEditMode}
                      aria-label="Username"
                    />
                  </td>
                </tr>

                <tr className="border-gray-200 border-b-2">
                  <td className="py-2 w-1/2">
                    <span className="text-gray-500 text-base font-semibold mr-8">
                      Email:
                    </span>
                  </td>
                  <td className="py-2 w-1/2">
                    <TextInput
                      name="email"
                      value={editableUser.email}
                      onChange={handleInputChange}
                      disabled={!isEditMode}
                      aria-label="Email"
                    />
                  </td>
                </tr>

                <tr className="border-gray-200 border-b-2">
                  <td className="py-2 w-1/2">
                    <span className="text-gray-500 text-base font-semibold mr-8">
                      First Name:
                    </span>
                  </td>
                  <td className="py-2 w-1/2">
                    <TextInput
                      name="first_name"
                      value={editableUser.first_name}
                      onChange={handleInputChange}
                      disabled={!isEditMode}
                      aria-label="First Name"
                    />
                  </td>
                </tr>

                <tr className="border-gray-200 border-b-2">
                  <td className="py-2 w-1/2">
                    <span className="text-gray-500 text-base font-semibold mr-8">
                      Last Name:
                    </span>
                  </td>
                  <td className="py-2 w-1/2">
                    <TextInput
                      name="last_name"
                      value={editableUser.last_name}
                      onChange={handleInputChange}
                      disabled={!isEditMode}
                      aria-label="Last Name"
                    />
                  </td>
                </tr>

                <tr className="border-gray-200 border-b-2">
                  <td className="py-2 w-1/2">
                    <span className="text-gray-500 text-base font-semibold mr-8">
                      Touch Interface:
                    </span>
                  </td>
                  <td className="py-2 w-1/2">
                    <ToggleSwitch
                      checked={editableUser.is_touch_interface_enabled}
                      onChange={handleTouchInterfaceChange}
                      disabled={!isEditMode}
                      aria-label="Touch Interface"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="mt-4 flex gap-2">
              <Button onClick={() => setIsEditMode(!isEditMode)} size="md" aria-label={isEditMode ? "Cancel Edit" : "Edit"}>
                {isEditMode ? "Cancel" : "Edit"}
              </Button>
              {isEditMode && (
                <Button onClick={handleSaveChanges} size="md" color="success" aria-label="Save Changes">
                  Save
                </Button>
              )}
            </div>
          </div>
        </div>

        <Header title="Media preference quiz:" isSubHeader />
        <div className="flex flex-col content-center items-center justify-center p-4">
          <table className="w-full lg:w-1/2">
            <tbody>
              <tr className="border-gray-200 border-b-2">
                <td className="py-2 w-1/2">
                  <span className="text-gray-500 text-base font-semibold mr-8">
                    Quiz:
                  </span>
                </td>
                <td className="py-2 w-1/2">
                  <span className="text-gray-500 text-base">
                    {renderQuizButton()}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <Header title="Connect Accounts" isSubHeader />
        <div className="flex flex-col content-center items-center justify-center p-4">
          <table className="w-full lg:w-1/2">
            <tbody>
              <tr className="border-gray-200 border-b-2">
                <td className="py-2 w-1/2" align="left">
                  <span className="text-gray-500 text-base font-semibold">
                    Spotify
                  </span>
                </td>
                <td className="py-2 w-1/2" align="left">
                  <SpotifyConnection />
                </td>
              </tr>
              <tr className="border-gray-200 border-b-2">
                <td className="py-2 w-1/2" align="left">
                  <span className="text-gray-500 text-base font-semibold">
                    YouTube
                  </span>
                </td>
                <td className="py-2 w-1/2" align="left">
                  <YoutubeConnection />
                </td>
              </tr>
              <tr className="border-gray-200 border-b-2">
                <td className="py-2 w-1/2" align="left">
                  <span className="text-gray-500 text-base font-semibold">
                    Shazam
                  </span>
                </td>
                <td className="py-2 w-1/2" align="left">
                  <ShazamConnection />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <Header title="Wallet" isSubHeader />
        <div className="p-4 text-center">
          <span className="text-gray-500 text-base font-semibold">
            Placeholder text
          </span>
        </div>

        <Header title="NFT" isSubHeader />
        <div className="p-4 text-center">
          <span className="text-gray-500 text-base font-semibold">
            Placeholder text
          </span>
        </div>

        <Header title="Earn" isSubHeader />
        <div className="p-4 text-center">
          <span className="text-gray-500 text-base font-semibold">
            Placeholder text
          </span>
        </div>

        <Header title="AI Training" isSubHeader />
        <div className="p-4 text-center">
          <span className="text-gray-500 text-base font-semibold">
            Placeholder text
          </span>
        </div>

        <div className="text-center">
          <span className="text-gray-500 text-base font-semibold">
            More integrations on the way!
          </span>
        </div>
      </div>
    </PageWrapper>
  );
}
