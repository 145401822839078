import { TrashIcon } from "@heroicons/react/24/outline";
import FileUpload from "components/common/file/FileUpload";
import { Checkbox, Label, TextInput } from "flowbite-react";
import { displayErrors } from "helpers/errors";
import React, { useEffect, useState } from "react";
import {
  QUESTION_TYPES,
  QuizQuestionAnswerOptionFormData,
  QuizQuestionFormData,
} from "types/quiz";
import { ErrorMessages } from "types/redux/slice";
import AnswerOptionImage from "../../AnswerOptionImage";
import { useAppSelector } from "redux/hooks";

interface Props {
  question: QuizQuestionFormData;
  data: QuizQuestionAnswerOptionFormData;
  onChange: (data: QuizQuestionAnswerOptionFormData) => void;
  errors: ErrorMessages;
  answerIdx?: number;
}

/**
 * Quiz question answer option input fields.
 */
export default function QuizQuestionAnswerOptionInput({
  question,
  answerIdx,
  data,
  onChange,
  errors,
}: Props) {
  const aggregatedUploadAnswerOptionImageErrorMessages = useAppSelector(
    (state) => state.quiz.aggregatedUploadAnswerOptionImageErrorMessages,
  );

  const [showImageUpload, setShowImageUpload] = useState(
    !!(data.external_image || data.image) || false,
  );
  const randomId = (Math.random() + 1).toString(36).substring(7);

  /**
   * Handle input change.
   */
  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const key = e.target.name as keyof QuizQuestionAnswerOptionFormData;
    const newData = { ...data, [key]: value };
    onChange(newData);
  }

  /**
   * Handle image change.
   */
  function handleImageChange(file: File | null) {
    const newData = { ...data, external_image: "", image: file };
    onChange(newData);
  }

  /**
   * Handle external image change.
   */
  function handleExternalImageChange(e: React.ChangeEvent<HTMLInputElement>) {
    const external_image = e.target.value;
    const newData = { ...data, external_image, image: null };
    onChange(newData);
  }

  /**
   * Clear image.
   */
  function clearImage() {
    onChange({ ...data, external_image: "", image: null });
  }

  // Disable if the quiz has no id - meaning it has not been created yet
  const isImageCheckboxDisabled = !data?.id;
  const questionType = question?.type;
  const disabledFieldTitle = "You cannot edit this field";

  /**
   * Generate disabled checkbox title.
   */
  function generateDisabledCheckboxTitle() {
    if (questionType === QUESTION_TYPES.RATING) {
      return "You cannot attach images to answers of rating-type questions";
    } else if (isImageCheckboxDisabled) {
      return "You must first submit the answer before you can attach an image to it";
    }
    return undefined;
  }

  /**
   * Show image upload input field if the answer already has an image.
   */
  useEffect(() => {
    setShowImageUpload(!!(data.external_image || data.image) || false);
  }, [data]);

  return (
    <div className="flex flex-col gap-2">
      <Label
        value={answerIdx !== undefined ? `Answer ${answerIdx + 1}` : "Answer"}
      />
      {displayErrors(errors?.text)}
      <TextInput
        type="text"
        value={data.text}
        name="text"
        onChange={handleOnChange}
        placeholder="The Amazing Spider-Man"
        disabled={questionType === QUESTION_TYPES.RATING}
        title={
          questionType === QUESTION_TYPES.RATING
            ? disabledFieldTitle
            : undefined
        }
        aria-label="Answer text"
      />

      <Label value="Identifier of the answer" />
      {displayErrors(errors?.value)}
      <TextInput
        type="text"
        value={data.value}
        name="value"
        onChange={handleOnChange}
        placeholder="the-amazing-spider-man"
        disabled={questionType === QUESTION_TYPES.RATING}
        title={
          questionType === QUESTION_TYPES.RATING
            ? disabledFieldTitle
            : undefined
        }
        aria-label="Answer identifier"
      />

      <Label value="Position of the answer on the questionnaire page" />
      {displayErrors(errors?.position)}
      <TextInput
        type="number"
        min="1"
        value={data.position}
        name="position"
        onChange={handleOnChange}
        disabled={questionType === QUESTION_TYPES.RATING}
        title={
          questionType === QUESTION_TYPES.RATING
            ? disabledFieldTitle
            : undefined
        }
        aria-label="Answer position"
      />

      <div className="flex items-center gap-2">
        <Checkbox
          disabled={isImageCheckboxDisabled}
          title={generateDisabledCheckboxTitle()}
          className={isImageCheckboxDisabled ? "cursor-not-allowed" : ""}
          checked={showImageUpload}
          onChange={() => setShowImageUpload(!showImageUpload)}
          aria-label="Assign image to answer"
        />
        <Label value="Would you like to assign an image to this answer?" />
      </div>

      {showImageUpload && (
        <div className="border rounded p-4 space-y-2" key={answerIdx}>
          <Label value="Upload image" />
          {answerIdx !== undefined &&
            question.answer_options[answerIdx].id !== undefined &&
            displayErrors(
              aggregatedUploadAnswerOptionImageErrorMessages[
                question.answer_options[answerIdx].id as number
              ]?.image,
            )}
          <FileUpload
            file={data.image instanceof File ? data.image : null}
            setFile={handleImageChange}
            inputId={randomId}
            aria-label="Upload image"
          />
          <div className="text-center">- or -</div>
          <Label value="Provide a link to the image" />
          {answerIdx !== undefined &&
            question.answer_options[answerIdx].id !== undefined &&
            displayErrors(
              aggregatedUploadAnswerOptionImageErrorMessages[
                question.answer_options[answerIdx].id as number
              ]?.external_image,
            )}
          <TextInput
            type="text"
            value={data.external_image || ""}
            name="external_image"
            onChange={handleExternalImageChange}
            aria-label="External image link"
          />
          {(data.image || data.external_image) && (
            <>
              <div>
                <Label value="Image preview" />
              </div>
              <div className="relative w-[64px] h-[64px]">
                <AnswerOptionImage answerOption={data} />
                <button
                  className="top-1 right-1 absolute"
                  onClick={clearImage}
                  aria-label="Clear image"
                >
                  <TrashIcon className="w-5 h-5 text-red-700" />
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
