import React from "react";
import { FeedItem } from "types/feed";

interface Props {
  feedItem: FeedItem | null;
}

/**
 * Clickable tabs for TMDBMovie component.
 */
export default function TMDBMovieRatings({ feedItem }: Props) {
  if (!feedItem?.ratings || feedItem?.ratings.length === 0) return null;

  return (
    <div>
      {feedItem.ratings.map((rating) => (
        <div key={rating.provider}>
          {rating.score}{" "}
          <a
            className="text-blue-600"
            href={rating.url}
            target="_blank"
            rel="noreferrer"
            aria-label={`View rating on ${rating.provider}`}
          >
            {rating.provider}
          </a>
        </div>
      ))}
    </div>
  );
}
