import React from "react";
import {
  QuizQuestionAnswerOption,
  QuizQuestionAnswerOptionFormData,
} from "types/quiz";
import NoImage from "images/No-Image.png";

interface Props {
  answerOption: QuizQuestionAnswerOption | QuizQuestionAnswerOptionFormData;
  additionalClassName?: string;
  hideIfNoImage?: boolean;
}

/**
 * Quiz question answer option image.
 */
export default function AnswerOptionImage({
  answerOption,
  additionalClassName = "",
  hideIfNoImage = false,
}: Props) {
  /**
   * Convert file to url.
   */
  function convertFileToUrl(file: File | string) {
    if (typeof file === "string") {
      return file;
    }
    return URL.createObjectURL(file);
  }

  /**
   * Generate image src.
   */
  function getImageSrc() {
    if (answerOption.image) {
      return convertFileToUrl(answerOption.image);
    } else if (answerOption.external_image) {
      return answerOption.external_image || undefined;
    } else {
      return NoImage;
    }
  }

  /**
   * Don't render if there is no image.
   */
  if (hideIfNoImage && !answerOption.external_image && !answerOption.image)
    return null;

  return (
    <img
      src={getImageSrc()}
      alt={answerOption.image ? "Quiz answer option image" : "No image available"}
      className={`object-cover inline w-[64px] h-[64px] rounded-full border-2 border-gray ${additionalClassName}`}
    />
  );
}
