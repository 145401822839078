import { QueryParams } from "types/api";
import { deleteRequest, get, patch, post } from "./apiService";
import {
  AddItemToPlaylistPayload,
  PlaylistPayload,
  RemoveItemFromPlaylistPayload,
  sharePlaylistsWithUsersPayload,
} from "types/playlist";

/**
 * Send a GET request to the API to retrieve a specific playlist
 */
export const retrievePlaylist = async ({ id }: { id: string }) => {
  return await get(`/api/playlists/${id}/`);
};

/**
 * Send a GET request to the API to retrieve playlists owned by the user
 */
export const retrieveMyPlaylists = async ({
  queryParams,
}: {
  queryParams?: QueryParams;
}) => {
  return await get("/api/playlists/my", undefined, undefined, queryParams);
};

/**
 * Send a GET request to the API to retrieve shared playlists
 */
export const retrieveSharedPlaylists = async ({
  queryParams,
}: {
  queryParams?: QueryParams;
}) => {
  return await get("/api/playlists/shared", undefined, undefined, queryParams);
};

/**
 * Send a POST request to the API to create a new playlist
 */
export const createPlaylist = async (body: PlaylistPayload) => {
  return await post("/api/playlists/", body);
};

/**
 * Send a PATCH request to the API to update a playlist
 */
export const patchPlaylist = async (id: string, body: PlaylistPayload) => {
  return await patch(`/api/playlists/${id}/`, body);
};

/**
 * Send a DELETE request to the API to delete a playlist
 */
export const deletePlaylist = async (id: string) => {
  return await deleteRequest(`/api/playlists/${id}/`);
};

/**
 * Send a POST request to the API to add an item to a playlist
 */
export const addItemToPlaylists = async (data: AddItemToPlaylistPayload) => {
  return await post("/api/playlists/add/", data);
};

/**
 * Send a POST request to the API to remove an item from a playlist
 */
export const removeItemFromPlaylist = async (
  data: RemoveItemFromPlaylistPayload,
) => {
  return await post("/api/playlists/remove/", data);
};

/**
 * Send a POST request to the API to share a playlist with users
 */
export const sharePlaylistsWithUsers = async (
  data: sharePlaylistsWithUsersPayload,
) => {
  return await post("/api/playlists/share/", data);
};
