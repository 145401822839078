import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ModalEditCreatePlaylist from "components/playlist/ModalEditCreatePlaylist";
import SelectPlaylistPopup from "components/playlist/SelectPlaylistPopup";
import PageWrapper from "components/common/pageWrapper";
import Track from "components/common/track";
import { isDispatchResponseError } from "redux/utils";
import { PlusIcon } from "@heroicons/react/24/solid";
import {
  FlagIcon,
  ForwardIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import MyPlaylistList from "components/playlist/MyPlaylistList";
import SharedPlaylistList from "components/playlist/SharedPlaylistList";
import Header from "components/common/header";
import { retrieveConnectedAdvertisers } from "redux/brainCoin/brainCoinSlice";
import AdByRandomProvider from "components/ads/AdByRandomProvider";
import { generateSpotifyUrl } from "helpers/spotify";
import {
  dislikeFeedItem,
  getDislikedFeedItems,
  getLikedFeedItems,
  getRandomFeedItem,
  increaseWatchedFor,
  likeFeedItem,
  skipFeedItem,
  watchFeedItem,
} from "redux/feed/feedItemsSlice";
import { FEED_ITEM_TYPES } from "types/feed";
import initiateSpotify from "helpers/spotifyIFrameAPI";
import { ReportFeedItemModal } from "components/report/ReportFeedItemModal";

/**
 * Music page.
 */
export default function Music() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const feedItem = useAppSelector((state) => state.feedItems.feedItem);
  const likedFeedItems = useAppSelector(
    (state) => state.feedItems.likedFeedItems,
  );
  const dislikedFeedItems = useAppSelector(
    (state) => state.feedItems.dislikedFeedItems,
  );
  const [showPlaylistPopup, setShowPlaylistPopup] = useState<boolean>(false);
  const [progressSeconds, setProgressSeconds] = useState(0);
  const [showSelectPlaylistPopup, setShowSelectPlaylistPopup] =
    useState<boolean>(false);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  let watchFeedItemTriggered = false;
  let prevPositionSeconds = 0;
  let prevProgressSeconds = 0;

  /**
   * Reloads the page.
   */
  function reloadPage() {
    // We need to refresh the page so that a new spotify player can be created
    window.location.reload();
  }

  /**
   * Mark feedItem as liked in database
   */
  const handleLikeFeedItem = async () => {
    const response = await dispatch(likeFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      reloadPage();
    }
  };

  /**
   * Mark feedItem as disliked in database
   */
  const handleDislikeFeedItem = async () => {
    const response = await dispatch(dislikeFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      reloadPage();
    }
  };

  /**
   * Mark feedItem as skipped in database
   */
  const handleSkipFeedItem = async () => {
    const response = await dispatch(skipFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      reloadPage();
    }
  };

  /**
   * Mark feedItem as watched in database
   */
  const handleWatchFeedItem = async () => {
    // handleWatchFeedItem is run whenever Spotify player changes its playback state.
    // Without the if statement here the backend would be flooded with requests to flag
    // the feedItem as watched
    if (!watchFeedItemTriggered) {
      await dispatch(watchFeedItem(feedItem?.id || ""));
      watchFeedItemTriggered = true;
    }
  };

  /**
   * Render a list of liked tracks.
   */
  const displayLikedFeedItems = () => {
    if (likedFeedItems.length > 0) {
      return likedFeedItems.map((likedFeedItem, idx) => (
        <li key={idx} className="pb-3 sm:py-4">
          <Track track={likedFeedItem} />
        </li>
      ));
    } else {
      return (
        <p className="py-4 text-gray-500 text-sm font-semibold">
          No liked tracks added yet!
        </p>
      );
    }
  };

  /**
   * Render a list of disliked tracks.
   */
  const displayDislikedFeedItems = () => {
    if (dislikedFeedItems.length > 0) {
      return dislikedFeedItems.map((dislikedFeedItem, idx) => (
        <li key={idx} className="pb-3 sm:py-4">
          <Track track={dislikedFeedItem} />
        </li>
      ));
    } else {
      return (
        <p className="py-4 text-gray-500 text-sm font-semibold">
          No disliked tracks added yet!
        </p>
      );
    }
  };

  /**
   * Update watched_for state in database.
   */
  function updateWatchedFor() {
    if (progressSeconds > 0 && feedItem) {
      dispatch(
        increaseWatchedFor({
          feed_item: feedItem.id,
          watched_for: progressSeconds,
          watched: true,
        }),
      );
      setProgressSeconds(0);
    }
  }

  /**
   * Retrieve data and set up Spotify iframe API.
   */
  useEffect(() => {
    document.title = "Music | BrainCargo";

    // Retrieve data
    dispatch(
      getRandomFeedItem({ queryParams: { types: FEED_ITEM_TYPES.TRACK } }),
    );
    dispatch(
      getLikedFeedItems({ queryParams: { types: FEED_ITEM_TYPES.TRACK } }),
    );
    dispatch(
      getDislikedFeedItems({ queryParams: { types: FEED_ITEM_TYPES.TRACK } }),
    );
    dispatch(retrieveConnectedAdvertisers());

    // Set up Spotify iframe API
    const script = document.createElement("script");
    script.src = "https://open.spotify.com/embed/iframe-api/v1";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  /**
   * Once a feedItem is loaded, set up Spotify player.
   */
  useEffect(() => {
    initiateSpotify(
      generateSpotifyUrl(feedItem?.spotify_track_id || ""),
      ({
        data,
      }: {
        data: {
          duration: number;
          isBuffering: boolean;
          isPaused: boolean;
          position: number;
        };
      }) => {
        if (!data.isPaused) {
          handleWatchFeedItem();
        }
        const positionSeconds = data.position / 1000;
        const newProgressSeconds = positionSeconds - prevPositionSeconds;
        if (newProgressSeconds > 0) {
          if (newProgressSeconds < 1) {
            // if newProgressSeconds >= 1 then we can assume that the user has seeked forward
            // We need to ignore that case
            setProgressSeconds(prevProgressSeconds + newProgressSeconds);
            prevProgressSeconds = prevProgressSeconds + newProgressSeconds;
          }
        }
        prevPositionSeconds = positionSeconds;
      },
    );
  }, [feedItem]);

  /**
   * Update watched_for state in database when user leaves the page.
   */
  useEffect(() => {
    const onUnload = function (e: BeforeUnloadEvent) {
      updateWatchedFor();
    };

    window.addEventListener("beforeunload", onUnload);
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [progressSeconds]);

  return (
    <PageWrapper>
      <div className="border shadow-md sm:rounded-lg bg-white" aria-label="Music Page">
        <Header title="Music" />

        {!user?.is_spotify_connected && (
          <p className="px-2 py-4 text-gray-500 text-sm font-semibold">
            Please connect your Spotify account on the profile page.
          </p>
        )}

        {user?.is_spotify_connected && (
          <>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 p-2 auto-rows-min">
              <div className="grid grid-cols-2 col-span-2 gap-2 auto-rows-min">
                <div className="col-span-2 justify-center items-center">
                  {feedItem ? (
                    <div id="embed-iframe" aria-label="Spotify Player"></div>
                  ) : (
                    <div className="text-center">
                      <p className="bg-gray-200 font-semibold p-6">
                        Tracks are not available at this time
                      </p>
                    </div>
                  )}
                </div>

                <div className="col-span-2 flex flex-col md:flex-row gap-2 w-full rounded">
                  <button
                    onClick={handleLikeFeedItem}
                    disabled={!feedItem}
                    className="flex p-2 bg-[#EBEDF0] font-semibold border rounded-lg justify-center"
                    aria-label="Like Track"
                  >
                    <HandThumbUpIcon className="w-6 h-6" />
                    &#160;Like
                  </button>
                  <button
                    onClick={handleDislikeFeedItem}
                    disabled={!feedItem}
                    className="flex p-2 bg-[#EBEDF0] font-semibold border rounded-lg justify-center"
                    aria-label="Dislike Track"
                  >
                    <HandThumbDownIcon className="w-6 h-6" />
                    &#160;Dislike
                  </button>
                  <button
                    onClick={handleSkipFeedItem}
                    disabled={!feedItem}
                    className="flex p-2 md:ml-auto bg-[#EBEDF0] font-semibold border rounded-lg justify-center"
                    aria-label="Skip Track"
                  >
                    <ForwardIcon className="w-6 h-6" />
                    &#160;Skip
                  </button>
                  <button
                    onClick={() => setShowReportModal(true)}
                    disabled={!feedItem}
                    className="flex p-2 bg-[#EBEDF0] font-semibold border rounded-lg justify-center"
                    aria-label="Report Track"
                  >
                    <FlagIcon className="w-6 h-6" />
                    &#160;Report
                  </button>
                  <button
                    onClick={() => setShowSelectPlaylistPopup(true)}
                    disabled={!feedItem}
                    className="flex p-2 bg-[#EBEDF0] font-semibold border rounded-lg justify-center"
                    aria-label="Add Track to Playlist"
                  >
                    <PlusIcon className="w-6 h-6" />
                    &#160;Add to Playlist
                  </button>
                </div>

                <AdByRandomProvider className="col-span-2 justify-center items-center" />

                <div className="border rounded col-span-2 xl:col-span-1">
                  <div className="bg-gray-100 p-4 border-b">
                    <span className="font-semibold">Liked Tracks</span>
                  </div>
                  <ul className="px-4 divide-y divide-gray-200 max-h-[40rem] overflow-auto">
                    {displayLikedFeedItems()}
                  </ul>
                </div>
                <div className="border rounded col-span-2 xl:col-span-1">
                  <div className="bg-gray-100 p-4 border-b">
                    <span className="font-semibold">Disliked Tracks</span>
                  </div>
                  <ul className="px-4 divide-y divide-gray-200 max-h-[40rem] overflow-auto">
                    {displayDislikedFeedItems()}
                  </ul>
                </div>
              </div>
              <div className="w-full">
                <div className="border rounded">
                  <MyPlaylistList smallHeader />
                  <SharedPlaylistList smallHeader />
                </div>
              </div>
            </div>

            <ModalEditCreatePlaylist
              show={showPlaylistPopup}
              onClose={() => setShowPlaylistPopup(false)}
            />
            {showSelectPlaylistPopup && feedItem && (
              <SelectPlaylistPopup
                feedItem={feedItem}
                show={showSelectPlaylistPopup}
                onClose={() => setShowSelectPlaylistPopup(false)}
                headerText="Add track to playlist"
              />
            )}

            {feedItem && (
              <ReportFeedItemModal
                show={showReportModal}
                setShow={setShowReportModal}
                feedItem={feedItem}
              />
            )}
          </>
        )}
      </div>
    </PageWrapper>
  );
}
