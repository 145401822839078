import React, { useEffect, useState } from "react";
import { Label, TextInput, ToggleSwitch } from "flowbite-react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { displayErrors } from "helpers/errors";
import {
  addItemToPlaylists,
  addPlaylist,
  getMyPlaylists,
  getPlaylist,
  updatePlaylist,
} from "redux/playlist/playlistSlice";
import { isDispatchResponseError } from "redux/utils";
import Modal from "components/common/modal";
import { PayloadAction } from "@reduxjs/toolkit";
import { useDebounce } from "use-debounce";
import { fetchUsers } from "redux/users/usersSlice";
import { User } from "types/user";
import { FeedItem } from "types/feed";
import {
  AddItemToPlaylistPayload,
  Playlist,
  PlaylistPayload,
} from "types/playlist";

interface Props {
  show: boolean;
  onClose: () => void;
  playlistId?: string;
  itemId?: FeedItem["id"];
}

export default function ModalEditCreatePlaylist({
  show,
  onClose,
  playlistId,
  itemId,
}: Props) {
  const dispatch = useAppDispatch();
  const users = useAppSelector((state) => state.users.users);
  const addPlaylistErrors = useAppSelector(
    (state) => state.playlist.addPlaylistErrors,
  );
  const updatePlaylistErrors = useAppSelector(
    (state) => state.playlist.updatePlaylistErrors,
  );
  const playlist = useAppSelector((state) => state.playlist.playlist);
  const sharePlaylistsWithUsersErrors = useAppSelector(
    (state) => state.playlist.sharePlaylistsWithUsersErrors,
  );
  const [name, setName] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
  const [selectedUserIds, setSelectedUserIds] = useState<Array<User["id"]>>([]);
  const [sharedChecked, setSharedChecked] = useState(false);

  /**
   * Update or create a playlist.
   */
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const payloadEditCreatePlaylist: PlaylistPayload = {
      name,
    };

    let shared_with: { id: string }[] = [];
    if (sharedChecked) {
      shared_with = selectedUserIds.map((item) => ({ id: item }));
    }
    payloadEditCreatePlaylist.shared_with = shared_with;

    let response: PayloadAction<any> | undefined;
    if (playlistId) {
      response = await dispatch(
        updatePlaylist({
          playlistId,
          data: payloadEditCreatePlaylist,
        }),
      );
    } else {
      response = await dispatch(addPlaylist(payloadEditCreatePlaylist));
    }

    if (!isDispatchResponseError(response)) {
      if (itemId) {
        // Add item to playlist
        const editedOrCreatedPlaylist = response.payload as Playlist;
        const addItemToPlaylistPayload: AddItemToPlaylistPayload = {
          playlist_ids: [editedOrCreatedPlaylist.id],
          feed_item_id: itemId,
        };

        const addItemToPlaylistsResponse = await dispatch(
          addItemToPlaylists(addItemToPlaylistPayload),
        );
        if (!isDispatchResponseError(addItemToPlaylistsResponse)) {
          await dispatch(getMyPlaylists({ queryParams: { page_size: "100" } }));
          setName("");
          setSharedChecked(false);
          setSelectedUserIds([]);
          onClose();
        }
      } else {
        await dispatch(getMyPlaylists({ queryParams: { page_size: "100" } }));
        setName("");
        setSharedChecked(false);
        setSelectedUserIds([]);
        onClose();
      }
    }
  };

  /**
   * Update selected users whom the playlist is to be shared with.
   */
  const handleChangeSelectedUsers = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedUserId = e.target.value;
    const checked = e.target.checked;
    let newSelectedUserIds = [...selectedUserIds];
    if (checked) {
      newSelectedUserIds = [...newSelectedUserIds, selectedUserId];
    } else {
      newSelectedUserIds = newSelectedUserIds.filter(
        (item) => item !== selectedUserId,
      );
    }
    setSelectedUserIds(newSelectedUserIds);
  };

  /**
   * Render table rows containing users.
   */
  const displayUsers = () => {
    return users.map((user, idx) => (
      <tr key={idx} className="border-b border-gray-200">
        <td className="flex items-center px-6 py-4 bg-gray-50">
          <input
            id={user.id}
            name="video_playlists"
            type="checkbox"
            value={user.id}
            onChange={(e) => handleChangeSelectedUsers(e)}
            checked={selectedUserIds.includes(user.id)}
            className="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-0"
            aria-label={`Select user ${user.username}`}
          />
          <label htmlFor={user.id}>{user.username}</label>
        </td>
      </tr>
    ));
  };

  /**
   * Fetch playlist if editing an existing playlist.
   */
  useEffect(() => {
    if (playlistId) {
      dispatch(getPlaylist({ id: playlistId }));
    }
  }, [playlistId]);

  /**
   * Prepopulate form fields if editing an existing playlist.
   */
  useEffect(() => {
    if (playlist) {
      setName(playlist.name);
      if (playlist.shared_with) {
        setSharedChecked(true);
      } else {
        setSharedChecked(false);
      }
      setSelectedUserIds(playlist.shared_with.map((item) => item.id));
    }
  }, [playlist]);

  /**
   * Fetch users based on search query.
   */
  useEffect(() => {
    dispatch(fetchUsers(debouncedSearchQuery));
  }, [debouncedSearchQuery]);

  return (
    <React.Fragment>
      <Modal
        show={show}
        onClose={onClose}
        onSubmit={handleSubmit}
        submitButtonDisabled={!name}
        headerText={playlistId ? "Edit Playlist" : "Create Playlist"}
        body={
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <Label htmlFor="name" value="Playlist name" />
              <TextInput
                id="playlist_name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="My Favorite Playlist"
                aria-label="Playlist name"
              />
              {displayErrors(addPlaylistErrors.name)}
              {displayErrors(updatePlaylistErrors.name)}
            </div>
            <div className="flex flex-row items-center">
              <div>
                <div className="text-sm font-medium text-gray-900">Shared</div>
                <div className="text-xs font-medium text-gray-400">
                  Allow selected users to view this collection
                </div>
              </div>
              <ToggleSwitch
                label=""
                name="name"
                checked={sharedChecked}
                onChange={(checked) => {
                  setSharedChecked(checked);
                }}
                className="ml-auto"
                aria-label="Toggle shared playlist"
              />
            </div>
            {sharedChecked && (
              <>
                <div>
                  <TextInput
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    aria-label="Search users"
                  />
                </div>
                <div className="border rounded max-h-[20rem] overflow-y-auto">
                  <table className="w-full text-sm text-left">
                    <thead className="text-xs text-gray-700 uppercase border-b sticky top-0">
                      {/* boxShadow property is needed here for a border to be present in sticky table header */}
                      <tr style={{ boxShadow: "inset 0 0 0 1px #E5E7EB" }}>
                        <th scope="col" className="px-6 py-3 bg-gray-50">
                          Name
                        </th>
                      </tr>
                    </thead>
                    <tbody>{displayUsers()}</tbody>
                  </table>
                </div>
                {displayErrors(sharePlaylistsWithUsersErrors.user_id)}
              </>
            )}
          </form>
        }
      ></Modal>
    </React.Fragment>
  );
}
