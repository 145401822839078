import React, { useEffect } from "react";

interface Props {
  dataAdSlot?: string;
  className?: string;
}

/**
 * Component for displaying a medium size AdSense advert.
 */
export default function AdSenseAdvertMd({
  dataAdSlot = process.env.REACT_APP_GOOGLE_ADSENSE_MD_AD_SLOT,
  className = "",
}: Props) {
  useEffect(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
      // Do nothing
    }
  }, []);

  return (
    <ins
      className={`adsbygoogle ${className}`}
      style={{ display: "block" }}
      data-ad-format="fluid"
      data-ad-client="ca-pub-5097091517177748"
      data-ad-layout-key="-fb+5w+4e-db+86"
      data-ad-slot={dataAdSlot}
    ></ins>
  );
}
