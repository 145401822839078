/**
 * Format a date string to a human readable format
 */
export function formatDate(val: string) {
  if (!val) return "-";
  const date = new Date(val);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return date.toLocaleDateString(undefined, options);
}

/**
 * Format a date string to a human readable format(including time)
 */
export function formatDateTime(val: string) {
  if (!val) return "-";
  const date = new Date(val);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return date.toLocaleDateString(undefined, options);
}

/**
 * Format a number of seconds to a human readable format
 */
export function formatSeconds(val: number | null) {
  if (!val) return "-";
  const hours = Math.floor(val / 3600);
  const minutes = Math.floor((val / 60) % 60);
  const seconds = Math.round(val % 60);

  const timeString = `${hours}h:${minutes}m:${seconds}s`;

  return timeString;
}
