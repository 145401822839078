import React, { useState } from "react";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import { generateLoginOTP, verifyOTP } from "redux/auth/authSlice";
import { toast } from "react-toastify";
import TextInput from "../../components/common/form/TextInput";
import "react-international-phone/style.css";
import { setTokens } from "../../services/localStorage";
import { history } from "../../helpers/history";
import { APP_URLS } from "../../navigation";
import CustomPhoneInput from "../../components/common/form/CustomPhoneInput";

/**
 * Login page.
 */

interface OTPLoginProps {
  email?: string;
  phone?: string;
  isSignInFieldEmail?: boolean;
  otpToken?: string;
}

const OTPLogin: React.FC<OTPLoginProps> = ({
  email = "",
  phone = "",
  isSignInFieldEmail = false,
  otpToken = "",
}) => {
  const dispatch = useAppDispatch();

  const verifyOTPErrorMessages = useAppSelector(
    (state) => state.auth.verifyOTPErrorMessages,
  );

  const [code, setCode] = useState("");
  const [OTPToken, setOTPToken] = useState(otpToken);
  /**
   * Log the user in.
   */

  const handleCodeVerify = async () => {
    try {
      const payload = isSignInFieldEmail
        ? { email, otp: code , ...(OTPToken && { token: OTPToken })}
        : { phone, otp: code , ...(OTPToken && { token: OTPToken })};
      const response = await toast.promise(
        dispatch(verifyOTP(payload)).unwrap(),
        {
          pending: {
            render({ data }: any) {
              return data?.detail || "Logging In";
            },
          },
          success: {
            render({ data }: any) {
              return data?.detail || "Logged In Successfully";
            },
          },
          error: {
            render({ data }: any) {
              return data?.detail || "Login Failed";
            },
          },
        },
        {
          autoClose: 5000,
        },
      );
      if (response) {
        const { access, refresh } = response;
        setTokens({ access, refresh });
        history.navigate(APP_URLS.VIDEOS);
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const handleRequestCode = async () => {
    try {
      const payload = isSignInFieldEmail ? { email } : { phone };
      const response = await toast.promise(
        dispatch(generateLoginOTP(payload)).unwrap(),
        {
          pending: "Sending OTP...",
          success: {
            render({ data }: any) {
              return data?.message || "OTP Sent";
            },
          },
          error: {
            render({ data }: any) {
              return data?.detail || "Login Failed";
            },
          },
        },
        {
          autoClose: 5000,
        },
      );
      if (response.status === 201) {
        setOTPToken(response.token || "");
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleCodeVerify();
  };

  return (
    <form onSubmit={handleSubmit} aria-label="OTP Login Form">
      <div className="mt-6">
        <>
          {isSignInFieldEmail ? (
            <TextInput
              value={email}
              label="Email"
              id="email"
              type="text"
              required
              readOnly={true}
              placeholder="Email"
              aria-label="Email"
            />
          ) : (
            <CustomPhoneInput
              value={phone}
              label="Phone Number"
              id="phone"
              type="text"
              required
              readOnly={true}
              placeholder="Phone"
              aria-label="Phone Number"
            />
          )}

          <TextInput
            value={code}
            label="Enter Verification Code"
            id="code"
            type="number"
            required
            onChange={(event) => setCode(event.target.value)}
            placeholder="Enter OTP Here"
            aria-label="Enter Verification Code"
            errorMessages={verifyOTPErrorMessages?.detail || verifyOTPErrorMessages.otp}
          />
          <div
            onClick={() => {
              handleRequestCode();
            }}
            className="flex justify-start py-2 text-sm text-white hover:underline"
            role="button"
            aria-label="Resend OTP"
          >
            Resend OTP
          </div>
        </>
        <div className="mt-6 flex justify-center">
          <button
            type="submit"
            className="w-full px-4 py-3 tracking-wide text-white transition-colors border border-transparent duration-200 transform bg-[linear-gradient(95.49deg,#5d3ef8_-12.23%,#ba4cd6_117.24%)] rounded-full hover:bg-transparent hover:border-[#cec0f3] focus:outline-none focus:bg-blue-600"
            aria-label={isSignInFieldEmail ? "Login" : "Continue"}
          >
            {isSignInFieldEmail ? "Login" : "Continue"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default OTPLogin;
