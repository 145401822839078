/**
 * Handlers for the touch events on the playlists drawer
 */
import {
  PLAYLISTS_DRAWER_FULL_SCREEN_THRESHOLD,
  PLAYLISTS_DRAWER_HIDE_THRESHOLD,
  disableTransition,
  enableTransition,
  getComponentHeight,
  setPlaylistFullScreen,
  setPlaylistHidden,
} from "./utils";

/**
 * Handles the touch start event on the playlists drawer
 */
export function handleTouchStart({
  e,
  startY,
  currY,
  ref,
}: {
  e: TouchEvent;
  startY: number;
  currY: number;
  ref: React.MutableRefObject<HTMLDivElement | null>;
}) {
  // Prepare variables used by handleTouchEnd, handleTouchMove & holdInterval
  startY = e.targetTouches[0].pageY;
  currY = e.targetTouches[0].pageY;

  // We need to disable css transition when dragging the element, otherwise
  // it will interfere with the item's positioning
  disableTransition({
    ref,
  });

  return { startY, currY };
}

/**
 * Handles the touch move event on the playlists drawer
 */
export function handleTouchMove({
  e,
  currY,
  ref,
}: {
  e: TouchEvent;
  currY: number;
  ref: React.MutableRefObject<HTMLDivElement | null>;
}) {
  currY = e.targetTouches[0].pageY;
  if (ref.current && currY !== 0) {
    ref.current.style.top = currY + "px";
  }

  return { currY };
}

/**
 * Handles the touch end event on the playlists drawer
 */
export function handleTouchEnd({
  currY,
  startY,
  ref,
  setShow,
}: {
  currY: number;
  startY: number;
  ref: React.MutableRefObject<HTMLDivElement | null>;
  setShow: (val: boolean) => void;
}) {
  // Trigger actions only if the item was actually dragged
  if (currY === startY) {
    return;
  }

  if (ref.current) {
    // We need to enable css transition after dragging the element, otherwise
    // it will interfere with the item's positioning
    enableTransition({ ref });

    const componentHeight = getComponentHeight();

    const fullScreenYThreshold = {
      top: 0,
      bottom: Math.floor(
        componentHeight * PLAYLISTS_DRAWER_FULL_SCREEN_THRESHOLD,
      ),
    };

    const hideYThreshold = {
      top: componentHeight - componentHeight * PLAYLISTS_DRAWER_HIDE_THRESHOLD,
      bottom: componentHeight,
    };

    if (
      currY > fullScreenYThreshold.top &&
      currY < fullScreenYThreshold.bottom
    ) {
      setPlaylistFullScreen(ref);
    }

    if (currY > hideYThreshold.top && currY < hideYThreshold.bottom) {
      setPlaylistHidden(ref);
      setShow(false);
    }
  }
}
