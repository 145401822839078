import { QueryParams } from "types/api";
import { get, post } from "./apiService";
import { Feedback } from "types/feedback";

/**
 * Send a POST request to the API to create a new feedback
 */
export const createFeedback = async (data: FormData) => {
  return await post("/api/feedback/", data, false, "multipart/form-data");
};

/**
 * Send a GET request to the API to retrieve feedbacks
 */
export const retrieveFeedbacks = async ({
  queryParams,
}: {
  queryParams?: QueryParams;
}) => {
  return await get("/api/feedback/", undefined, undefined, queryParams);
};

/**
 * Send a GET request to the API to retrieve a specific feedback
 */
export const retrieveFeedback = async (id: Feedback["id"]) => {
  return await get("/api/feedback/" + id + "/");
};
