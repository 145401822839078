import React, { useEffect, useState } from "react";
import { history } from "helpers/history";
import QuizForm from "components/quiz/form/take-quiz/QuizForm";
import { APP_URLS } from "navigation";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { retrieveQuizzes } from "redux/quiz/quizSlice";
import { Quiz } from "types/quiz";

/**
 * Main Quiz page
 */
export default function MainQuiz() {
  const dispatch = useAppDispatch();
  const quizzes = useAppSelector((store) => store.quiz.quizzes);
  const [quiz, setQuiz] = useState<Quiz | null>(null);
  const [quizName, setQuizName] = useState<string>("Quiz");

  /**
   * Skip this step and navigate to Videos page
   */
  const onSkipClick = () => {
    history.navigate(APP_URLS.VIDEOS);
  };

  /**
   * Redirect user to Videos page after successful quiz submission after 5 seconds
   */
  function onSuccess() {
    setTimeout(() => history.navigate(APP_URLS.VIDEOS), 5000);
  }

  /**
   * Retrieve main quiz
   */
  useEffect(() => {
    dispatch(retrieveQuizzes({ queryParams: { main_quiz: "true" } }));
  }, []);

  /**
   * Extract quiz data
   */
  useEffect(() => {
    if (quizzes.results.length > 0) {
      document.title = `${quizName} | BrainCargo`;
      setQuiz({ ...quizzes.results[0] });
      setQuizName(quiz?.name || quizName);
    } else {
      setQuiz(null);
    }
  }, [quizzes]);

  const successText = (
    <>
      Thank you for taking part in the quiz!
      <br />
      You will be redirected to Home screen in 5 seconds.
    </>
  );

  return (
    <div className="flex flex-col justify-center sm:h-screen">
      <div className="relative overflow-x-auto border shadow-md sm:rounded-lg bg-white">
        <div className="flex flex-col sm:flex-row items-center px-6 py-4 border-b">
          <div className="flex flex-col">
            <span className="text-lg font-semibold text-gray-800 capitalize">
              {quizName}
            </span>
            <span className="text-sm text-gray-500">
              Please answer the following questions to let us get to know you
              better.
            </span>
          </div>

          <button
            onClick={onSkipClick}
            className="mx-0 sm:ml-16 sm:mr-4 order-2 sm:order-1 flex py-2 text-sm font-semibold text-center text-gray-500 hover:text-gray-600 hover:underline"
            aria-label="Skip this step"
          >
            Skip This Step
          </button>
        </div>

        <QuizForm onSuccess={onSuccess} successText={successText} quiz={quiz} />
      </div>
    </div>
  );
}
