import {
  AuthTokens,
  checkEmailPayload,
  checkEmailResponse,
  GoogleLoginPayload,
  LoginPayload,
  OTPGenerateResponse,
  PhoneGeneratePayload,
  PhoneRegisterPayload,
  PhoneVerificationPayload,
  RegisterMessageResponse,
  RegisterPayload,
  RegisterResponse,
  resetEmailPayload,
  VerifyEmailResponse,
} from "types/auth";
import { post } from "./apiService";

/**
 * Send a POST request to the API to register a new user
 */
export const register = async (
  data: RegisterPayload,
): Promise<RegisterResponse> => {
  return await post("/api/register/", data, true);
};

export const registerPhone = async (
  data: PhoneRegisterPayload,
): Promise<RegisterMessageResponse> => {
  return await post("/api/register/register-phone", data, true);
};

/**
 * Send a POST request to the API to log a user in
 */
export const login = async (data: LoginPayload): Promise<AuthTokens> => {
  return await post("/api/token/", data, true);
};


export const googleLogin = async (data: GoogleLoginPayload): Promise<RegisterResponse> => {
  return await post("/api/register/google/", data, true);
};

export const verifyEmail = async (data: { key: string }): Promise<VerifyEmailResponse> => {
  return await post("/api/register/verify-email/", data, true);
};


export const verifyPhoneOTP = async (data: PhoneVerificationPayload): Promise<RegisterMessageResponse> => {
  return await post("/api/accounts/verify-phone/", data, true);
};

export const verifyOTP = async (data: PhoneVerificationPayload): Promise<AuthTokens> => {
  return await post("/api/accounts/verify-otp/", data, true);
};


export const generateLoginOTP = async (data: PhoneGeneratePayload): Promise<OTPGenerateResponse> => {
  return await post("/api/register/generate-login-otp", data, true);
};

export const checkEmail = async (data: checkEmailPayload): Promise<checkEmailResponse> => {
  return await post("/api/register/check-email/", data, true);
};

export const forgotEmailPassword = async (data: checkEmailPayload): Promise<checkEmailResponse> => {
  return await post("/api/accounts/password/reset/", data, true);
};

export const resetEmailPassword = async (data: resetEmailPayload): Promise<checkEmailResponse> => {
  return await post("/api/accounts/password/reset/confirm/", data, true);
};
