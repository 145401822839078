import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createNeuralNetwork,
  removeNeuralNetwork,
  retrieveNeuralNetworkCategories,
  retrieveNeuralNetworks,
  selectNeuralNetworks as selectNeuralNetworksService,
} from "services/neuralNetworkService";
import { QueryParams } from "types/api";
import {
  NeuralNetwork,
  NeuralNetworkCategoryList,
  NeuralNetworkList,
} from "types/neuralNetwork";
import { ErrorMessages } from "types/redux/slice";

interface NeuralNetworksState {
  neuralNetworks: NeuralNetworkList;
  neuralNetworkCategories: NeuralNetworkCategoryList;
  pendingGetNeuralNetworkCategories: boolean;
  pendingGetNeuralNetworks: boolean;
  pendingSelectNeuralNetworks: boolean;
  pendingAddNeuralNetwork: boolean;
  pendingDeleteNeuralNetwork: boolean;
  errorMessages: ErrorMessages;
}

const initialState: NeuralNetworksState = {
  neuralNetworks: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  neuralNetworkCategories: [],
  pendingGetNeuralNetworkCategories: false,
  pendingGetNeuralNetworks: false,
  pendingSelectNeuralNetworks: false,
  pendingAddNeuralNetwork: false,
  pendingDeleteNeuralNetwork: false,
  errorMessages: {},
};

export const neuralNetworksSlice = createSlice({
  name: "neuralNetworks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNeuralNetworks.pending, (state, action) => {
      state.errorMessages = {};
      state.pendingGetNeuralNetworks = true;
    });
    builder.addCase(getNeuralNetworks.fulfilled, (state, action) => {
      state.neuralNetworks = action.payload;
      state.pendingGetNeuralNetworks = false;
    });
    builder.addCase(getNeuralNetworks.rejected, (state, action) => {
      state.errorMessages = action.payload || {};
      state.pendingGetNeuralNetworks = false;
    });

    builder.addCase(getNeuralNetworkCategories.pending, (state, action) => {
      state.errorMessages = {};
      state.pendingGetNeuralNetworkCategories = true;
    });
    builder.addCase(getNeuralNetworkCategories.fulfilled, (state, action) => {
      state.neuralNetworkCategories = action.payload;
      state.pendingGetNeuralNetworkCategories = false;
    });
    builder.addCase(getNeuralNetworkCategories.rejected, (state, action) => {
      state.errorMessages = action.payload || {};
      state.pendingGetNeuralNetworkCategories = false;
    });

    builder.addCase(addNeuralNetwork.pending, (state, action) => {
      state.errorMessages = {};
      state.pendingAddNeuralNetwork = true;
    });
    builder.addCase(addNeuralNetwork.fulfilled, (state, action) => {
      state.pendingAddNeuralNetwork = false;
    });
    builder.addCase(addNeuralNetwork.rejected, (state, action) => {
      state.errorMessages = action.payload || {};
      state.pendingAddNeuralNetwork = false;
    });

    builder.addCase(selectNeuralNetworks.pending, (state, action) => {
      state.errorMessages = {};
      state.pendingSelectNeuralNetworks = true;
    });
    builder.addCase(selectNeuralNetworks.fulfilled, (state, action) => {
      state.pendingSelectNeuralNetworks = false;
    });
    builder.addCase(selectNeuralNetworks.rejected, (state, action) => {
      state.errorMessages = action.payload || {};
      state.pendingSelectNeuralNetworks = false;
    });

    builder.addCase(deleteNeuralNetwork.pending, (state, action) => {
      state.errorMessages = {};
      state.pendingDeleteNeuralNetwork = true;
    });
    builder.addCase(deleteNeuralNetwork.fulfilled, (state, action) => {
      state.pendingDeleteNeuralNetwork = false;
    });
    builder.addCase(deleteNeuralNetwork.rejected, (state, action) => {
      state.errorMessages = action.payload || {};
      state.pendingDeleteNeuralNetwork = false;
    });
  },
});

export const getNeuralNetworks = createAsyncThunk<
  NeuralNetworkList,
  { url?: string | null; queryParams?: QueryParams } | undefined,
  { rejectValue: ErrorMessages }
>("neuralNetworks/getNeuralNetworks", async (data, thunkApi) => {
  try {
    const response = await retrieveNeuralNetworks(data?.url, data?.queryParams);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const getNeuralNetworkCategories = createAsyncThunk<
  NeuralNetworkCategoryList,
  void,
  { rejectValue: ErrorMessages }
>("neuralNetworks/getNeuralNetworkCategories", async (data, thunkApi) => {
  try {
    const response = await retrieveNeuralNetworkCategories();
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const addNeuralNetwork = createAsyncThunk<
  NeuralNetwork,
  FormData,
  { rejectValue: ErrorMessages }
>("neuralNetworks/addNeuralNetwork", async (data, thunkApi) => {
  try {
    const response = await createNeuralNetwork(data);

    // Refresh neural networks list
    thunkApi.dispatch(getNeuralNetworks());
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const selectNeuralNetworks = createAsyncThunk<
  { neural_networks: string[] },
  { data: { neural_networks: string[] } },
  { rejectValue: ErrorMessages }
>("neuralNetworks/selectNeuralNetworks", async ({ data }, thunkApi) => {
  try {
    const response = await selectNeuralNetworksService(data);
    // Refresh neural networks list
    thunkApi.dispatch(getNeuralNetworks());
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const deleteNeuralNetwork = createAsyncThunk<
  string, // empty string
  string,
  { rejectValue: ErrorMessages }
>("neuralNetworks/deleteNeuralNetwork", async (data, thunkApi) => {
  try {
    const response = await removeNeuralNetwork(data);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});
