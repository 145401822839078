import { UserFeedData, UserRestricted } from "types/user";
import { get } from "./apiService";
import { PaginatedList, QueryParams } from "types/api";

/**
 * Send a GET request to the API to retrieve a list of all users of the app(excluding current user)
 */
export const fetchUsers = async (query = ""): Promise<UserRestricted[]> => {
  return await get("/api/accounts/", undefined, undefined, { query });
};

/**
 * Send a GET request to the API to retrieve a list of all users of the app & all available feed data related to them
 */
export const fetchUsersFeedData = async ({
  queryParams,
}: {
  queryParams?: QueryParams;
}): Promise<PaginatedList<UserFeedData>> => {
  return await get(
    "/api/accounts/feed_data/",
    undefined,
    undefined,
    queryParams,
  );
};

/**
 * Send a GET request to the API to retrieve a list of all users of the app & all available feed data related to them
 */
export const fetchUserFeedData = async ({
  userId,
}: {
  userId: string;
}): Promise<UserFeedData> => {
  return await get(`/api/accounts/${userId}/feed_data/`);
};
