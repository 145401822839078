import React, {useEffect} from "react";
import PageWrapper from "components/common/pageWrapper";
import Header from "components/common/header";
import MoreInfo from "components/common/moreInfo";
import ConnectedAdvertisersAccordion from "components/brainCoin/ConnectedAdvertisersAccordion";
import SequenceWallet from "components/brainCoin/SequenceWallet";

/**
 * Brain coin page.
 */
export default function BrainCoin() {

  useEffect(() => {
    document.title = "Brain Coin | BrainCargo";
  }, []);

  return (
    <PageWrapper>
      <div className="border shadow-md lg:rounded-lg bg-white pb-6 space-y-2">
        <Header title="Wallet" />
        <div
          className={
            "flex flex-col justify-center items-center px-4 space-y-2 w-full"
          }
        >
          <div className={"w-full sm:w-1/2"}>
            <SequenceWallet />
          </div>
        </div>

        <Header
          title={
            <>
              Earn{" "}
              <MoreInfo
                text={
                  "Select advertisers you wish to allow to put up ads on your feed.\n\nPlease note that selected advertisers will gain access to your data."
                }
              />
            </>
          }
          isSubHeader
        />
        <div
          className={
            "flex flex-col justify-center items-center px-4 space-y-2 w-full"
          }
        >
          <div className={"w-full sm:w-1/2"}>
            <ConnectedAdvertisersAccordion />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
