import { Location, NavigateFunction } from "react-router-dom";

interface History {
  navigate: NavigateFunction;
  location?: Location;
}

/**
 * Custom history object to allow navigation outside react components
 */
export const history: History = {
  navigate: () => null,
  location: undefined,
};
