import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import PaginationV2 from "components/common/pagination/PaginationV2";
import SearchInput from "components/common/searchInput";
import { Spinner } from "flowbite-react";
import { formatDateTime } from "helpers/date";
import { APP_URLS } from "navigation";
import React, { useEffect, useState } from "react";
import { retrieveFeedbacks } from "redux/feedback/feedbackSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { QueryParams } from "types/api";
import { FEEDBACK_TYPE_LABELS } from "types/feedback";
import { useDebounce } from "use-debounce";

const DEFAULT_ORDERING = "-created_at";

/**
 * Labels for the table headers.
 */
const LABELS = {
  created_at: "Creation date",
  user: "Reported by",
  type: "Type",
  description: "Description",
};

/**
 * Component that renders a table of feedbacks.
 */
export default function FeedbackListTable() {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [ordering, setOrdering] = useState(DEFAULT_ORDERING);
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 500);
  const pendingGetFeedbacks = useAppSelector(
    (state) => state.feedback.pendingGetFeedbacks,
  );
  const feedbacks = useAppSelector((state) => state.feedback.feedbacks);

  /**
   * Renders a table header.
   */
  function renderTableHeader({ key, label }: { key: string; label: string }) {
    let icon = null;
    if (ordering === key) {
      icon = <ChevronDownIcon className="w-4" />;
    }
    if (ordering === "-" + key) {
      icon = <ChevronUpIcon className="w-4" />;
    }

    function onClickHeader() {
      if (ordering === key) {
        setOrdering("-" + ordering);
      } else if (ordering === "-" + key) {
        setOrdering(ordering.replaceAll("-", ""));
      } else {
        setOrdering(key);
      }
    }

    return (
      <th className="p-2" key={key}>
        <button
          onClick={onClickHeader}
          className="flex flex-row gap-1"
          aria-label={`Sort by ${label}`}
        >
          {icon}
          {label}
        </button>
      </th>
    );
  }

  /**
   * Trims the description to 50 characters.
   */
  function trimDescription(description: string) {
    if (description.length > 50) {
      return description.substring(0, 50) + "...";
    } else {
      return description;
    }
  }

  /**
   * Fetches feedbacks.
   */
  function fetchData(page: number, query: string) {
    const queryParams: QueryParams = {
      page: page.toString(),
      page_size: pageSize.toString(),
      query,
      ordering,
    };
    dispatch(retrieveFeedbacks({ queryParams }));
  }

  /**
   * Redirects to the feedback details page.
   */
  function onClickRow(feedbackId: string) {
    const url = APP_URLS.FEEDBACK_DETAILS.replace(":id", feedbackId);
    window.location.href = url;
  }

  /**
   * Fetches feedbacks whenever page/ordering/search query changes.
   */
  useEffect(() => {
    fetchData(page, debouncedQuery);
  }, [page, debouncedQuery, ordering]);

  return (
    <div>
      <div className="pb-2">
        <SearchInput value={query} onChange={setQuery} />
      </div>
      {pendingGetFeedbacks ? (
        <div className="flex justify-center items-center">
          <Spinner aria-label="Loading feedbacks" />
        </div>
      ) : (
        <div className="border rounded overflow-y-auto">
          <table className="w-full text-sm text-left bg-white">
            <thead className="text-xs text-gray-700 uppercase border-b sticky top-0">
              <tr className="bg-gray-50">
                {Object.keys(LABELS).map((key) =>
                  renderTableHeader({
                    key,
                    label: LABELS[key as keyof typeof LABELS],
                  }),
                )}
              </tr>
            </thead>
            <tbody>
              {feedbacks.results.map((elem) => {
                return (
                  <tr
                    key={elem.id}
                    className="border-b border-gray-200 hover:cursor-pointer hover:bg-gray-50"
                    onClick={() => onClickRow(elem.id)}
                    tabIndex={0}
                    aria-label={`Feedback from ${elem.user.username} on ${formatDateTime(elem.created_at)}`}
                  >
                    <td className="p-2">{formatDateTime(elem.created_at)}</td>
                    <td className="p-2">{elem.user.username}</td>
                    <td className="p-2">{FEEDBACK_TYPE_LABELS[elem.type]}</td>
                    <td className="p-2">{trimDescription(elem.description)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <PaginationV2
        count={feedbacks.count}
        page={page}
        previous={feedbacks.previous}
        next={feedbacks.next}
        onClickPrev={() => setPage(page - 1)}
        onClickNext={() => setPage(page + 1)}
        pageSize={pageSize}
      />
    </div>
  );
}
