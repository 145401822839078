import React, {useState} from "react";
import {ShareIcon} from "@heroicons/react/24/outline";
import SharePlaylistsPopup from "./SharePlaylistsPopup";

interface Props {
  additionalClasses?: string;
}

/**
 * Component used for sharing playlists with other users.
 */
export default function ButtonSharePlaylists({additionalClasses = ""}: Props) {
  const [showPopup, setShowPopup] = useState<boolean>(false);

  return <>
    <button
      onClick={() => setShowPopup(true)}
      className={`flex p-2 text-center text-blue-600 bg-white border-[1.5px] border-blue-600 rounded-lg justify-center items-center ${additionalClasses}`}
      aria-label="Share Playlists"
    >
      <ShareIcon className="w-5 h-5 mr-2" />
      <span className="text-sm font-semibold">
        Share Playlists
      </span>
    </button>

    <SharePlaylistsPopup show={showPopup} onClose={() => setShowPopup(false)} />
  </>;
}
