import { QueryParams } from "types/api";
import { API_BASE_URL, deleteRequest, get, post } from "./apiService";

/**
 * Send a GET request to the API to retrieve neural networks
 */
export const retrieveNeuralNetworks = async (
  pageUrl?: string | null,
  queryParams?: QueryParams,
) => {
  // Determine Neural Networks list endpoint URL
  const url = pageUrl
    ? pageUrl.replace(API_BASE_URL, "")
    : "/api/neural_networks/";

  return await get(url, undefined, undefined, queryParams);
};

/**
 * Send a GET request to the API to retrieve neural network categories
 */
export const retrieveNeuralNetworkCategories = async () => {
  // Send a http request to API with username and password values
  return await get("/api/neural_networks/categories/");
};

/**
 * Send a POST request to the API to create a new neural network
 */
export const createNeuralNetwork = async (data: FormData) => {
  // Send a http request to API with registration data
  return await post(
    "/api/neural_networks/",
    data,
    false,
    "multipart/form-data",
  );
};

/**
 * Send a DELETE request to the API to delete a neural network
 */
export const removeNeuralNetwork = async (id: string) => {
  // Send a http request to API with registration data
  return await deleteRequest(`/api/neural_networks/${id}/`);
};

/**
 * Send a POST request to the API to assign neural networks to the user
 */
export const selectNeuralNetworks = async (data: {
  neural_networks: string[];
}) => {
  return await post("/api/neural_networks/select/", data);
};
