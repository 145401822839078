import { FeedItem, StreamingProvider } from "types/feed";
import AmazonLogo from "components/icons/AmazonLogo.svg";
import AppleTVLogo from "components/icons/AppleTVLogo.svg";
import HBOLogo from "components/icons/HBOLogo.svg";
import NetflixLogo from "components/icons/NetflixLogo.svg";
import SpotifyLogo from "components/icons/SpotifyLogo.svg";
import NoImage from "images/No-Image.png";

/**
 * Return the image of the given feed item if it exists, otherwise return the default image.
 */
export function getFeedItemImage(feedItem: FeedItem) {
  if (feedItem.image) return feedItem.image;
  else return NoImage;
}

/**
 * Returns the logo for a given streaming provider.
 */
export function getLogoForStreamingProvider(provider: StreamingProvider) {
  // MOTN API docs don't provide a full list of streaming providers.
  if (provider.service === "prime") return AmazonLogo;
  if (provider.service === "netflix") return NetflixLogo;
  if (provider.service === "hbo") return HBOLogo;
  if (provider.service === "apple") return AppleTVLogo;
  if (provider.service === "spotify") return SpotifyLogo;

  // Return default NoImage
  return NoImage;
}

/**
 * Handles special cases where service name has to be transformed to look better(e.g. "hbo" to "HBO").
 */
export function transformStreamingServiceName(service: string) {
  if (service === "hbo") return "HBO";
  return service;
}
