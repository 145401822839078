import React, { useEffect, useState } from "react";
import { Button, Label, TextInput } from "flowbite-react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { displayErrors } from "helpers/errors";
import {
  getMyPlaylists,
  sharePlaylistsWithUsers,
} from "redux/playlist/playlistSlice";
import { isDispatchResponseError } from "redux/utils";
import { fetchUsers } from "redux/users/usersSlice";
import { useDebounce } from "use-debounce";
import Modal from "components/common/modal";
import { Playlist } from "types/playlist";

interface Props {
  show: boolean;
  onClose: () => void;
}

/**
 * Component for sharing playlists with users.
 */
export default function SharePlaylistsPopup({ show, onClose }: Props) {
  const dispatch = useAppDispatch();
  const users = useAppSelector((state) => state.users.users);
  const playlists = useAppSelector((state) => state.playlist.myPlaylists);
  const sharePlaylistsWithUsersErrors = useAppSelector(
    (state) => state.playlist.sharePlaylistsWithUsersErrors,
  );
  const [selectedPlaylists, setSelectedPlaylists] = useState<Array<string>>([]);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);

  /**
   * Share playlists with selected user.
   */
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const response = await dispatch(
      sharePlaylistsWithUsers({
        user_id: selectedUser,
        playlist_ids: selectedPlaylists,
      }),
    );

    // Close the popup if successful
    if (!isDispatchResponseError(response)) {
      onClose();
    }
  };

  /**
   * Render rows of playlists.
   */
  const displayPlaylists = () => {
    return playlists.map((playlist, idx) => (
      <tr key={idx} className="border-b border-gray-200">
        <td className="px-6 py-4 bg-gray-50">
          <input
            id={playlist.id}
            name="video_playlists"
            type="checkbox"
            value={playlist.id}
            onChange={(e) => handleChangePlaylists(e)}
            className="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-0"
            checked={selectedPlaylists.includes(playlist.id)}
            aria-label={`Select playlist ${playlist.name}`}
          />
          <label htmlFor={playlist.id}>{playlist.name}</label>
        </td>
        <td className="px-6 py-4">{getPlaylistLength(playlist)} items</td>
      </tr>
    ));
  };

  /**
   * Render rows of users.
   */
  const displayUsers = () => {
    return users.map((user, idx) => (
      <tr key={idx} className="border-b border-gray-200">
        <td className="flex items-center px-6 py-4 bg-gray-50">
          <input
            id={user.id}
            name="video_playlists"
            type="radio"
            value={user.id}
            onChange={(e) => handleChangeUsers(e)}
            checked={selectedUser === user.id}
            className="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-0"
            aria-label={`Select user ${user.username}`}
          />
          <label htmlFor={user.id}>{user.username}</label>
        </td>
      </tr>
    ));
  };

  /**
   * Handle updating selected playlists form state.
   */
  const handleChangePlaylists = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedPlaylistId = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked && !selectedPlaylists.includes(selectedPlaylistId)) {
      // If playlist item is checked and doesn't exist in selectedPlaylists list
      setSelectedPlaylists([...selectedPlaylists, selectedPlaylistId]);
    } else if (!isChecked && selectedPlaylists.includes(selectedPlaylistId)) {
      // If playlist item is unchecked and exists in selectedPlaylists list
      const newSelectedPlaylists = selectedPlaylists.filter(
        (selectedPlaylist) => selectedPlaylist !== selectedPlaylistId,
      );
      setSelectedPlaylists(newSelectedPlaylists);
    }
  };

  /**
   * Handle updating selected user form state.
   */
  const handleChangeUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedUserId = e.target.value;
    setSelectedUser(selectedUserId);
  };

  /**
   * Get the number of items in a playlist.
   */
  function getPlaylistLength(playlist: Playlist) {
    const length = playlist.items.length;
    return length;
  }

  /**
   * Clear form state when popup display status changes.
   */
  useEffect(() => {
    // Run the code only when popup is open
    if (show) {
      // Retrieve list of Playlists
      dispatch(getMyPlaylists({}));
      dispatch(fetchUsers());
    }

    // Clear selected playlists & users
    setSelectedPlaylists([]);
    setSelectedUser("");
  }, [show]);

  /**
   * Fetch users based on search query.
   */
  useEffect(() => {
    dispatch(fetchUsers(debouncedSearchQuery));
  }, [debouncedSearchQuery]);

  return (
    <React.Fragment>
      <Modal
        show={show}
        onClose={onClose}
        headerText="Share your playlists"
        submitButtonDisabled={selectedPlaylists.length === 0 || !selectedUser}
        onSubmit={handleSubmit}
        body={
          <div className="space-y-4 px-4">
            {playlists.length == 0 ? (
              <p className="text-gray-500 text-sm font-semibold">
                No Playlists added yet!
              </p>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-2">
                <Label value="Select playlists" />
                <div className="border rounded max-h-[20rem] overflow-y-auto">
                  <table className="w-full text-sm text-left">
                    <thead className="text-xs text-gray-700 uppercase border-b sticky top-0">
                      {/* boxShadow property is needed here for a border to be present in sticky table header */}
                      <tr style={{ boxShadow: "inset 0 0 0 1px #E5E7EB" }}>
                        <th scope="col" className="px-6 py-3 bg-gray-50">
                          Name
                        </th>
                        <th scope="col" className="px-6 py-3 bg-white">
                          Items
                        </th>
                      </tr>
                    </thead>
                    <tbody>{displayPlaylists()}</tbody>
                  </table>
                </div>
                {displayErrors(sharePlaylistsWithUsersErrors.playlist_ids)}

                <div>
                  <Label value="Select a user" />
                  <TextInput
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    aria-label="Search user"
                  />
                </div>
                <div className="border rounded max-h-[20rem] overflow-y-auto">
                  <table className="w-full text-sm text-left">
                    <thead className="text-xs text-gray-700 uppercase border-b sticky top-0">
                      {/* boxShadow property is needed here for a border to be present in sticky table header */}
                      <tr style={{ boxShadow: "inset 0 0 0 1px #E5E7EB" }}>
                        <th scope="col" className="px-6 py-3 bg-gray-50">
                          Name
                        </th>
                      </tr>
                    </thead>
                    <tbody>{displayUsers()}</tbody>
                  </table>
                </div>
                {displayErrors(sharePlaylistsWithUsersErrors.user_id)}
              </form>
            )}
          </div>
        }
      ></Modal>
    </React.Fragment>
  );
}
