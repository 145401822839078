import { ArrowUpTrayIcon } from "@heroicons/react/24/solid";
import React from "react";
import FileAttachment from "./FileAttachment";

interface Props {
  file: File | null;
  setFile: (arg?: any) => void;
  maxSize?: string;
  // If there are multiple <FileUpload /> components in a single view,
  // each must have a different inputId. Otherwise some of the forms won't
  // register selecting a file.
  inputId?: string;
}

/**
 * A file upload component.
 */
export default function FileUpload({
  file,
  setFile,
  maxSize = "20 MB",
  inputId = "dropzone-file",
}: Props) {
  return (
    <div className="flex flex-col w-full">
      <label
        htmlFor={inputId}
        className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        aria-label="File upload area"
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <ArrowUpTrayIcon className="w-10 h-10 mb-3 text-gray-400" />
          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
            <span className="font-semibold">Click to upload</span> or drag and
            drop
          </p>
          <p className="text-xs text-gray-500 dark:text-gray-400">
            Maximum size {maxSize}
          </p>
        </div>
        <input
          id={inputId}
          onChange={(e) => {
            setFile(e.target.files ? e.target.files[0] : null);
          }}
          type="file"
          className="hidden"
          value={""}
          aria-label="File input"
        />
      </label>
      {file && <FileAttachment file={file} setFile={setFile} />}
    </div>
  );
}
