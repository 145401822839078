import React, { useState } from "react";
import { Playlist } from "types/playlist";
import { Button } from "flowbite-react";
import { PencilSquareIcon, PlayIcon } from "@heroicons/react/24/solid";
import ModalEditCreatePlaylist from "../ModalEditCreatePlaylist";
import { TrashIcon } from "@heroicons/react/24/outline";
import ConfirmationPopup from "components/common/modal/ConfirmationPopup";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { deletePlaylist } from "redux/playlist/playlistSlice";
import { isDispatchResponseError } from "redux/utils";
import { APP_URLS } from "navigation";
import ModalAddToPlaylistDirectLink from "../ModalAddToPlaylistDirectLink";

interface Props {
  playlist: Playlist | null;
}

/**
 * Component used for performing actions on a playlist.
 */
export default function PlaylistActions({ playlist }: Props) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDirectLinkModal, setShowDirectLinkModal] = useState(false);
  const dispatch = useAppDispatch();
  const deletePlaylistPending = useAppSelector(
    (state) => state.playlist.deletePlaylistPending,
  );
  const user = useAppSelector((state) => state.user.user);

  /**
   * Navigate to mobile feed which will display only feed items belonging to the playlist.
   */
  function onClickPlay() {
    const url = APP_URLS.FEED_MOBILE_PLAYLIST.replace(
      ":playlistId",
      String(playlist?.id),
    );
    window.location.href = url;
  }

  /**
   * Open modal for editing the playlist.
   */
  function onClickEdit() {
    setShowEditModal(true);
  }

  /**
   * Close modal for editing the playlist.
   */
  function onCloseEditModal() {
    setShowEditModal(false);
  }

  /**
   * Open confirmation modal for deleting the playlist.
   */
  function onClickDelete() {
    setShowDeleteModal(true);
  }

  /**
   * Close confirmation modal for deleting the playlist.
   */
  function onCloseDeleteModal() {
    setShowDeleteModal(false);
  }

  /**
   * Delete the playlist.
   */
  async function onConfirmDeleteModal() {
    if (playlist) {
      const response = await dispatch(deletePlaylist(playlist?.id));
      if (!isDispatchResponseError(response)) {
        onCloseDeleteModal();
        window.location.href = APP_URLS.PLAYLISTS_MOBILE;
      }
    }
  }

  /**
   * Open modal for adding an item with a direct link.
   */
  function onClickDirectLink() {
    setShowDirectLinkModal(true);
  }

  /**
   * Close modal for adding an item with a direct link.
   */
  function onCloseDirectLinkModal() {
    setShowDirectLinkModal(false);
  }

  /**
   * Flag indicating if the current user is the owner of the playlist.
   */
  const isUserOwner = playlist?.user === user?.id;

  /**
   * Flag indicating if the playlist is a default playlist.
   */
  const isDefault = playlist?.is_default || playlist?.is_default_liked;

  return (
    <div
      className="grid grid-cols-1 justify-center items-center w-full gap-2"
    >
      <Button
        className="bg-neutral-300 w-full focus:ring-transparent focus:outline-none hover:bg-neutral-300"
        onClick={onClickPlay}
        aria-label="Play playlist"
      >
        <PlayIcon className="h-6 w-6 text-neutral-700" />
        <span className="ml-1 text-xl text-neutral-700">Play</span>
      </Button>

      {isUserOwner && !isDefault && (
        <Button
          className="bg-neutral-300 w-full focus:ring-transparent focus:outline-none hover:bg-neutral-300"
          onClick={onClickEdit}
          aria-label="Edit playlist"
        >
          <PencilSquareIcon className="h-6 w-6 text-neutral-700" />
          <span className="ml-1 text-xl text-neutral-700">Edit</span>
        </Button>
      )}

      {isUserOwner && (
        <Button
          className="bg-neutral-300 w-full focus:ring-transparent focus:outline-none hover:bg-neutral-300"
          onClick={onClickDirectLink}
          aria-label="Add an item with a direct link"
        >
          <PencilSquareIcon className="h-6 w-6 text-neutral-700" />
          <span className="ml-1 text-xl text-neutral-700">
            Add an item with a direct link
          </span>
        </Button>
      )}

      {isUserOwner && !isDefault && (
        <Button
          className="bg-red-300 w-full focus:ring-transparent focus:outline-none hover:bg-neutral-300"
          onClick={onClickDelete}
          aria-label="Delete playlist"
        >
          <TrashIcon className="h-6 w-6 text-neutral-700" />
          <span className="ml-1 text-xl text-neutral-700">Delete</span>
        </Button>
      )}

      <ModalEditCreatePlaylist
        show={showEditModal}
        onClose={onCloseEditModal}
        playlistId={playlist?.id}
      />
      <ConfirmationPopup
        show={showDeleteModal}
        onClose={onCloseDeleteModal}
        onConfirm={onConfirmDeleteModal}
        title="Are you sure you want to delete this playlist?"
        pendingConfirm={deletePlaylistPending}
      />
      <ModalAddToPlaylistDirectLink
        playlist={playlist}
        show={showDirectLinkModal}
        onClose={onCloseDirectLinkModal}
      />
    </div>
  );
}
